<template>
  <v-container>
    <v-container
      id="grid"
      fluid
      tag="section"
      style="padding-top: 0px"
    >
      <h2>{{ $t("customer_detail.title") }}</h2>
      <material-alert
        v-if="isUpdateSuccess"
        color="success"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ $t('customer_detail.update_success') }}
      </material-alert>
      <v-form
        ref="Forms"
        v-model="isFormValid"
      >
        <v-row>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="shortCustomerId"
              type="text"
              :label="$t('customer_detail.placeholder.short_customer_id_uneditable')"
              prepend-icon="mdi-card-account-details"
              readonly
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="tier"
              type="text"
              :label="$t('customer_detail.placeholder.tier_uneditable')"
              readonly
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="valueSum"
              type="text"
              :label="$t('customer_detail.placeholder.value_sum_uneditable')"
              readonly
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="customerId"
              type="text"
              :label="customerIdLabel"
              prepend-icon="mdi-account"
              readonly
              :rules="[requireRule]"
              required
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="contactIdRegistrationDate"
              type="text"
              :label="contactIdRegDateLabel"
              prepend-icon="mdi-calendar"
              readonly
              :rules="[requireRule]"
              required
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="profileRegistrationDate"
              type="text"
              :label="profileRegDateLabel"
              readonly
              :rules="[requireRule]"
              required
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="profileUpdateDate"
              type="text"
              :label="profileUpdateDateLabel"
              readonly
              :rules="[requireRule]"
              required
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="lastName"
              type="text"
              prepend-icon="mdi-account"
              :label="$t('customer_detail.placeholder.last_name')"
              :rules="[requireRule]"
              :readonly="!isEditable"
              required
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="firstName"
              type="text"
              :label="$t('customer_detail.placeholder.first_name')"
              :rules="[requireRule]"
              :readonly="!isEditable"
              required
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="kanaLastName"
              prepend-icon="mdi-account"
              type="text"
              :label="$t('customer_detail.placeholder.kana_last_name')"
              :rules="[requireRule]"
              required
              :readonly="!isEditable"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="kanaFirstName"
              type="text"
              :label="$t('customer_detail.placeholder.kana_first_name')"
              :rules="[requireRule]"
              required
              :readonly="!isEditable"
            />
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col
            cols="12"
            sm="1"
          >
            {{ $t("customer_detail.placeholder.gender.title") }}
          </v-col>
          <v-col
            cols="12"
            sm="11"
          >
            <v-radio-group
              v-model="gender"
              row
            >
              <v-radio
                :label="$t('customer_detail.placeholder.gender.male')"
                :value="$t('customer_detail.placeholder.gender.male')"
                :readonly="!isEditable"
              />
              <v-radio
                :label="$t('customer_detail.placeholder.gender.female')"
                :value="$t('customer_detail.placeholder.gender.female')"
                :readonly="!isEditable"
              />
              <v-radio
                :label="$t('customer_detail.placeholder.gender.other')"
                :value="$t('customer_detail.placeholder.gender.other')"
                :readonly="!isEditable"
              />
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-menu
              v-model="isOpenBirthDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="displayBirthDate"
                  :label="$t('customer_detail.placeholder.birth_date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  clearable
                  v-bind="attrs"
                  :rules="[datePickerRule]"
                  v-on="on"
                  @click:clear="birthDate = ''"
                />
              </template>
              <v-date-picker
                v-model="birthDate"
                :locale="locale"
                :day-format="date => new Date(date).getDate()"
                @input="isOpenBirthDatePicker = false"
              />
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="2"
          >
            <v-text-field
              v-model="phoneType"
              type="text"
              prepend-icon="mdi-phone"
              :label="$t('customer_detail.placeholder.phone_type')"
              :readonly="!isEditable"
            />
          </v-col>
          <v-col
            cols="12"
            sm="10"
          >
            <v-text-field
              v-model="phoneNumber"
              type="text"
              :label="$t('customer_detail.placeholder.phone_number')"
              :readonly="!isEditable"
              :rules="[phonenumberFormatRule]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="mailAddress"
              type="email"
              prepend-icon="mdi-email-open-outline"
              :label="$t('customer_detail.placeholder.mail_address')"
              :readonly="!isEditable"
              :rules="[requireRule, emailFormatRule]"
              required
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="postalCode"
              type="text"
              prepend-icon="mdi-post-outline"
              :label="$t('customer_detail.placeholder.postal_code')"
              :readonly="!isEditable"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="address"
              type="text"
              prepend-icon="mdi-map-marker-radius"
              :label="$t('customer_detail.placeholder.address')"
              :readonly="!isEditable"
            />
          </v-col>
        </v-row>

        <v-row
          v-for="(value, key) in extraAttribute"
          :key="key"
          style="padding-left: 43px"
        >
          <v-text-field
            v-model="extraAttribute[key]"
            type="text"
            :label="key"
            :readonly="!isEditable"
          />
        </v-row>
        <v-row>
          <v-col>
            <v-menu
              v-model="isOpenWeddingDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="displayWeddingDate"
                  :label="$t('customer_detail.placeholder.wedding_date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  clearable
                  v-bind="attrs"
                  :rules="[datePickerRule]"
                  v-on="on"
                  @click:clear="weddingDate = ''"
                />
              </template>
              <v-date-picker
                v-model="weddingDate"
                :locale="locale"
                :day-format="date => new Date(date).getDate()"
                @input="isOpenWeddingDatePicker = false"
              />
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="memo"
              type="text"
              :label="$t('customer_detail.placeholder.memo')"
              prepend-icon="mdi-note-search"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-row
        justify="end"
        style="margin-top: 16px"
      >
        <v-btn
          v-if="isEditable"
          color="red"
          @click="showDeleteDialog = true"
        >
          {{ $t("customer_detail.delete_button") }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="blue-grey lighten-2"
          to="/components/customer-search"
        >
          {{ $t('customer_detail.return_button') }}
        </v-btn>
        <v-btn
          v-if="isEditable"
          color="primary"
          :disabled="!isFormValid"
          @click="openUpdateDialog"
        >
          {{ $t("customer_detail.update_button") }}
        </v-btn>
      </v-row>

      <div class="margin-additional">
        <h2>記念日一覧</h2>
        <v-simple-table
          v-if="anniversaries.length"
          class="mt-2"
        >
          <tbody>
            <tr
              v-for="anniversary in anniversaries"
              :key="anniversary.order"
            >
              <td> {{ anniversary.order }}</td>
              <td>{{ anniversary.type }}</td>
              <td>
                {{ parseToDateString(anniversary.year, anniversary.month, anniversary.day) }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <div
          v-else
          class="mt-1"
        >
          記念日が登録されていません。
        </div>
      </div>

      <!-- point history -->
      <div class="margin-additional">
        <h2>{{ $t("customer_detail.point_history_title") }}</h2>
        <v-data-table
          :headers="headers"
          :items="pointHistories"
          :items-per-page="10"
          item-key="customer_id"
          sort-by="timestamp"
          :sort-desc="true"
          class="elevation-1 mt-2"
        />
      </div>
    </v-container>

    <!-- 更新確認ダイアログ -->
    <v-dialog
      v-model="showUpdateDialog"
      max-width="950px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t("customer_detail.update_dialog.title") }}</span>
        </v-card-title>

        <v-card-text class="line-break">
          <material-alert
            v-for="error in errors"
            :key="error"
            color="error"
            dark
            dismissible
            icon="mdi-bell"
          >
            {{ error }}
          </material-alert>
          {{ updateCautionMessage }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey lighten-2"
            :disabled="isProcessing"
            @click="showUpdateDialog = false"
          >
            {{ $t("customer_detail.cancel_button") }}
          </v-btn>
          <v-btn
            color="primary"
            :loading="isProcessing"
            @click="updateCustomer()"
          >
            {{ $t("customer_detail.update_button") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 削除ダイアログ -->
    <v-dialog
      v-model="showDeleteDialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t("customer_detail.delete_dialog.title") }}</span>
        </v-card-title>

        <v-card-text>
          <material-alert
            v-for="error in errors"
            :key="error"
            color="error"
            dark
            dismissible
            icon="mdi-bell"
          >
            {{ error }}
          </material-alert>
        </v-card-text>

        <v-card-text class="line-break">
          {{ $t("customer_detail.delete_dialog.description") }}

          <v-form v-model="customerNameIsValid">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="delete_full_name"
                  type="text"
                  :label="delete_form_placeholder"
                  :rules="[requireRule, customerNameRule]"
                  required
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey lighten-2"
            :disabled="isProcessing"
            @click="showDeleteDialog = false"
          >
            {{ $t("customer_detail.cancel_button") }}
          </v-btn>
          <v-btn
            color="red"
            :disabled="!customerNameIsValid"
            :loading="isProcessing"
            @click="deleteCustomer()"
          >
            {{ $t("customer_detail.delete_button") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import * as api from '../api.js'
  import MaterialAlert from '../components/atoms/MaterialAlert.vue'

  export default {
    name: 'CustomerDetail',
    components: {
      MaterialAlert,
    },
    data () {
      return {
        errors: [],
        isFormValid: true,
        locale: 'en',
        isEditable: false,
        customerInfo: null,
        customerId: '',
        contactIdRegistrationDate: '',
        profileRegistrationDate: '',
        profileUpdateDate: '',
        customerIdLabel: '',
        contactIdRegDateLabel: '',
        profileRegDateLabel: '',
        profileUpdateDateLabel: '',
        shortCustomerId: '',
        tier: '',
        valueSum: '',
        lastName: '',
        firstName: '',
        kanaLastName: '',
        kanaFirstName: '',
        gender: '',
        phoneType: '',
        phoneNumber: '',
        mailAddress: '',
        postalCode: '',
        address: '',
        extraAttribute: {},
        pointHistories: [],
        requireRule: v => !!v || this.$t('employee_entry.errors.required'),
        emailFormatRule: v => /.+@.+\..+/.test(v) || this.$t('customer_detail.errors.email_format'),
        phonenumberFormatRule: v => !v ? true : /^[+]{0,1}[0-9]+$/.test(v) || this.$t('customer_detail.errors.phonenumber_format'),
        customerNameRule: v => v === this.lastName + ' ' + this.firstName || this.$t('customer_detail.delete_dialog.customer_name_not_match'),
        showDeleteDialog: false,
        showUpdateDialog: false,
        updateCautionMessage: '',
        delete_full_name: '',
        customerNameIsValid: false,
        isProcessing: false,
        isUpdateSuccess: false,
        anniversaries: [],
        weddingDate: '',
        memo: '',
        birthDate: '',
        isOpenWeddingDatePicker: false,
        isOpenBirthDatePicker: false,
        headers: [
          {
            text: this.$t('customer_detail.point_history_header.datetime'),
            align: 'start',
            sortable: true,
            value: 'timestamp',
            width: '16%',
            class: 'primary white--text',
          },
          {
            text: this.$t('customer_detail.point_history_header.points'),
            sortable: false,
            value: 'points',
            width: '8%',
            class: 'primary white--text',
          },
          {
            text: this.$t('customer_detail.point_history_header.amount'),
            sortable: false,
            value: 'amount',
            width: '12%',
            class: 'primary white--text',
          },
          {
            text: this.$t('customer_detail.point_history_header.operation'),
            sortable: true,
            value: 'operation',
            width: '8%',
            class: 'primary white--text',
          },
          {
            text: this.$t('customer_detail.point_history_header.point_expires_at'),
            sortable: false,
            value: 'expires_at',
            width: '12%',
            class: 'primary white--text',
          },
          {
            text: this.$t('customer_detail.point_history_header.venue_name'),
            align: 'start',
            sortable: false,
            value: 'venueName',
            width: '12%',
            class: 'primary white--text',
          },
          {
            text: this.$t('customer_detail.point_history_header.operator_name'),
            align: 'start',
            sortable: false,
            value: 'operatorName',
            width: '12%',
            class: 'primary white--text',
          },
          {
            text: this.$t('customer_detail.point_history_header.description'),
            sortable: false,
            value: 'description',
            width: '28%',
            class: 'primary white--text',
          },
        ],
      }
    },
    computed: {
      delete_form_placeholder () {
        return this.lastName + ' ' + this.firstName
      },
      displayWeddingDate: {
        get () {
          // weddingDateはYYYY-MM-DD形式で扱うが、他の項目と合わせて表示上はYYYY/MM/DDする
          if (!this.weddingDate) return ''

          return moment(new Date(this.weddingDate).toISOString()).format('YYYY/MM/DD')
        },
        set (value) {
          if (!this.weddingDate) return ''

          this.weddingDate = value
        },
      },
      displayBirthDate: {
        get () {
          // birthDateはYYYY-MM-DD形式で扱うが、他の項目と合わせて表示上はYYYY/MM/DDする
          if (!this.birthDate) return ''

          return moment(new Date(this.birthDate).toISOString()).format('YYYY/MM/DD')
        },
        set (value) {
          if (!this.birthDate) return ''

          this.birthDate = value
        },
      },
    },
    watch: {
    },
    mounted () {
      scrollTo(0, 0)
      this.locale = process.env.VUE_APP_I18N_LOCALE
      moment.locale(this.locale)

      // 権限設定
      this.isEditable = this.$can('component.update_customer')
      // 権限に応じてラベルを切り替え
      if (this.isEditable) {
        this.customerIdLabel = this.$t('customer_detail.placeholder.customer_id_uneditable')
        this.contactIdRegDateLabel = this.$t('customer_detail.placeholder.contact_id_registration_date_uneditable')
        this.profileRegDateLabel = this.$t('customer_detail.placeholder.profile_registration_date_uneditable')
        this.profileUpdateDateLabel = this.$t('customer_detail.placeholder.profile_update_date_uneditable')
      } else {
        this.customerIdLabel = this.$t('customer_detail.placeholder.customer_id')
        this.contactIdRegDateLabel = this.$t('customer_detail.placeholder.contact_id_registration_date')
        this.profileRegDateLabel = this.$t('customer_detail.placeholder.profile_registration_date')
        this.profileUpdateDateLabel = this.$t('customer_detail.placeholder.profile_update_date')
      }

      if (typeof this.$route.params.target === 'undefined') {
        // URL直打ち等、顧客検索画面の詳細ボタンのクリック以外の方法で飛んできた場合、顧客検索画面へ強制的に遷移させる
        this.$router.push({
          name: 'Customer Search',
        })
      } else {
        // 表示対象の顧客情報を取得し、入力フォームの変数にセット
        this.customerInfo = this.$route.params.target
        this.setCustomerInfoToFields(this.$route.params.target)

        // ポイント履歴をAPIから取得
        api.getCustomerPointHistories(this.customerId)
          .then(res => {
            this.pointHistories = res.data
          })
          .catch(e => console.log(e))
      }
    },
    methods: {
      setCustomerInfoToFields (customerInfo) {
        this.shortCustomerId = customerInfo.short_customer_id
        this.tier = customerInfo.kinchaku_data.tier
        this.valueSum = customerInfo.kinchaku_data.value_sum
        this.customerId = customerInfo.customer_id
        this.lastName = customerInfo.profile.lastname
        this.firstName = customerInfo.profile.firstname
        this.kanaLastName = customerInfo.profile.lastname_kana
        this.kanaFirstName = customerInfo.profile.firstname_kana
        this.gender = customerInfo.profile.gender
        this.phoneType = customerInfo.profile.phonetype
        this.phoneNumber = customerInfo.profile.phonenumber
        this.mailAddress = customerInfo.profile.email
        this.postalCode = customerInfo.profile.postalcode
        this.address = customerInfo.profile.address
        this.extraAttribute = customerInfo.profile.extra_attribute
        this.anniversaries = this.parseAnniversaryToArray(customerInfo.anniversaries)
        this.memo = customerInfo.profile.memo
        this.weddingDate = this.formattedDateSplitHyphen(customerInfo.profile.wedding_date)
        this.birthDate = this.formattedDateSplitHyphen(customerInfo.profile.birth_date)
        this.contactIdRegistrationDate = this.formattedDateTimeSplitSlash(customerInfo.contact_id_registration_date)
        this.profileRegistrationDate = this.formattedDateTimeSplitSlash(customerInfo.profile_registration_date)
        this.profileUpdateDate = this.formattedDateTimeSplitSlash(customerInfo.profile_update_date)
      },
      formattedDateSplitHyphen (date) {
        return date === '' ? '' : moment(new Date(date).toISOString()).format('YYYY-MM-DD')
      },
      formattedDateSplitSlash (date) {
        return date === '' ? '' : moment(new Date(date).toISOString()).format('YYYY/MM/DD')
      },
      // 友達登録日/プロフィール登録日/プロフィール更新日のフォーマットを調整
      // 空文字だったら半角スペースを表示する (空文字だとプレースホルダーの文字列が大きく表示されるため)
      // タイムゾーンはシステム依存でmoment.jsが調整してくれる
      formattedDateTimeSplitSlash (date) {
        return date === '' ? ' ' : moment(new Date(date).toISOString()).format('YYYY/MM/DD HH:mm:ss')
      },
      openUpdateDialog () {
        this.showUpdateDialog = true
        this.isUpdateSuccess = false
        this.updateCautionMessage = this.$t('customer_detail.update_dialog.description').replace('{{customer_id}}', this.customerId)
      },
      updateCustomer () {
        this.isProcessing = true
        this.errors = []
        const params = this.customerInfo
        params.profile.lastname = this.lastName
        params.profile.firstname = this.firstName
        params.profile.lastname_kana = this.kanaLastName
        params.profile.firstname_kana = this.kanaFirstName
        params.profile.gender = this.gender
        params.profile.phonetype = this.phoneType
        params.profile.phonenumber = this.phoneNumber
        params.profile.email = this.mailAddress
        params.profile.postalcode = this.postalCode
        params.profile.address = this.address
        params.profile.extra_attribute = this.extraAttribute
        params.profile.wedding_date = this.weddingDate
        params.profile.memo = this.memo
        params.profile.birth_date = this.birthDate

        api.updateCustomer(this.customerId, params).then(res => {
          // 更新内容を検索結果のキャッシュに反映させる
          this.customerInfo = params
          this.customerInfo.profile_update_date = moment().format('YYYY/MM/DD HH:mm:ss')
          this.profileUpdateDate = this.customerInfo.profile_update_date
          this.showUpdateDialog = false
          this.isUpdateSuccess = true
          scrollTo(0, 0)
        }).catch(e => {
          if (e.response !== undefined && e.response.status === 404) {
            this.errors.push(this.$t('customer_detail.errors.not_found'))
          } else if (e.code === 'ECONNABORTED') {
            this.errors.push(this.$t('errors.api_timeout'))
          } else {
            this.errors.push(this.$t('errors.unexpected'))
          }
          this.isUpdateSuccess = false
        }).finally(() => {
          params.profile.wedding_date = this.formattedDateSplitSlash(this.weddingDate)
          params.profile.birth_date = this.formattedDateSplitSlash(this.birthDate)
          this.isProcessing = false
        })
      },
      deleteCustomer () {
        this.isProcessing = true
        this.errors = []
        api.deleteCustomer(this.customerId).then(res => {
          this.$router.push({
            name: 'Customer Search',
            params: {
              deleted_id: this.customerId,
            },
          })
        }).catch(e => {
          if (e.response !== undefined && e.response.status === 404) {
            this.errors.push(this.$t('customer_detail.errors.not_found'))
          } else if (e.code === 'ECONNABORTED') {
            this.errors.push(this.$t('errors.api_timeout'))
          } else {
            this.errors.push(this.$t('errors.unexpected'))
          }
          this.isProcessing = false
        })
      },
      /*
        表示しやすい形にパースする
       */
      parseAnniversaryToArray (anniversaries) {
        // INFO: 元データに影響を与えないように、深いコピーをした別のオブジェクトを生成しておく
        const copiedAnniversaries = JSON.parse(JSON.stringify(anniversaries))

        const parsedAnniversary = []
        // typeを該当の日本語に変換する
        for (const [key, anniversary] of Object.entries(copiedAnniversaries)) {
          switch (anniversary.type) {
            case 'family-anniversary':
              anniversary.type = this.$t('customer_detail.anniversary.family-anniversary')
              break
            case 'family-birthday':
              anniversary.type = this.$t('customer_detail.anniversary.family-birthday')
              break
            case 'self-birthday':
              anniversary.type = this.$t('customer_detail.anniversary.self-birthday')
              break
            case 'wedding-anniversary':
              anniversary.type = this.$t('customer_detail.anniversary.wedding-anniversary')
              break
            case 'other':
              anniversary.type = anniversary.other_name
              break
            default:
              continue
          }
          // 不要になった`other_name`フィールドは削除
          delete anniversary.other_name
          // 優先順位を表す値を、keyから取得
          anniversary.order = key

          parsedAnniversary.push(anniversary)
        }

        // order順にソートしてから返す
        return parsedAnniversary.sort((a, b) => a.id < b.id ? -1 : 1)
      },
      /*
        日時を表示用にパースする
       */
      parseToDateString (year, month, day) {
        return `${year}年 ${('0' + month).slice(-2)}月 ${('0' + day).slice(-2)}日`
      },
      /*
        日付フォームのバリデーション
       */
      datePickerRule (value) {
        if (!value) {
          return true
        }
        const inputYear = value.split('/')[0]
        const currentYear = new Date().getFullYear()
        const maxYear = currentYear + 3
        const minYear = currentYear - 100

        if (inputYear && inputYear < minYear) {
          return this.$t('customer_datepicker.errors.min_year').replace('{{ min_year }}', minYear)
        }

        if (inputYear && maxYear < inputYear) {
          return this.$t('customer_datepicker.errors.max_year').replace('{{ max_year }}', maxYear)
        }

        return true
      },
    },
  }
</script>
<style scoped>
.line-break {
  white-space:pre-wrap;
  word-wrap:break-word;
}
.v-progress-circular {
  margin: 1rem;
}
.right-input {
  padding-top: 0px;
}
.right-input >>> input {
  text-align: right!important;
}
.v-picker.v-card {
  margin: 0px;
}
.top-card {
  margin-top: 0px;
  width: fit-content;
}
.margin-additional {
  margin-top: 28px;
}
</style>
