<template>
  <v-container>
    <v-container
      v-if="isProcessing"
      id="grid"
      fluid
      tag="section"
    >
      <v-row justify="center">
        <v-progress-circular
          indeterminate
          color="primary"
          :size="100"
        />
      </v-row>
    </v-container>

    <v-container
      v-else
      id="grid"
      fluid
      tag="section"
    >
      <material-alert
        v-for="error in errors"
        :key="error"
        color="error"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ error }}
      </material-alert>

      <material-alert
        v-if="FailedGetStore"
        color="error"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ $t('entry.errors.store_api') }}
      </material-alert>

      <material-alert
        v-if="isZeroStore"
        color="warning"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ $t('entry.zero_store') }}
      </material-alert>

      <material-alert
        v-for="error in errorCategory"
        :key="error"
        color="error"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ $t('store_browsing.errors.category_prefix') }} {{ error }} {{ $t('store_browsing.errors.category_suffix') }}
      </material-alert>

      <div
        v-if="!isProcessing && !FailedGetStore && !isZeroStore"
      >
        <v-data-table
          :headers="headers"
          :items="storeList"
          :items-per-page="10"
          item-key="name"
          :custom-sort="customSort"
          sort-by="store_code"
          :sort-desc="false"
          class="elevation-1"
        >
          <template
            v-slot:item.actions="{ item }"
          >
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <!-- <v-icon
              small
              @click="editItem(item)"
            >
              mdi-delete
            </v-icon> -->
          </template>
          <template v-slot:no-data>
            <v-btn
              color="primary"
              @click="initialize"
            >
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </v-container>

    <!-- <v-dialog
      v-model="showDialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("reservation_capacity_entry.dialog.title") }}</span>
        </v-card-title>
        <v-card-text class="line-break">
          {{ $t("reservation_capacity_entry.dialog.description") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
          >
            {{ $t("reservation_capacity_entry.dialog.cancel") }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
          >
            {{ $t("reservation_capacity_entry.dialog.entry") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </v-container>
</template>

<script>
  import moment from 'moment'
  import * as api from '../api.js'
  import MaterialAlert from '../components/atoms/MaterialAlert.vue'

  export default {
    name: 'StoreBrowsing',
    components: {
      MaterialAlert,
    },
    data () {
      return {
        errors: [],
        showDialog: false,
        isProcessing: true,
        FailedGetStore: false,
        isZeroStore: false,
        storeList: [],
        storeNameList: [], // storeListから店舗名のみ抽出
        locale: 'en',
        headers: [
          {
            text: this.$t('store_browsing.header.store_code'),
            align: 'start',
            sortable: true,
            value: 'store_code',
            width: '14%',
            class: 'primary white--text',
          },
          {
            text: this.$t('store_browsing.header.name'),
            sortable: true,
            value: 'name',
            width: '20%',
            class: 'primary white--text',
          },
          { text: this.$t('store_browsing.header.address'), value: 'address', class: 'primary white--text' },
          { text: this.$t('store_browsing.header.detail'), value: 'actions', sortable: false, width: '10%', class: 'primary white--text' },
        ],
        errorCategory: [],
      }
    },
    computed: {
    },
    watch: {
    },
    mounted () {
      this.locale = process.env.VUE_APP_I18N_LOCALE
      moment.locale(this.locale)
      this.errorCategory = this.$route.params.error_category

      api.getStore().then(res => {
        if (res.data.length === 0) {
          this.storeList = []
          this.isZeroStore = true
          this.FailedGetStore = false
        } else {
          this.storeList = res.data
          this.storeList.sort(function (a, b) {
            if (a.name < b.name) {
              return -1
            }
            if (a.name > b.name) {
              return 1
            }
            return 0
          })
          this.storeNameList = this.storeList.map(x => x.name)
          // 仮でコードを振る
          // let count = 0
          // this.storeList.forEach(x => {
          //   x.store_code = 'S' + count
          //   count++
          // })
          this.FailedGetStore = false
          this.isZeroStore = false
        }
        this.isProcessing = false
      }).catch(e => {
        console.log(e)
        if (e.response && e.response.status === 404) {
          this.storeList = []
          this.isZeroStore = true
          this.FailedGetStore = false
        } else {
          this.FailedGetStore = true
        }
        this.isProcessing = false
      })
    },
    methods: {
      enterStoreInfo () {
        this.showDialog = false

        try {
          const storeInfoToSend = {
            name: this.store_name,
            phone_number: this.phone_number,
            address: this.address,
            area_id: '', // 現時点では画面から登録不可
            mail_address: this.store_mail_address,
            notification_mail_address: this.notification_mail_address,
            campaign: this.campaign,
            online_support: this.service_on_web,
            stripe_connection: false,
            video_meeting_tool_name: this.meeting_tool_name,
            video_meeting_tool_credential: {
              token: this.meeting_tool_credentials_id,
              address: this.meeting_tool_credentials_id,
            },
            google_calendar_id: this.google_calendar_id,
            start_time: this.business_hours_weekdays_start,
            end_time: this.business_hours_weekdays_end,
            start_time_holiday: this.business_hours_holidays_start,
            end_time_holiday: this.business_hours_holidays_end,
          }
          const shiftCategoryToSend = this.shift_category.map(function (x) {
            return {
              name: x.name,
              start_time: x.start_time,
              end_time: x.end_time,
            }
          })
          api.enterStoreInfo(storeInfoToSend).then(res => {
            const storeId = res.data.store_id
            shiftCategoryToSend.forEach(x => {
              x.store_id = storeId
            })
            const se = []
            const errorCategory = []
            shiftCategoryToSend.forEach(s => {
              const tmp = api.enterShiftCategory(s).then(res => {
              }).catch(e => {
                errorCategory.push(s.name)
              })
              se.push(tmp)
            })
            Promise.all(se).then(res => {
              console.log(errorCategory)
              this.$router.push({
                name: 'Dashboard',
                params: errorCategory,
              })
            })
          }).catch(e => {
            if (e.code === 'ECONNABORTED') {
              this.errors.push(this.$t('errors.api_timeout'))
            } else {
              this.errors.push(this.$t('errors.unexpected'))
            }
            this.isProcessing = false
          })
        } catch (e) {
          console.log(e)
          this.errors.push(this.$t('errors.unexpected'))
          this.isProcessing = false
        }
      },
      entry () {
        this.isProcessing = true
        try {
          this.enterStoreInfo()
        } catch (e) {
          console.log(e)
          this.isProcessing = false
        }
      },
      closeDialog () {
        this.isProcessing = false
        this.showDialog = false
      },
      customSort (items, index, isDesc) {
        if (index.length === 0 || isDesc.length === 0) {
          // ソートをoffにしたときの順番
          // console.log('default')
          items.sort((x, y) => x.store_code.localeCompare(y.store_code, this.locale, { numeric: true }))
          return items
        }
        const k = index[0]
        if (isDesc[0]) {
          // console.log('desc')
          items.sort((x, y) => -x[k].localeCompare(y[k], this.locale, { numeric: true }))
        } else {
          // console.log('asc')
          items.sort((x, y) => x[k].localeCompare(y[k], this.locale, { numeric: true }))
        }
        return items
      },
      editItem (item) {
        this.$router.push({
          name: 'Store Update',
          params: {
            id: item._id,
            store_info: item,
          },
        })
      },
    },
  }
</script>
<style scoped>
.line-break {
  white-space:pre-wrap;
  word-wrap:break-word;
}
.v-progress-circular {
  margin: 1rem;
}
.right-input {
  padding-top: 0px;
}
.right-input >>> input {
  text-align: right!important;
}
.v-picker.v-card {
  margin: 0px;
}
.top-card {
  margin-top: 0px;
  width: fit-content;
}
.margin-additional {
  margin-top: 28px;
}
.v-data-table >>> i.v-data-table-header__icon {
  color: white!important;
}
.v-data-table >>> th:first-child {
  border-radius: 4px 0px 0px 0px;
}
.v-data-table >>> th * {
  border-radius: 0px 0px 0px 0px;
  font-size: small;
}
.v-data-table >>> th:last-child {
  border-radius: 0px 4px 0px 0px;
}

</style>
