<template>
  <component :is="layout()" />
</template>

<script>
  import MainLayout from './components/layouts/MainLayout.vue'
  import SimpleLayout from './components/layouts/SimpleLayout.vue'

  export default {
    name: 'App',
    components: {
      MainLayout,
      SimpleLayout,
    },
    methods: {
      layout () {
        // routerのmeta > layoutをセットしていたら、その値から使用するLayoutComponentを決定する。
        // セットしない場合は、MainLayoutを適用する。
        return this.$route.meta.layout ? this.$route.meta.layout + '-layout' : 'main-layout'
      },
    },
  }
</script>
