<template>
  <v-container>
    <v-row justify="center">
      <font size="5">
        {{ $t('not_found.message') }}
      </font>
    </v-row>
    <br>
    <v-row justify="center">
      <v-btn
        class="primary"
        onclick="history.back()"
      >
        {{ $t('not_found.return_button') }}
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'NotFound',
    data: () => ({
      locale: 'en',
    }),
    mounted () {
    },
    methods: {
    },
  }
</script>
<style scoped>
.line-break {
  white-space:pre-wrap;
  word-wrap:break-word;
}
.v-progress-circular {
  margin: 1rem;
}

.hoge table {
  width: auto!important;
}
table {
  table-layout: fixed;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  width: auto!important;
}
th, td {
  font-weight: 300;
  padding: 6px 8px!important;
  height: 16px!important;
  max-width: 180px!important;
  min-width: 80px!important;
  border-width: 1px 0px; /* 上下だけ引く */
   border-color: lightgray;
}
.right-input {
  padding-top: 0px;
}
.right-input >>> input {
  text-align: right!important;
}
.v-picker.v-card {
  margin: 0px;
}
.text-color-absent {
  color: lightgray;
}
tbody td {
  padding: 0px 8px!important;
}
thead th {
  color: white!important;
}
.top-card {
  margin-top: 0px;
  width: fit-content;
}
</style>
