<template>
  <v-form
    ref="business_hours"
    v-model="businessHoursValid"
    :disabled="readOnly"
  >
    <v-row align="center">
      <v-col
        cols="12"
        sm="3"
      >
        {{ label }}
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <v-text-field
          v-model="businessHours.start"
          type="time"
          :label="startLabel"
          :rules="[requireRule, isBeforeRule(businessHours.end)]"
          required
        />
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <v-text-field
          v-model="businessHours.end"
          type="time"
          :label="endLabel"
          :rules="[requireRule, isAfterRule(businessHours.start)]"
          required
        />
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
  import moment from 'moment'

  export default {
    name: 'BusinessHourPair',

    props: {
      label: String,
      startLabel: String,
      endLabel: String,
      readOnly: Boolean,
      value: {
        start: String,
        end: String,
        valid: Boolean,
      },
      // value format:
      // {
      //   start: 'HH:mm',
      //   end: 'HH:mm',
      //   valid: Boolean,
      // }
    },

    data () {
      return {
        requireRule: v => !!v || this.$t('store_entry.errors.required'),
        isBeforeRule: function (endTime) {
          return v => (endTime === '' || moment(v, 'hh:mm') < moment(endTime, 'hh:mm')) || this.$t('store_entry.errors.invalid_time')
        },
        isAfterRule: function (startTime) {
          return v => (startTime === '' || moment(v, 'hh:mm') > moment(startTime, 'hh:mm')) || this.$t('store_entry.errors.invalid_time')
        },
        businessHours: this.value,
        businessHoursValid: false,
      }
    },
    watch: {
      'businessHours.start': function (val) {
        this.$nextTick(function () {
          if (this.$refs.business_hours) {
            const v = this.$refs.business_hours.validate()
            this.$emit('input', {
              start: val,
              end: this.businessHours.end,
              valid: v,
            })
          }
        })
      },
      'businessHours.end': function (val) {
        this.$nextTick(function () {
          if (this.$refs.business_hours) {
            const v = this.$refs.business_hours.validate()
            this.$emit('input', {
              start: this.businessHours.start,
              end: val,
              valid: v,
            })
          }
        })
      },
    },
  }
</script>

<style lang="sass">
</style>
