<template>
  <v-container>
    <v-container
      v-if="isProcessing"
      id="grid"
      fluid
      tag="section"
    >
      <v-row justify="center">
        <v-progress-circular
          indeterminate
          color="primary"
          :size="100"
        />
      </v-row>
    </v-container>

    <v-container
      v-else
      id="grid"
      fluid
      tag="section"
    >
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            v-model="selectedStore"
            :items="storeList"
            item-text="name"
            item-value="_id"
            :label="$t('reservation_capacity_entry.store_selector_label')"
            prepend-icon="mdi-map-marker-radius"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
          <v-menu
            v-model="datePickerMenu"
            offset-y
            :close-on-content-click="false"
            max-width="auto"
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="formattedPickedDate"
                :label="$t('reservation_capacity_entry.date_picker_label')"
                readonly
                prepend-icon="mdi-calendar"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="pickedDate"
              type="month"
              :locale="locale"
              @click="datePickerMenu = false"
            />
          </v-menu>
        </v-col>
      </v-row>

      <material-alert
        v-for="error in errors"
        :key="error"
        color="error"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ error }}
      </material-alert>

      <material-alert
        v-if="FailedGetStore"
        color="error"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ $t('entry.errors.store_api') }}
      </material-alert>

      <div
        v-if="!isProcessing && !FailedGetStore"
      >
        <v-row justify="center">
          <v-col
            cols="12"
          >
            <v-row justify="center">
              <v-card
                class="px-5 py-3 top-card"
              >
                <v-card-title>
                  {{ $t('reservation_capacity_entry.table_title_format').replace('${year}', year).replace('${month}', month) }}
                </v-card-title>
                <v-card-text>
                  <v-row
                    justify="center"
                  >
                    <v-form v-model="isFormValid">
                      <table
                        class="hoge"
                        rules="rows"
                      >
                        <thead>
                          <tr
                            align="center"
                            class="primary"
                          >
                            <th>
                              {{ $t('reservation_capacity_entry.time_label') }}
                            </th>
                            <th>
                              {{ $t('reservation_capacity_entry.weekdays_label') }}
                            </th>
                            <th>
                              {{ $t('reservation_capacity_entry.holidays_label') }}
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr
                            v-for="(d, index) in hours"
                            :key="index"
                            align="center"
                          >
                            <td>{{ d }}</td>
                            <td>
                              <v-text-field
                                v-model.number="selectedItems[0][index]"
                                :rules="[rules.required, rules.natural]"
                                type="number"
                                min="0"
                                class="right-input"
                              />
                            </td>
                            <td>
                              <v-text-field
                                v-model.number="selectedItems[1][index]"
                                :rules="[rules.required, rules.natural]"
                                type="number"
                                min="0"
                                class="right-input"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </v-form>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-row>
          </v-col>
          <v-col
            cols="12"
          >
            <v-row justify="end">
              <v-btn
                v-if="isFormValid"
                color="primary"
                @click="entry"
              >
                {{ $t('reservation_capacity_entry.entry_button') }}
              </v-btn>
              <v-btn
                v-else
                color="primary"
                disabled
              >
                {{ $t('reservation_capacity_entry.entry_button') }}
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-container>

    <v-dialog
      v-model="showDialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t("reservation_capacity_entry.dialog.title") }}</span>
        </v-card-title>
        <v-card-text class="line-break">
          {{ $t("reservation_capacity_entry.dialog.description") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey lighten-2"
            @click="closeDialog"
          >
            {{ $t("reservation_capacity_entry.dialog.cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            @click="enterReservationCapacity"
          >
            {{ $t("reservation_capacity_entry.dialog.entry") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import * as api from '../api.js'
  import * as holiday from '../holiday.js'
  import MaterialAlert from '../components/atoms/MaterialAlert.vue'

  export default {
    name: 'CapacityEntry',
    components: {
      MaterialAlert,
    },
    data: () => ({
      selected: null,
      errors: [],
      showDialog: false,
      isProcessing: true,
      storeList: [],
      selectedStore: null,
      hours: [],
      selectedItems: [],
      pickedDate: null,
      formattedPickedDate: '',
      datePickerMenu: false,
      locale: 'en',
      businessStartHour: moment('2020-01-01T09:00:00'),
      businessEndHour: moment('2020-01-01T19:00:00'),
      holidays: [],
      rules: {},
      isFormValid: false,
      FailedGetStore: false,
    }),
    computed: {
      year: function () {
        return moment(this.pickedDate).format('YYYY')
      },
      month: function () {
        return moment(this.pickedDate).format('MM')
      },
    },
    watch: {
      selectedStore: function () {
        const s = this.storeList.find(x => x._id === this.selectedStore)
        this.businessStartHour = this.calcBusinessHour(s.start_time, s.start_time_holiday, true)
        this.businessEndHour = this.calcBusinessHour(s.end_time, s.end_time_holiday, false)
        this.hours = this.getHours(this.businessStartHour, this.businessEndHour, 1)
        this.selectedItems = [this.hours.map(x => 0), this.hours.map(x => 0)]
      },
      pickedDate: function () {
        this.formattedPickedDate = this.pickedDate.replace('-', '/')
      },
    },
    mounted () {
      this.rules = {
        required: value => value !== '' || this.$t('reservation_capacity_entry.errors.required'),
        natural: value => (Number.isInteger(value) && value >= 0) || this.$t('reservation_capacity_entry.errors.not_natural'),
      }
      this.locale = process.env.VUE_APP_I18N_LOCALE
      moment.locale(this.locale)
      const now = moment()
      this.pickedDate = now.format('YYYY-MM')
      const st = this.businessStartHour
      const en = this.businessEndHour
      this.hours = this.getHours(st, en, 1)
      this.selectedItems = [this.hours.map(x => 0), this.hours.map(x => 0)]

      api.getStore().then(res => {
        if (res.data.length === 0) {
          this.storeList = []
          this.FailedGetStore = true
        } else {
          this.storeList = res.data
          this.storeList.sort(function (a, b) {
            if (a.name < b.name) {
              return -1
            }
            if (a.name > b.name) {
              return 1
            }
            return 0
          })
          this.selectedStore = res.data[0]._id // 先頭要素を初期値とする
          this.FailedGetStore = false
          this.businessStartHour = this.calcBusinessHour(res.data[0].start_time, res.data[0].start_time_holiday, true)
          this.businessEndHour = this.calcBusinessHour(res.data[0].end_time, res.data[0].end_time_holiday, false)
          this.FailedGetStore = false
        }
        this.isProcessing = false
      }).catch(e => {
        console.log(e)
        this.isProcessing = false
        this.FailedGetStore = true
      })
      this.holidays = holiday.getHolidays('ja')
    },
    methods: {
      getHours (startTime, endTime, duration = 1) {
        let tmp = startTime.clone()
        const ret = []
        while (tmp.isBefore(endTime)) {
          ret.push(tmp.format('HH:mm'))
          tmp = tmp.add(duration, 'hours')
        }
        return ret
      },
      enterReservationCapacity () {
        this.showDialog = false

        try {
          const weekdays = this.selectedItems[0]
          const holidays = this.selectedItems[1]
          const start = moment('2020-01-01T00:00:00')
          const end = moment('2020-01-02T00:00:00')
          const duration = this.getHours(start, end)
          const businessHours = this.getHours(this.businessStartHour, this.businessEndHour)
          const days = moment(this.pickedDate, 'YYYY-MM').daysInMonth()
          const dataToSend = []
          for (let i = 1; i <= days; i++) {
            const tmp = {
              store_id: this.selectedStore,
              date: this.pickedDate + '-' + ('00' + i).slice(-2),
            }
            const cap = {}
            duration.forEach(d => {
              cap[d] = 0
            })
            tmp.capacity = cap
            let daytype = null
            if (this.isHoliday(tmp.date)) {
              daytype = holidays
            } else {
              daytype = weekdays
            }
            for (let j = 0; j < businessHours.length; j++) {
              tmp.capacity[businessHours[j]] = daytype[j]
            }
            dataToSend.push(tmp)
          }

          api.enterReservationCapacity(dataToSend).then(res => {
            this.$router.push({
              name: 'Resesrvation Capacity Browsing',
              params: {
                store_id: this.selectedStore,
                picked_date: this.pickedDate,
              },
            })
          }).catch(e => {
            if (e.code === 'ECONNABORTED') {
              this.errors.push(this.$t('errors.api_timeout'))
            } else {
              this.errors.push(this.$t('errors.unexpected'))
            }
            this.isProcessing = false
          })
        } catch (e) {
          console.log(e)
          this.errors.push(this.$t('errors.unexpected'))
          this.isProcessing = false
        }
      },
      async checkReservationCapacityAlreadyEntered () {
        const capacities = await api.getReservationCapacityMonthly(this.selectedStore, this.year, this.month).catch(e => {
          console.log(e)
          if (e.response && e.response.status === 404) {
            return { data: [] }
          }
        })
        return capacities.data.length > 0
      },
      entry () {
        this.isProcessing = true
        this.checkReservationCapacityAlreadyEntered().then(res => {
          if (res) {
            this.showDialog = true
          } else {
            this.enterReservationCapacity()
          }
        }).catch(e => {
          console.log(e)
          this.isProcessing = false
        })
      },
      isHoliday (date) {
        const tmp = moment(date, 'YYYY-MM-DD')
        const variant = tmp.format('YYYY-M-D')
        const day = tmp.get('day')
        if (day === 0 || day === 6) {
          return true
        }
        return (this.holidays.findIndex(x => x[0] === date || x[0] === variant) >= 0)
      },
      calcBusinessHour (weekdayTime, holidayTime, isStart) {
        weekdayTime = moment(weekdayTime, 'HH:mm')
        holidayTime = moment(holidayTime, 'HH:mm')

        // 平日と休日の営業時間のうち、始業時間は早い方、就業時間は遅い方を採用
        let t = null
        if (isStart) {
          t = weekdayTime.isAfter(holidayTime) ? holidayTime : weekdayTime
        } else {
          t = weekdayTime.isAfter(holidayTime) ? weekdayTime : holidayTime
        }

        // 始業時間の場合、0分きっかり開始でなければ1時間プラスする
        if (isStart && (t.minute() !== 0)) {
          t.add(1, 'h')
        }
        // 分を切り捨てる
        t.minutes(0)

        return t
      },
      closeDialog () {
        this.isProcessing = false
        this.showDialog = false
      },
    },
  }
</script>
<style scoped>
.line-break {
  white-space:pre-wrap;
  word-wrap:break-word;
}
.v-progress-circular {
  margin: 1rem;
}

.hoge table {
  width: auto!important;
}
table {
  table-layout: fixed;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  width: auto!important;
}
th, td {
  font-weight: 300;
  padding: 6px 8px!important;
  height: 16px!important;
  max-width: 180px!important;
  min-width: 80px!important;
  border-width: 1px 0px; /* 上下だけ引く */
   border-color: lightgray;
}
.right-input {
  padding-top: 0px;
}
.right-input >>> input {
  text-align: right!important;
}
.v-picker.v-card {
  margin: 0px;
}
.text-color-absent {
  color: lightgray;
}
tbody td {
  padding: 0px 8px!important;
}
thead th {
  color: white!important;
}
.top-card {
  margin-top: 0px;
  width: fit-content;
}
</style>
