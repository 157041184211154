import axios from 'axios'
import moment from 'moment'

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.post['Access-Control-Allow-Credentials'] = 'true'
axios.defaults.withCredentials = true
const DOMAIN = process.env.VUE_APP_API_DOMAIN
// console.log(process.env)
// const DOMAIN = 'http://192.168.99.100:5000'
// const DOMAIN = 'http://localhost:5000'
const DEFAULT_TIMEOUT_MS = 1000 * 120

export function login (params) {
  return axios.post(DOMAIN + '/api/login', params, { timeout: DEFAULT_TIMEOUT_MS })
}
export function logout () {
  return axios.post(DOMAIN + '/api/logout', {})
}
export function protectedApi () {
  return axios.get(DOMAIN + '/api/protected', {})
}

export function getSchedule () {
  return axios.get(DOMAIN + '/api/schedule')
}
export function entrySchedule (params) {
  return axios.post(DOMAIN + '/api/schedule', params)
}
export function updateSchedule (scheduleId, params) {
  // TODO: escape id
  return axios.post(DOMAIN + '/api/schedule/' + scheduleId, params)
}
export function deleteSchedule (scheduleId) {
  // TODO: escape id
  return axios.delete(DOMAIN + '/api/schedule/' + scheduleId)
}

export function getMonthlyShift (storeId, targetYear, targetMonth) {
  const yyyymm = targetYear + '-' + ('0' + targetMonth).slice(-2)
  return axios.get(DOMAIN + '/api/reservation_shift', {
    params: {
      store_id: storeId,
      year_month: yyyymm,
    },
    timeout: DEFAULT_TIMEOUT_MS,
  })
}

export function entryReservationShift (shifts) {
  return axios.post(DOMAIN + '/api/reservation_shift', shifts, { timeout: DEFAULT_TIMEOUT_MS })
}

export function updateOneReservationShift (shiftId, shift) {
  return axios.put(DOMAIN + '/api/reservation_shift/' + shiftId, shift, { timeout: DEFAULT_TIMEOUT_MS })
}

export function entryOneReservationShift (shift) {
  return axios.post(DOMAIN + '/api/reservation_shift/one', shift, { timeout: DEFAULT_TIMEOUT_MS })
}

export function getStoreStaffs (storeId) {
  // const ret = [
  //   {
  //     id: 'xxx',
  //     employee_id: 'xxx',
  //     role: 'staff',
  //     store_id: storeId,
  //     first_name: 'test',
  //     last_name: 'user',
  //     sex: 'male',
  //     nickname: 'Taro',
  //     extra_attribute: {
  //       extra1: '',
  //       extra2: '',
  //     },
  //   },
  //   {
  //     id: 'yyy',
  //     employee_id: 'yyy',
  //     role: 'staff',
  //     store_id: storeId,
  //     first_name: 'test',
  //     last_name: 'user',
  //     sex: 'male',
  //     nickname: 'Michael',
  //     extra_attribute: {
  //       extra1: '',
  //       extra2: '',
  //     },
  //   },
  //   {
  //     id: 'zzz',
  //     employee_id: 'zzz',
  //     role: 'staff',
  //     store_id: storeId,
  //     first_name: 'hoge',
  //     last_name: 'fuga',
  //     sex: 'male',
  //     nickname: 'Angus',
  //     extra_attribute: {
  //       extra1: '',
  //       extra2: '',
  //     },
  //   },
  //   {
  //     id: 'abc',
  //     employee_id: 'abc',
  //     role: 'staff',
  //     store_id: storeId,
  //     first_name: 'hoge',
  //     last_name: 'fuga',
  //     sex: 'male',
  //     nickname: 'Malcom',
  //     extra_attribute: {
  //       extra1: '',
  //       extra2: '',
  //     },
  //   },
  //   {
  //     id: 'def',
  //     employee_id: 'def',
  //     role: 'staff',
  //     store_id: storeId,
  //     first_name: 'hoge',
  //     last_name: 'fuga',
  //     sex: 'male',
  //     nickname: 'Brian',
  //     extra_attribute: {
  //       extra1: '',
  //       extra2: '',
  //     },
  //   },
  //   {
  //     id: 'ghi',
  //     employee_id: 'ghi',
  //     role: 'staff',
  //     store_id: storeId,
  //     first_name: 'hoge',
  //     last_name: 'fuga',
  //     sex: 'male',
  //     nickname: 'Phil',
  //     extra_attribute: {
  //       extra1: '',
  //       extra2: '',
  //     },
  //   },
  // ]
  // // 例)2秒スリープさせる
  // const _sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
  // return new Promise((resolve, reject) => {
  //   _sleep(2000).then(x => {
  //     resolve(ret)
  //   })
  // })
  return axios.get(DOMAIN + '/api/employee_profile', {
    params: {
      store_id: storeId,
    },
    timeout: DEFAULT_TIMEOUT_MS,
  })
}

export function getAllEmployees () {
  if (localStorage.getItem('role') === 'manager') {
    return axios.get(DOMAIN + '/api/employee_profile', { timeout: DEFAULT_TIMEOUT_MS })
  } else {
    const stid = localStorage.getItem('storeId')
    return axios.get(DOMAIN + '/api/employee_profile?store_id=' + stid, {
      timeout: DEFAULT_TIMEOUT_MS,
    })
  }
}

export function getEmployee (employeeId) {
  const stid = localStorage.getItem('storeId')
  // return axios.get(DOMAIN + '/api/employee_profile?employee_id=' + employeeId, { timeout: DEFAULT_TIMEOUT_MS })
  return axios.get(DOMAIN + '/api/employee_profile?store_id=' + stid + '&employee_id=' + employeeId, { timeout: DEFAULT_TIMEOUT_MS })
}

export function getEmployeesOfStore (storeId) {
  return axios.get(DOMAIN + '/api/employee_profile?store_id=' + storeId, { timeout: DEFAULT_TIMEOUT_MS })
}

export function getShiftCategory (storeId) {
  // const ret = [
  //   {
  //     id: '早番',
  //     store_id: storeId,
  //     name: '早番',
  //     start_time: '09:00',
  //     end_time: '15:00',
  //   },
  //   {
  //     id: '遅番',
  //     store_id: storeId,
  //     name: '遅番',
  //     start_time: '13:00',
  //     end_time: '19:00',
  //   },
  // ]
  // return new Promise((resolve, reject) => {
  //   resolve(ret)
  // })
  return axios.get(DOMAIN + '/api/shift_category', {
    params: {
      store_id: storeId,
    },
    timeout: DEFAULT_TIMEOUT_MS,
  })
}

export function enterShiftCategory (shiftCategory) {
  // console.log(shiftCategory)
  // const _sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
  // return new Promise((resolve, reject) => {
  //   _sleep(2000).then(x => {
  //     resolve({})
  //     // reject(ret)
  //   })
  // })
  return axios.post(DOMAIN + '/api/shift_category', shiftCategory, {
    timeout: DEFAULT_TIMEOUT_MS,
  })
}

export function updateShiftCategory (storeId, shiftCategory) {
  return axios.put(DOMAIN + '/api/shift_category/' + storeId, shiftCategory, {
    timeout: DEFAULT_TIMEOUT_MS,
  })
}

export function deleteShiftCategory (storeId) {
  return axios.delete(DOMAIN + '/api/shift_category/' + storeId, {
    timeout: DEFAULT_TIMEOUT_MS,
  })
}

export function getStore () {
  // const ret = [
  //   {
  //     id: '1',
  //     name: '銀座',
  //     start_time: '10:00',
  //     end_time: '20:00',
  //   },
  //   {
  //     id: '3',
  //     name: '銀座（GOLD会員用）',
  //     start_time: '00:00',
  //     end_time: '24:00',
  //   },
  //   {
  //     id: '2',
  //     name: '新宿',
  //     start_time: '09:00',
  //     end_time: '21:00',
  //   },
  // ]
  // // 例)2秒スリープさせる
  // const _sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
  // return new Promise((resolve, reject) => {
  //   _sleep(2000).then(x => {
  //     resolve(ret)
  //     // reject(ret)
  //   })
  // })

  if (localStorage.getItem('role') === 'manager') {
    return axios.get(DOMAIN + '/api/store_info', {
      timeout: DEFAULT_TIMEOUT_MS,
    })
  } else {
    const stid = localStorage.getItem('storeId')
    return axios.get(DOMAIN + '/api/store_info/' + stid, {
      timeout: DEFAULT_TIMEOUT_MS,
    }).then(res => {
      res.data = [res.data]
      return res
    })
  }
}

export function enterStoreInfo (storeInfo) {
  // console.log(storeInfo)
  // const ret = {
  //   data: {
  //     store_id: 'xxx',
  //   },
  // }
  // const _sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
  // return new Promise((resolve, reject) => {
  //   _sleep(2000).then(x => {
  //     resolve(ret)
  //     // reject(ret)
  //   })
  // })
  return axios.post(DOMAIN + '/api/store_info', storeInfo, {
    timeout: DEFAULT_TIMEOUT_MS,
  })
}

export function enterEmployeeInfo (employeeInfo) {
  return axios.post(DOMAIN + '/api/employee', employeeInfo, {
    timeout: DEFAULT_TIMEOUT_MS,
  })
}
export function updateEmployeeInfo (employeeInfo) {
  const encodedEmployeeId = encodeURIComponent(employeeInfo.employee_id)
  return axios.put(DOMAIN + '/api/employee/' + encodedEmployeeId, employeeInfo, {
    timeout: DEFAULT_TIMEOUT_MS,
  })

  // UI単体テスト用
  // const ret = {
  //   response: {
  //     status: 200,
  //     message: 'ok',
  //   },
  //   // response: {
  //   //   status: 403,
  //   //   message: 'Forbidden',
  //   // },
  //   // response: {
  //   //   status: 400,
  //   //   message: 'Bad request',
  //   // },
  // }
  // const _sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
  // return new Promise((resolve, reject) => {
  //   _sleep(1000 * 3).then(x => {
  //     resolve(ret)
  //     // reject(ret)
  //   })
  // })
}

export function deleteEmployee (employeeId) {
  const encodedEmployeeId = encodeURIComponent(employeeId)
  const body = {
    today: moment().format('YYYY-MM-DD'), // デフォルトでクライアントの現地時刻の文字列となる
  }
  return axios.post(DOMAIN + '/api/employee/' + encodedEmployeeId, body, {
    timeout: DEFAULT_TIMEOUT_MS,
  })
}

export function updateStoreInfo (storeId, storeInfo) {
  return axios.put(DOMAIN + '/api/store_info/' + storeId, storeInfo, {
    timeout: DEFAULT_TIMEOUT_MS,
  })
}

export function getReservationCapacityMonthly (storeId, targetYear, targetMonth) {
  return axios.get(DOMAIN + '/api/reservation_capacity', {
    params: {
      store_id: storeId,
      year_month: moment(new Date(targetYear, targetMonth - 1, 1)).format('YYYY-MM'),
    },
    timeout: DEFAULT_TIMEOUT_MS,
  })
}

export function enterReservationCapacity (capacities) {
  return axios.post(DOMAIN + '/api/reservation_capacity', capacities, { timeout: DEFAULT_TIMEOUT_MS })
}

export function changePassword (currentPass, newPass) {
  const body = {
    current_password: currentPass,
    new_password: newPass,
  }
  return axios.put(DOMAIN + '/api/password_change', body, { timeout: DEFAULT_TIMEOUT_MS })
  // UI単体テスト用
  // const ret = {
  //   // response: {
  //   //   status: 200,
  //   //   message: 'ok',
  //   // },
  //   // response: {
  //   //   status: 403,
  //   //   message: 'Forbidden',
  //   // },
  //   response: {
  //     status: 400,
  //     message: 'Bad request',
  //   },
  // }
  // const _sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
  // return new Promise((resolve, reject) => {
  //   _sleep(1000 * 3).then(x => {
  //     // resolve(ret)
  //     reject(ret)
  //   })
  // })
}

export function updateReservationCapacity (capacityId, params) {
  return axios.put(DOMAIN + '/api/reservation_capacity/' + capacityId, params, { timeout: DEFAULT_TIMEOUT_MS })
}

export function searchCustomer (params) {
  return axios.put(DOMAIN + '/api/customer', params, { timeout: DEFAULT_TIMEOUT_MS })
}

export function downloadCustomer (params) {
  return axios.put(DOMAIN + '/api/customer_download', params, { timeout: DEFAULT_TIMEOUT_MS })
}

export function lineDownloadCustomer (params) {
  return axios.put(DOMAIN + '/api/line_audience_download', params, { timeout: DEFAULT_TIMEOUT_MS })
}

export function getVenuesList (params) {
  return axios.get(DOMAIN + '/api/venues_list', params, { timeout: DEFAULT_TIMEOUT_MS })
}

export function getCustomer (customerId) {
  return axios.get(DOMAIN + '/api/customer/' + customerId, { timeout: DEFAULT_TIMEOUT_MS })
}

export function deleteCustomer (customerId) {
  return axios.delete(DOMAIN + '/api/customer/' + customerId, { timeout: DEFAULT_TIMEOUT_MS })
}

export function getCustomerPointHistories (customerId) {
  return axios.get(DOMAIN + '/api/customer/point_histories/' + customerId, { timeout: DEFAULT_TIMEOUT_MS })
}

export function getCustomerReservationMonthly (storeId, targetYear, targetMonth) {
  const yyyymm = targetYear + '-' + ('0' + targetMonth).slice(-2)
  return axios.get(DOMAIN + '/api/customer_reservation?store_id=' + storeId + '&year_month=' + yyyymm,
                   { timeout: DEFAULT_TIMEOUT_MS })
}

export function getCustomerReservationHistory (customerId, storeId) {
  if (storeId !== null) {
    return axios.get(DOMAIN + '/api/customer_reservation/' + customerId + '?store_id=' + storeId, { timeout: DEFAULT_TIMEOUT_MS })
  } else {
    return axios.get(DOMAIN + '/api/customer_reservation/' + customerId, { timeout: DEFAULT_TIMEOUT_MS })
  }
}

export function updateCustomerReservation (id, params) {
  return axios.put(DOMAIN + '/api/customer_reservation/' + id, params, { timeout: DEFAULT_TIMEOUT_MS })
}

export function getDashboard (storeId, targetYear, targetFlg) {
  return axios.get(DOMAIN + '/api/dashboard', {
    params: {
      store_id: storeId,
      year: targetYear,
      aggregation_flg: targetFlg,
    },
    timeout: DEFAULT_TIMEOUT_MS,
  })
}

// export function getDashboard (storeId, targetYear, targetFlg) {
//   return axios.get(DOMAIN + '/api/dashboard?store_id=' + storeId +
//                                            '&year=' + targetYear +
//                                            '&aggregation_flg=' + targetFlg,
//                            { timeout: DEFAULT_TIMEOUT_MS })
// }
export function deleteCustomerReservation (id) {
  return axios.delete(DOMAIN + '/api/customer_reservation/' + id, { timeout: DEFAULT_TIMEOUT_MS })
}

export function resetPassword (id) {
  return axios.post(DOMAIN + '/api/password_reset/' + id, {}, { timeout: DEFAULT_TIMEOUT_MS })
}

export function countCustomerReservation (params) {
  return axios.put(DOMAIN + '/api/customer_reservation_count', params, { timeout: DEFAULT_TIMEOUT_MS })
}

export function updateCustomer (customerId, params) {
  return axios.put(DOMAIN + '/api/customer/' + customerId, params, { timeout: DEFAULT_TIMEOUT_MS })
}

export function getRewards () {
  return axios.get(DOMAIN + '/api/rewards', {}, { timeout: DEFAULT_TIMEOUT_MS })
}

export function getRewardHistory (startDate, endDate, projectId, shortCustomerId) {
  let params = `?start_date=${startDate}&end_date=${endDate}`
  params += projectId ? `&project_id=${projectId}` : ''
  params += shortCustomerId ? `&short_customer_id=${shortCustomerId}` : ''
  return axios.get(DOMAIN + '/api/reward_history' + params, {}, { timeout: DEFAULT_TIMEOUT_MS })
}

export function getHealth () {
  return axios.get(DOMAIN + '/api/health', {}, { timeout: DEFAULT_TIMEOUT_MS })
}
