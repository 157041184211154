<template>
  <v-container>
    <v-container
      v-show="isProcessing"
      fluid
      tag="section"
    >
      <v-row justify="center">
        <v-progress-circular
          indeterminate
          color="primary"
          :size="100"
        />
      </v-row>
    </v-container>

    <v-container
      id="grid"
      :class="{ hide: isProcessing }"
      fluid
      tag="section"
    >
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            v-model="selectedStore"
            :items="storeList"
            item-text="name"
            item-value="_id"
            :label="$t('reservation_capacity_entry.store_selector_label')"
            prepend-icon="mdi-map-marker-radius"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
          <v-menu
            v-model="datePickerMenu"
            offset-y
            :close-on-content-click="false"
            max-width="auto"
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="formattedPickedDate"
                :label="$t('reservation_capacity_entry.date_picker_label')"
                readonly
                prepend-icon="mdi-calendar"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="pickedDate"
              type="month"
              :locale="locale"
              @click="datePickerMenu = false"
            />
          </v-menu>
        </v-col>
      </v-row>

      <material-alert
        v-for="error in errors"
        :key="error"
        color="error"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ error }}
      </material-alert>

      <material-alert
        v-if="FailedGetStore"
        color="error"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ $t('entry.errors.store_api') }}
      </material-alert>

      <v-card
        class="calendar"
        :class="{ hide: hideCalendar || FailedGetStore }"
      >
        <full-calendar
          ref="fullCalendar"
          :options="calendarOptions"
        />
      </v-card>
    </v-container>

    <v-dialog
      v-if="!isReadOnly"
      v-model="showContent"
      persistent
      max-width="600px"
    >
      <v-card
        v-if="showContent"
      >
        <v-card-title>
          <span class="text-h5">{{ reformatMMDD(selectedEvent.start) }}{{ $t('reservation_capacity_browsing.update.title.suffix') }}</span>
        </v-card-title>
        <v-card-text>
          <material-alert
            v-for="error in errorsInSending"
            :key="error"
            color="error"
            dark
            dismissible
            icon="mdi-bell"
          >
            {{ error }}
          </material-alert>
          <v-form v-model="valid">
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="displayDetail.startTime"
                    :label="$t('reservation_capacity_browsing.update.start_time')"
                    type="time"
                    disabled
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="displayDetail.endTime"
                    :label="$t('reservation_capacity_browsing.update.end_time')"
                    type="time"
                    disabled
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model.number="displayDetail.title"
                    :label="$t('reservation_capacity_browsing.update.capacity')"
                    type="number"
                    required
                    min="0"
                    :rules="[rules.required, rules.natural]"
                    class="black-label"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey lighten-2"
            :disabled="sending"
            @click="closeDialog"
          >
            {{ $t('reservation_capacity_browsing.update.button.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!valid"
            :loading="sending"
            @click="updateAndColseDialog"
          >
            {{ $t('reservation_capacity_browsing.update.button.update') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import FullCalendar from '@fullcalendar/vue'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import interactionPlugin from '@fullcalendar/interaction'
  import timeGridPlugin from '@fullcalendar/timegrid'
  import allLocales from '@fullcalendar/core/locales-all'
  import moment from 'moment'
  import * as api from '../api.js'
  import * as holiday from '../holiday.js'
  import MaterialAlert from '../components/atoms/MaterialAlert.vue'

  const holidays = holiday.getHolidays('ja')
  function isHoliday (date) {
    const tmp = moment(date, 'YYYY-MM-DD')
    const variant = tmp.format('YYYY-M-D')
    const day = tmp.get('day')
    if (day === 0 || day === 6) {
      return true
    }
    return (holidays.findIndex(x => x[0] === date || x[0] === variant) >= 0)
  }

  class DisplayDetail {
    constructor () {
      this.id = ''
      this.title = 0
      this.startTime = null
      this.endTime = null
      this.date = null
    }

    startDatetime () {
      return moment(this.date + ' ' + this.startTime)
    }

    endDatetime () {
      return moment(this.date + ' ' + this.endTime)
    }

    updateNewEvent (newEvent) {
      newEvent.title = Number(this.title)
      newEvent.start = this.startDatetime().format('YYYY-MM-DD HH:mmZ')
      newEvent.end = this.endDatetime().format('YYYY-MM-DD HH:mmZ')
    }

    updateCalendarEvent (calendarEvent) {
      calendarEvent.setProp('title', this.title)
      calendarEvent.setStart(this.startDatetime().format('YYYY-MM-DD HH:mmZ'))
      calendarEvent.setEnd(this.endDatetime().format('YYYY-MM-DD HH:mmZ'))
      if (this.title === 0) {
        calendarEvent.setProp('backgroundColor', 'gray')
        calendarEvent.setProp('borderColor', 'gray')
      } else {
        calendarEvent.setProp('backgroundColor', '#3788d8')
        calendarEvent.setProp('borderColor', '#3788d8')
      }
    }

    clear () {
      this.id = ''
      this.title = 0
      this.startTime = null
      this.endTime = null
      this.date = null
    }

    toApi () {
      const tmp = {}
      tmp[this.startTime] = this.title
      const event = {
        _id: this.id,
        capacity: tmp,
      }
      return event
    }

    static fromCalendarEvent (calendarEvent) {
      const ret = new DisplayDetail()
      ret.id = calendarEvent.id
      ret.title = Number(calendarEvent.title)
      ret.startTime = moment(calendarEvent.start).format('HH:mm')
      ret.endTime = moment(calendarEvent.end).format('HH:mm')
      ret.date = moment(calendarEvent.end).format('YYYY-MM-DD')
      return ret
    }
  }

  export default {
    name: 'CapacityBrowsing',
    components: {
      MaterialAlert,
      FullCalendar, // make the <FullCalendar> tag available
    },
    data () {
      return {
        calendarApi: null,
        showContent: false,
        selectedDate: null,
        valid: false,
        displayDetail: new DisplayDetail(),
        selectedEvent: null,
        calendarOptions: {
          plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
          headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          },
          initialView: 'timeGridWeek',
          selectable: false,
          select: this.dateClick,
          eventClick: this.eventClick,
          calendarEvents: this.events,
          editable: false,
          events: [],
          height: '75vh',
          locales: allLocales,
          locale: 'ja',
          allDaySlot: false,
          validRange: {},
          dayHeaderClassNames: function (args) {
            if (isHoliday(args.date)) {
              return ['holiday']
            }
          },
        },
        pickedDate: null,
        formattedPickedDate: '',
        datePickerMenu: false,
        locale: 'en',
        storeList: [],
        selectedStore: null,
        isProcessing: true,
        isReadOnly: true,
        FailedGetStore: false,
        hideCalendar: false,
        errors: [],
        calendarCurrentDate: null,
        rules: [],
        sending: false,
        errorsInSending: [],
      }
    },
    computed: {
      year: function () {
        return moment(this.pickedDate).format('YYYY')
      },
      month: function () {
        return moment(this.pickedDate).format('MM')
      },
    },
    watch: {
      selectedStore: function (n, o) {
        // 初期状態ではmountedで更新する
        if (o !== null) {
          this.errors = []
          this.getReservationCapacity()
        }
      },
      pickedDate: function (n, o) {
        this.formattedPickedDate = this.pickedDate.replace('-', '/')
        // 初期状態ではmountedで更新する
        if (o !== null) {
          this.errors = []
          this.getReservationCapacity()
        }
        this.calendarOptions.validRange = {}
        this.calendarApi.setOption('validRange', this.generateValidRange(this.pickedDate))
        // 有効範囲の設定が確定して現在日が移動したのが確定したら月初に移動する
        // 変数の変更をフックして月初に移動する
        // 上から順に書き下すと月初にうまく移動できないため
        this.calendarCurrentDate = this.calendarApi.getDate()
      },
      calendarCurrentDate: function (value, oldValue) {
        // console.log(value, oldValue)
        // 当月以外は対象月の月初の週を表示する
        if (this.pickedDate !== moment().format('YYYY-MM')) {
          this.calendarApi.gotoDate(this.pickedDate + '-01')
        } else {
          this.calendarApi.gotoDate(moment().format('YYYY-MM-DD'))
        }
      },
    },
    mounted () {
      this.rules = {
        required: value => value !== '' || this.$t('reservation_capacity_browsing.update.errors.required'),
        natural: value => (Number.isInteger(value) && value >= 0) || this.$t('reservation_capacity_browsing.update.errors.not_natural'),
      }
      this.calendarApi = this.$refs.fullCalendar.getApi()
      this.locale = process.env.VUE_APP_I18N_LOCALE
      moment.locale(this.locale)

      if (this.$can('component.update_reservation_capacity')) {
        this.isReadOnly = false
      }

      api.getStore().then(res => {
        if (res.data.length === 0) {
          this.storeList = []
          this.FailedGetStore = true
        } else {
          this.storeList = res.data
          this.storeList.sort(function (a, b) {
            if (a.name < b.name) {
              return -1
            }
            if (a.name > b.name) {
              return 1
            }
            return 0
          })
          // 登録後遷移の場合、登録対象の店舗・年月を表示する
          if (this.$route.params.store_id && this.$route.params.picked_date) {
            this.selectedStore = this.$route.params.store_id
            this.pickedDate = this.$route.params.picked_date
          } else {
            this.selectedStore = res.data[0]._id // 先頭要素を初期値とする
            const now = moment()
            this.pickedDate = now.format('YYYY-MM')
          }
          this.FailedGetStore = false
          this.getReservationCapacity()
        }
      }).catch(e => {
        console.log(e)
        this.isProcessing = false
        this.FailedGetStore = true
      })
      this.calendarOptions.validRange = this.generateValidRange(this.pickedDate)
    },
    methods: {
      dateClick: function (info) {
        this.displayDetail.clear()
        this.selectedEvent = info
        // alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY)
        // alert('Current view: ' + info.view.type)
        this.displayDetail.date = moment(info.start).format('YYYY-MM-DD')
        this.displayDetail.startTime = moment(info.start).format('HH:mm')
        this.displayDetail.endTime = moment(info.end).format('HH:mm')
        this.showContent = true
      },
      eventClick: function (info) {
        this.selectedEvent = info.event
        this.showContent = true
        this.displayDetail = DisplayDetail.fromCalendarEvent(info.event)
      },
      closeDialog () {
        this.showContent = false
        this.selectedEvent = null
        this.errorsInSending = []
      },
      entryAndCloseDialog () {
        const info = this.selectedEvent
        this.displayDetail.updateNewEvent(info)
        // info.userId = '1'
        api.entrySchedule(info).then(res => {
          info.id = res.data.schedule_id
          this.calendarApi.addEvent(
            info,
          )
          // place, tileは、calendarApiから取り出すとextendedPropsの下に格納される
          this.closeDialog()
        })
      },
      apiToCalendarEvent (event) {
        return {
          title: event.title,
          start: event.start_at,
          end: event.finish_at,
          id: event.id,
        }
      },
      calendarEventToApi (calendarEvent) {
        const event = {
          title: calendarEvent.title,
          start: calendarEvent.start,
          end: calendarEvent.end,
        }
        if (calendarEvent.extendedProps !== undefined) {
          if (calendarEvent.extendedProps.place !== undefined) {
            event.place = calendarEvent.extendedProps.place
          }
          if (calendarEvent.extendedProps.contents !== undefined) {
            event.contents = calendarEvent.extendedProps.contents
          }
        }
        return event
      },
      deleteAndCloseDialog () {
        const id = this.selectedEvent.id
        api.deleteSchedule(id).then(e => {
          this.calendarApi.getEventById(id).remove()
          this.closeDialog()
        })
      },
      updateAndColseDialog () {
        this.errorsInSending = []
        this.sending = true
        api.updateReservationCapacity(this.displayDetail.id, this.displayDetail.toApi()).then(x => {
          this.displayDetail.updateCalendarEvent(this.selectedEvent)
          this.sending = false
          this.closeDialog()
        }).catch(e => {
          console.log(e)
          if (e.code === 'ECONNABORTED') {
            this.errorsInSending.push(this.$t('errors.api_timeout'))
          } else if (e.response !== undefined && e.response.status === 404) {
            this.errors.push(this.$t('reservation_capacity_browsing.errors.not_found'))
          } else {
            this.errorsInSending.push(this.$t('errors.unexpected'))
          }
          this.sending = false
        })
      },
      getReservationCapacity () {
        this.isProcessing = true
        this.hideCalendar = false

        // 前回のカレンダーデータをすべて削除する
        const eventSources = this.calendarApi.getEventSources()
        eventSources.forEach(e => {
          e.remove()
        })

        api.getReservationCapacityMonthly(this.selectedStore, this.year, this.month).then(res => {
          const currentEvents = []
          res.data.forEach(e => {
            const hours = Object.keys(e.capacity).sort()
            const l = hours.length
            for (let i = 0; i < l; i++) {
              const start = moment(e.date + '-' + hours[i], 'YYYY-MM-DD-HH:mm')
              const end = moment(e.date + '-' + hours[i + 1], 'YYYY-MM-DD-HH:mm')
              let calendarEvent = {}
              if (e.capacity[hours[i]] === 0) {
                calendarEvent = {
                  id: e._id,
                  title: Number(e.capacity[hours[i]]),
                  start: start.toDate(),
                  end: end.toDate(),
                  backgroundColor: 'gray',
                  borderColor: 'gray',
                }
              } else {
                calendarEvent = {
                  id: e._id,
                  title: Number(e.capacity[hours[i]]),
                  start: start.toDate(),
                  end: end.toDate(),
                }
              }
              currentEvents.push(calendarEvent)
            }
          })
          this.calendarApi.addEventSource(currentEvents)
          this.isProcessing = false
        }).catch(e => {
          console.log(e)
          if (e.code === 'ECONNABORTED') {
            this.errors.push(this.$t('errors.api_timeout'))
            this.hideCalendar = true
          } else if (e.response !== undefined && e.response.status === 404) {
            this.errors.push(this.$t('reservation_capacity_browsing.errors.not_found'))
          } else {
            this.errors.push(this.$t('errors.unexpected'))
            this.hideCalendar = true
          }
          this.isProcessing = false
        })
      },
      generateValidRange (yyyymm) {
        return {
          start: moment(yyyymm).startOf('month').format('YYYY-MM-DD'),
          end: moment(yyyymm).endOf('month').add(1, 'days').format('YYYY-MM-DD'),
        }
      },
      reformatMMDD (dateStr) {
        return moment(dateStr).format(this.$t('reservation_capacity_browsing.update.title.date_format'))
      },
    },
  }
</script>

<style>
.calendar {
  margin-top: 0px;
  margin-bottom: 0px;
}
.v-progress-circular {
  margin: 1rem;
}
.v-picker.v-card {
  margin: 0px;
}
/* スピナーを回すときのコンテンツ非表示用
 * v-showだとカレンダーの表示が崩れるため
 * v-ifだとdomが生成されず、カレンダーの初期化に失敗するため
 */
.hide {
  visibility:hidden;
}
.fc-col-header-cell.fc-day-sat {
  background-color: #d2d8fc;
}
.fc-col-header-cell.fc-day-sun {
  background-color:#fae1e1;
}
.holiday {
  background-color:#fae1e1;
}
.theme--light .v-input--is-disabled * {
  color: rgba(0,0,0,0.87) !important
}
.theme--light .black-label .v-label {
  color: rgba(0,0,0,0.87) !important
}
</style>
