<template>
  <v-container>
    <v-container
      v-if="isProcessing"
      id="grid"
      fluid
      tag="section"
    >
      <v-row justify="center">
        <v-progress-circular
          indeterminate
          color="primary"
          :size="100"
        />
      </v-row>
    </v-container>

    <v-container
      v-else
      id="grid"
      fluid
      tag="section"
    >
      <material-alert
        v-for="error in errors"
        :key="error"
        color="error"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ error }}
      </material-alert>

      <material-alert
        v-if="FailedGetStore"
        color="error"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ $t('entry.errors.store_api') }}
      </material-alert>

      <div
        v-if="!isProcessing && !FailedGetStore"
      >
        <v-form
          ref="forms"
          v-model="isFormValid"
          :disabled="isReadOnly"
        >
          <v-row>
            <v-col
              cols="12"
              sm="4"
              class="zero-padding"
            >
              <v-text-field
                v-model="store_code"
                type="text"
                :label="$t('store_entry.placeholder.store_code')"
                :rules="[requireRule, duplicateStoreCodeRule(storeList)]"
                prepend-icon="mdi-store"
                required
              />
            </v-col>
            <v-col
              cols="12"
              sm="8"
              class="zero-padding"
              style="padding-left: 16px;"
            >
              <v-text-field
                v-model="store_name"
                type="text"
                :label="$t('store_entry.placeholder.store_name')"
                :rules="[requireRule, duplicateStoreNameRule(storeNameList)]"
                prepend-icon="mdi-store"
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <!-- <v-col
              cols="12"
              sm="3"
            >
              <v-text-field
                v-model="postal_code"
                type="text"
                :label="$t('store_entry.placeholder.postal_code')"
                prepend-icon="mdi-map-marker-radius"
                required
              />
            </v-col> -->
            <!-- <v-col
              cols="12"
              sm="9"
            > -->
            <v-text-field
              v-model="address"
              type="text"
              :label="$t('store_entry.placeholder.address')"
              :rules="addressRules"
              prepend-icon="mdi-map-marker-radius"
              required
            />
            <!-- </v-col> -->
          </v-row>
          <v-row>
            <v-text-field
              v-model="phone_number"
              :label="$t('store_entry.placeholder.phone_number')"
              :rules="phoneRules"
              prepend-icon="mdi-phone"
              required
            />
          </v-row>
          <v-row>
            <v-text-field
              v-model="store_mail_address"
              type="email"
              :label="$t('store_entry.placeholder.store_mail_address')"
              :rules="[requireRule, emailFormatRule]"
              prepend-icon="mdi-email-open-outline"
              required
            />
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="4"
            >
              <v-icon>mdi-email-open-multiple-outline</v-icon>
              {{ $t('store_entry.placeholder.notification_mail_address') }}
              <v-btn
                v-if="!isReadOnly"
                class="mx-2"
                fab
                dark
                x-small
                color="primary"
                @click="addNotificationMailAddressForm"
              >
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="8"
            >
              <v-form
                ref="notification_mail_address"
                v-model="notification_mail_address_valid"
                :disabled="isReadOnly"
              >
                <v-row
                  v-for="(c, index) in notification_mail_address"
                  :key="index"
                >
                  <v-text-field
                    v-model="c.address"
                    type="email"
                    :rules="[requireRule, emailFormatRule, duplicateMailRule(notification_mail_address)]"
                    :label="$t('store_entry.placeholder.notification_mail_address_detail')"
                    required
                  />
                  <v-btn
                    v-if="!isReadOnly"
                    class="mx-2 disabled-button"
                    fab
                    dark
                    x-small
                    color="primary"
                    @click="deleteNotificationMailAddressForm(index)"
                  >
                    <v-icon dark>
                      mdi-minus
                    </v-icon>
                  </v-btn>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
          <business-hour-pair
            v-model="whole_business_hours_weekdays"
            :label="$t('store_entry.placeholder.whole_business_hours_weekdays')"
            :start-label="$t('store_entry.placeholder.start_time')"
            :end-label="$t('store_entry.placeholder.end_time')"
            :read-only="isReadOnly"
          />
          <business-hour-pair
            v-model="whole_business_hours_holidays"
            :label="$t('store_entry.placeholder.whole_business_hours_holidays')"
            :start-label="$t('store_entry.placeholder.start_time')"
            :end-label="$t('store_entry.placeholder.end_time')"
            :read-only="isReadOnly"
          />
          <business-hour-pair
            v-model="business_hours_weekdays"
            :label="$t('store_entry.placeholder.business_hours_weekdays')"
            :start-label="$t('store_entry.placeholder.start_time')"
            :end-label="$t('store_entry.placeholder.end_time')"
            :read-only="isReadOnly"
          />
          <business-hour-pair
            v-model="business_hours_holidays"
            :label="$t('store_entry.placeholder.business_hours_holidays')"
            :start-label="$t('store_entry.placeholder.start_time')"
            :end-label="$t('store_entry.placeholder.end_time')"
            :read-only="isReadOnly"
          />
          <v-row>
            <v-checkbox
              v-if="this.planId == 'MRK'"
              v-model="stripe_connection"
              :label="$t('store_entry.placeholder.stripe_connection')"
              color="indigo"
              hide-details
            />
            <v-checkbox
              v-else
              v-model="stripe_connection"
              :label="$t('store_entry.placeholder.stripe_connection')"
              color="indigo"
              hide-details
              disabled
            />
          </v-row>
          <v-row>
            <v-checkbox
              v-model="service_on_web"
              :label="$t('store_entry.placeholder.service_on_web')"
              color="indigo"
              hide-details
            />
          </v-row>
          <v-row
            v-if="service_on_web"
          >
            <!-- <v-text-field
              v-model="meeting_tool_name"
              type="text"
              label="ご利用予定のビデオ会議ツール名"
              prepend-icon="mdi-contacts-outline"
              :disabled="!service_on_web"
              required
            /> -->
            <v-select
              v-model="meeting_tool_name"
              :items="meetingToolList"
              item-text="name"
              item-value="name"
              :label="$t('store_entry.placeholder.video_meeting_tool_name')"
              prepend-icon="mdi-contacts-outline"
              :rules="[requiredIfCheckedRule(service_on_web)]"
            />
          </v-row>
          <!-- <v-row>
            <v-checkbox
              v-model="campaign"
              label="キャンペーン中"
              color="indigo"
              hide-details
            />
          </v-row> -->
          <!-- <v-row>
            <v-text-field
              v-model="google_calendar_id"
              type="text"
              label="GoogleカレンダーID"
              prepend-icon="mdi-calendar"
              required
            />
          </v-row> -->
          <v-form
            ref="shift_category"
            v-model="shift_category_valid"
            :disabled="isReadOnly"
          >
            <v-row>
              <v-col
                cols="12"
                sm="3"
                class="margin-additional"
              >
                <v-icon>mdi-alarm-multiple</v-icon>
                シフトカテゴリ
                <v-btn
                  v-if="!isReadOnly"
                  class="mx-2"
                  fab
                  dark
                  x-small
                  color="primary"
                  @click="addShiftCategoryForm"
                >
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                sm="9"
              >
                <v-row
                  v-for="(c, index) in shift_category"
                  :key="index"
                  align="center"
                >
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      v-model="c.name"
                      type="text"
                      :label="$t('store_entry.placeholder.category_name')"
                      :rules="[requireRule, duplicateShiftCategoryRule(shift_category)]"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="10"
                    sm="3"
                  >
                    <v-text-field
                      v-model="c.start_time"
                      type="time"
                      :label="$t('store_entry.placeholder.category_start_time')"
                      :rules="[requireRule, isBeforeRule(c.end_time)]"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="10"
                    sm="3"
                  >
                    <v-text-field
                      v-model="c.end_time"
                      type="time"
                      :label="$t('store_entry.placeholder.category_end_time')"
                      :rules="[requireRule, isAfterRule(c.start_time)]"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="2"
                    sm="2"
                  >
                    <v-btn
                      v-if="!isReadOnly && shift_category.length !== 1"
                      class="mx-2"
                      fab
                      dark
                      x-small
                      color="primary"
                      @click="deleteShiftCategoryForm(index)"
                    >
                      <v-icon dark>
                        mdi-minus
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-form>
        <v-row
          v-if="!isReadOnly"
          style="margin-top: 16px"
        >
          <v-col
            cols="8"
            class="red--text"
          >
            <span v-if="!isUpdate">
              {{ $t('store_entry.notification') }}
            </span>
          </v-col>
          <v-col
            cols="4"
          >
            <v-row justify="end">
              <v-btn
                v-if="isUpdate"
                to="/components/store-browsing"
                color="blue-grey lighten-2"
              >
                {{ $t('store_entry.button.return') }}
              </v-btn>
              <v-btn
                v-if="isUpdate"
                :disabled="!isFormValid || !business_hours_weekdays.valid || !business_hours_holidays.valid || !whole_business_hours_weekdays.valid || !whole_business_hours_holidays.valid || !shift_category_valid || !notification_mail_address_valid"
                color="primary"
                @click="update"
              >
                {{ $t('store_entry.button.update') }}
              </v-btn>
              <v-btn
                v-else
                :disabled="!isFormValid || !business_hours_weekdays.valid || !business_hours_holidays.valid || !whole_business_hours_weekdays.valid || !whole_business_hours_holidays.valid || !shift_category_valid || !notification_mail_address_valid"
                color="primary"
                @click="entry"
              >
                {{ $t('store_entry.button.entry') }}
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-container>

    <v-dialog
      v-model="showDialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t("reservation_capacity_entry.dialog.title") }}</span>
        </v-card-title>
        <v-card-text class="line-break">
          {{ $t("reservation_capacity_entry.dialog.description") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
          >
            {{ $t("reservation_capacity_entry.dialog.cancel") }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
          >
            {{ $t("reservation_capacity_entry.dialog.entry") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import * as api from '../api.js'
  import BusinessHourPair from '../components/atoms/BusinessHourPair.vue'
  import MaterialAlert from '../components/atoms/MaterialAlert.vue'

  export default {
    name: 'StoreEntry',
    components: {
      MaterialAlert,
      BusinessHourPair,
    },
    data () {
      return {
        planId: null,
        errors: [],
        showDialog: false,
        isProcessing: true,
        isReadOnly: true,
        storeList: [],
        storeNameList: [], // storeListから店舗名のみ抽出
        locale: 'en',
        rules: {},
        emailFormatRule: v => /.+@.+\..+/.test(v) || this.$t('store_entry.errors.email_format'),
        nameRules: [
          v => !!v || this.$t('store_entry.errors.required'),
        ],
        addressRules: [
          v => !!v || this.$t('store_entry.errors.required'),
        ],
        phoneRules: [
          v => !!v || this.$t('store_entry.errors.required'),
        ],
        duplicateStoreCodeRule: function (storeList) {
          return v => (v === '' || storeList.filter(x => x.store_code === v).length === 0) || this.$t('store_entry.errors.duplicate_store_name')
        },
        duplicateStoreNameRule: function (storeNames) {
          return v => storeNames.filter(x => x === v).length === 0 || this.$t('store_entry.errors.duplicate_store_name')
        },
        duplicateMailRule: function (emails) {
          const names = emails.map(x => x.address)
          return v => names.filter(x => x === v).length <= 1 || this.$t('store_entry.errors.duplicate_address')
        },
        duplicateShiftCategoryRule: function (shiftCategory) {
          const names = shiftCategory.map(x => x.name)
          return v => names.filter(x => x === v).length <= 1 || this.$t('store_entry.errors.duplicate_category')
        },
        isBeforeRule: function (endTime) {
          return v => (endTime === '' || moment(v, 'hh:mm') < moment(endTime, 'hh:mm')) || this.$t('store_entry.errors.invalid_time')
        },
        isAfterRule: function (startTime) {
          return v => (startTime === '' || moment(v, 'hh:mm') > moment(startTime, 'hh:mm')) || this.$t('store_entry.errors.invalid_time')
        },
        requireRule: v => !!v || this.$t('store_entry.errors.required'),
        requiredIfCheckedRule: function (checked) {
          return v => (!checked || !!v) || this.$t('store_entry.errors.required')
        },
        isFormValid: false,
        notification_mail_address_valid: false,
        shift_category_valid: false,
        FailedGetStore: false,
        stripe_connection: false,
        store_code: '',
        store_name: '',
        address: '',
        phone_number: '',
        store_mail_address: '',
        notification_mail_address: [
          {
            address: '',
          },
        ],
        google_calendar_id: '',
        meeting_tool_name: '',
        service_on_web: false,
        campaign: false,
        shift_category: [
          {
            name: '',
            start_time: '',
            end_time: '',
          },
        ],
        meetingToolList: [
          { name: 'Google Meet' },
          { name: 'Zoom' },
        ],
        isUpdate: false,
        storeId: '',
        deleteTargetShiftCategory: [],
        business_hours_weekdays: {
          start: '',
          end: '',
          valid: false,
        },
        business_hours_holidays: {
          start: '',
          end: '',
          valid: false,
        },
        whole_business_hours_weekdays: {
          start: '',
          end: '',
          valid: false,
        },
        whole_business_hours_holidays: {
          start: '',
          end: '',
          valid: false,
        },
      }
    },
    computed: {
    },
    watch: {
      notification_mail_address: {
        handler: function () {
          this.$nextTick(function () {
            if (this.$refs.notification_mail_address) {
              this.$refs.notification_mail_address.validate()
            }
          })
        },
        deep: true,
      },
      shift_category: {
        handler: function () {
          this.$nextTick(function () {
            if (this.$refs.shift_category) {
              this.$refs.shift_category.validate()
            }
          })
        },
        deep: true,
      },
    },
    mounted () {
      this.locale = process.env.VUE_APP_I18N_LOCALE
      moment.locale(this.locale)
      this.planId = localStorage.getItem('planId')
      if (this.$can('component.update_store')) {
        this.isReadOnly = false
      }

      // 更新と新規登録で同じコンポーネントを使用するため、この二者の行き来では再描画されない。
      // データの初期化を行うため、遷移時に処理を明示的に呼び出す。
      this.$router.beforeEach((to, from, next) => {
        if ((from.name === 'Store Update' && to.name === 'Store Entry') ||
          (from.name === 'Store Entry' && to.name === 'Store Update') ||
          (from.name === 'Store Update' && to.name === 'Store Update')) {
          // console.log('データクリア')
          // this.$options.data()ではダメ。data()で設定される値以外の情報は維持したいので、
          // 元からあるコンテキストthisを引き継ぐようにする。
          Object.assign(this.$data, this.$options.data.call(this))
          this.getStoreData()
        }
        next()
      })

      this.getStoreData()
    },
    methods: {
      enterStoreInfo () {
        this.showDialog = false

        try {
          const storeInfoToSend = {
            store_code: this.store_code,
            name: this.store_name,
            phone_number: this.phone_number,
            address: this.address,
            area_id: '', // 現時点では画面から登録不可
            mail_address: this.store_mail_address,
            notification_mail_address: this.notification_mail_address.map(x => x.address),
            campaign: this.campaign,
            online_support: this.service_on_web,
            stripe_connection: this.stripe_connection,
            stripe_code: '',
            video_meeting_tool_name: this.meeting_tool_name,
            video_meeting_tool_credential: {
              jwt: '',
              user: '',
            },
            google_calendar_id: this.google_calendar_id,
            google_calendar_credential: {
              refresh_token: '',
              client_id: '',
              client_secret: '',
              grant_type: '',
            },
            start_time: this.business_hours_weekdays.start,
            end_time: this.business_hours_weekdays.end,
            start_time_holiday: this.business_hours_holidays.start,
            end_time_holiday: this.business_hours_holidays.end,
            whole_start_time: this.whole_business_hours_weekdays.start,
            whole_end_time: this.whole_business_hours_weekdays.end,
            whole_start_time_holiday: this.whole_business_hours_holidays.start,
            whole_end_time_holiday: this.whole_business_hours_holidays.end,
          }
          const shiftCategoryToSend = this.shift_category.map(function (x) {
            return {
              name: x.name,
              start_time: x.start_time,
              end_time: x.end_time,
            }
          })
          api.enterStoreInfo(storeInfoToSend).then(res => {
            const storeId = res.data.store_id
            shiftCategoryToSend.forEach(x => {
              x.store_id = storeId
            })
            const se = []
            const errorCategory = []
            shiftCategoryToSend.forEach(s => {
              const tmp = api.enterShiftCategory(s).then(res => {
              }).catch(e => {
                errorCategory.push(s.name)
              })
              se.push(tmp)
            })
            Promise.all(se).then(res => {
              console.log(errorCategory)
              this.$router.push({
                name: 'Store Browsing',
                params: {
                  error_category: errorCategory,
                },
              })
            })
          }).catch(e => {
            if (e.code === 'ECONNABORTED') {
              this.errors.push(this.$t('errors.api_timeout'))
            } else {
              this.errors.push(this.$t('errors.unexpected'))
            }
            this.isProcessing = false
          })
        } catch (e) {
          console.log(e)
          this.errors.push(this.$t('errors.unexpected'))
          this.isProcessing = false
        }
      },
      entry () {
        this.isProcessing = true
        try {
          this.enterStoreInfo()
        } catch (e) {
          console.log(e)
          this.isProcessing = false
        }
      },
      closeDialog () {
        this.isProcessing = false
        this.showDialog = false
      },
      addNotificationMailAddressForm () {
        this.notification_mail_address.push(
          {
            address: '',
          },
        )
      },
      deleteNotificationMailAddressForm (id) {
        this.notification_mail_address.splice(id, 1)
      },
      addShiftCategoryForm () {
        this.shift_category.push(
          {
            name: '',
            start_time: '',
            end_time: '',
          },
        )
      },
      deleteShiftCategoryForm (id) {
        if (this.shift_category[id]._id) {
          this.deleteTargetShiftCategory.push(this.shift_category[id])
        }
        this.shift_category.splice(id, 1)
      },
      update () {
        this.isProcessing = true
        try {
          this.updateStoreInfo()
        } catch (e) {
          console.log(e)
          this.isProcessing = false
        }
      },
      updateStoreInfo () {
        this.showDialog = false

        try {
          const storeInfoToSend = {
            store_code: this.store_code,
            name: this.store_name,
            phone_number: this.phone_number,
            address: this.address,
            area_id: '', // 現時点では画面から登録不可
            mail_address: this.store_mail_address,
            notification_mail_address: this.notification_mail_address.map(x => x.address),
            campaign: this.campaign,
            online_support: this.service_on_web,
            stripe_connection: this.stripe_connection,
            stripe_code: '',
            video_meeting_tool_name: this.meeting_tool_name,
            video_meeting_tool_credential: {
              jwt: '',
              user: '',
            },
            google_calendar_id: this.google_calendar_id,
            google_calendar_credential: {
              refresh_token: '',
              client_id: '',
              client_secret: '',
              grant_type: '',
            },
            start_time: this.business_hours_weekdays.start,
            end_time: this.business_hours_weekdays.end,
            start_time_holiday: this.business_hours_holidays.start,
            end_time_holiday: this.business_hours_holidays.end,
            whole_start_time: this.whole_business_hours_weekdays.start,
            whole_end_time: this.whole_business_hours_weekdays.end,
            whole_start_time_holiday: this.whole_business_hours_holidays.start,
            whole_end_time_holiday: this.whole_business_hours_holidays.end,
          }
          const shiftCategoryToSend = this.shift_category.map(function (x) {
            return {
              _id: x._id,
              name: x.name,
              start_time: x.start_time,
              end_time: x.end_time,
            }
          })
          api.updateStoreInfo(this.storeId, storeInfoToSend).then(res => {
            shiftCategoryToSend.forEach(x => {
              x.store_id = this.storeId
            })
            const se = []
            const errorCategory = []
            shiftCategoryToSend.forEach(s => {
              let m = null
              if (s._id) {
                m = api.updateShiftCategory(s._id, s)
              } else {
                m = api.enterShiftCategory(s)
              }
              const tmp = m.then(res => {
              }).catch(e => {
                errorCategory.push(s.name)
              })
              se.push(tmp)
            })
            this.deleteTargetShiftCategory.forEach(s => {
              se.push(api.deleteShiftCategory(s._id).catch(e => {
                console.log(e)
                errorCategory.push(s.name)
              }))
            })
            Promise.all(se).then(res => {
              this.$router.push({
                name: 'Store Browsing',
                params: {
                  error_category: errorCategory,
                },
              })
            })
          }).catch(e => {
            if (e.code === 'ECONNABORTED') {
              this.errors.push(this.$t('errors.api_timeout'))
            } else if (e.response !== undefined && e.response.status === 409) {
              this.errors.push(this.$t('store_entry.errors.conflict'))
            } else {
              this.errors.push(this.$t('errors.unexpected'))
            }
            this.isProcessing = false
          })
        } catch (e) {
          console.log(e)
          this.errors.push(this.$t('errors.unexpected'))
          this.isProcessing = false
        }
      },
      // 店舗更新の場合に、現在の情報を各フィールドに格納する。一部バリデーションを更新用に変更する。
      setStoreInfoToFields (s) {
        if (this.$route.params.id) {
          this.isUpdate = true
          this.storeId = this.$route.params.id
          // const s = this.$route.params.store_info
          this.store_code = s.store_code
          this.store_name = s.name
          this.address = s.address
          this.phone_number = s.phone_number
          this.store_mail_address = s.mail_address
          this.notification_mail_address = s.notification_mail_address.map(x => ({ address: x }))
          this.business_hours_weekdays.start = s.start_time
          this.business_hours_weekdays.end = s.end_time
          this.business_hours_holidays.start = s.start_time_holiday
          this.business_hours_holidays.end = s.end_time_holiday
          this.business_hours_weekdays.valid = true
          this.business_hours_holidays.valid = true
          this.whole_business_hours_weekdays.start = s.whole_start_time
          this.whole_business_hours_weekdays.end = s.whole_end_time
          this.whole_business_hours_holidays.start = s.whole_start_time_holiday
          this.whole_business_hours_holidays.end = s.whole_end_time_holiday
          if (s.whole_start_time !== '' && s.whole_end_time !== '') {
            this.whole_business_hours_weekdays.valid = true
          }
          if (s.whole_start_time_holiday !== '' & s.whole_end_time_holiday !== '') {
            this.whole_business_hours_holidays.valid = true
          }
          this.google_calendar_id = s.google_calendar_id
          this.meeting_tool_name = s.video_meeting_tool_name
          this.service_on_web = s.online_support
          this.stripe_connection = s.stripe_connection
          this.campaign = s.campaign

          api.getShiftCategory(this.storeId).then(res => {
            this.shift_category = res.data
          })

          this.duplicateStoreNameRule = function (storeNames) {
            return v => (v === s.name || storeNames.filter(x => x === v).length === 0) || this.$t('store_entry.errors.duplicate_store_name')
          }
          this.duplicateStoreCodeRule = function (storeList) {
            return v => (v === '' || v === s.store_code || storeList.filter(x => x.store_code === v).length === 0) || this.$t('store_entry.errors.duplicate_store_name')
          }
        }
      },
      getStoreData () {
        api.getStore().then(res => {
          if (res.data.length === 0) {
            this.storeList = []
            this.FailedGetStore = false
          } else {
            this.storeList = res.data
            this.storeList.sort(function (a, b) {
              if (a.name < b.name) {
                return -1
              }
              if (a.name > b.name) {
                return 1
              }
              return 0
            })
            this.storeNameList = this.storeList.map(x => x.name)
            this.FailedGetStore = false

            // 店舗情報が渡されている場合は詳細参照・更新として値を各フィールドに当てはめる
            if (this.$route.params.id) {
              if (this.$route.params.store_info) {
                // console.log('set params from previous page')
                this.setStoreInfoToFields(this.$route.params.store_info)
              } else {
                // console.log('set params from db')
                const tmp = this.storeList.find(x => x._id === this.$route.params.id)
                this.setStoreInfoToFields(tmp)
              }
            }
          }
          this.isProcessing = false
        }).catch(e => {
          console.log(e)
          if (e.response && e.response.status === 404) {
            this.storeList = []
            this.FailedGetStore = false
          } else {
            this.FailedGetStore = true
          }
          this.isProcessing = false
        })
      },
    },
  }
</script>
<style scoped>
.line-break {
  white-space:pre-wrap;
  word-wrap:break-word;
}
.v-progress-circular {
  margin: 1rem;
}
.right-input {
  padding-top: 0px;
}
.right-input >>> input {
  text-align: right!important;
}
.v-picker.v-card {
  margin: 0px;
}
.top-card {
  margin-top: 0px;
  width: fit-content;
}
.margin-additional {
  margin-top: 28px;
}
.zero-padding {
  padding: 0px;
}
.theme--light .v-input--checkbox >>> label {
  color: black;
}
.theme--light .v-input--is-disabled >>> label {
  color: lightgray!important;
}
.theme--dark .v-input--checkbox >>> label {
  color: white;
}
</style>
