/** ランク・ステータスリスト- 定数 */
export const STATUS_RANK_LIST = [
  { name: '', _id: '' },
  { name: 'Silver', _id: 0 },
  { name: 'Gold', _id: 1 },
  { name: 'Platinum', _id: 2 },
]
/** 最終利用からの経過日数リスト- 定数 */
export const FINAL_USE_LENGTH_TIME_LIST = [
  { name: '', _id: '' },
  { name: '30日以内', _id: '30' },
  { name: '60日以内', _id: '60' },
  { name: '90日以内', _id: '90' },
  { name: '120日以内', _id: '120' },
  { name: '180日以内', _id: '180' },
  { name: '365日以内', _id: '365' },
  { name: '365日超', _id: 'over365' },
]
/** 会員登録状態- 定数 */
export const CUSTOMER_REGISTRATION_STATUS_LIST = [
  { name: '', _id: '' },
  { name: '登録済み', _id: 'registered' },
  { name: '未登録', _id: 'unregistered' },
]
