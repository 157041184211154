<template>
  <v-app>
    <main-app-bar />
    <main-drawer />

    <v-content>
      <router-view class="wrapper" />
      <common-footer />
    </v-content>

    <!-- <settings /> -->
  </v-app>
</template>

<script>
  import MainAppBar from './MainAppBar.vue'
  import MainDrawer from './MainDrawer.vue'
  import CommonFooter from './CommonFooter.vue'

  export default {
    name: 'MainLayout',
    components: {
      MainAppBar,
      MainDrawer,
      CommonFooter,
    },
  }
</script>

<style scoped>
.wrapper{
  min-height: 85vh;
  position: relative;/*←相対位置*/
  padding-bottom: 80px;/*←footerの高さ*/
  box-sizing: border-box;/*←全て含めてmin-height:100vhに*/
}
</style>
