<template>
  <v-container>
    <v-container
      v-if="isProcessing"
      id="grid"
      fluid
      tag="section"
    >
      <v-row justify="center">
        <v-progress-circular
          indeterminate
          color="primary"
          :size="100"
        />
      </v-row>
    </v-container>

    <v-container
      v-else
      id="grid"
      fluid
      tag="section"
      style="padding-top: 0px"
    >
      <v-row
        v-if="isSuccess"
        justify="center"
      >
        <material-alert
          color="success"
          dark
        >
          <span
            class="text-uppercase"
          /> {{ $t('employee_entry.success_alert') }}
        </material-alert>
      </v-row>

      <material-alert
        v-if="updateSuccess"
        color="success"
        dark
        dismissible
        icon="mdi-bell"
      >
        <span
          class="text-uppercase"
        /> {{ $t('employee_entry.update_success_alert') }}
      </material-alert>

      <material-alert
        v-for="error in errors"
        :key="error"
        color="error"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ error }}
      </material-alert>

      <material-alert
        v-if="FailedGetStore"
        color="error"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ $t('entry.errors.store_api') }}
      </material-alert>

      <material-alert
        v-if="FailedGetEmployee"
        color="error"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ $t('employee_entry.errors.employee_profile_api') }}
      </material-alert>

      <div
        v-if="!isProcessing && !FailedGetStore && !FailedGetEmployee"
      >
        <h2>{{ $t("employee_entry.title_auth") }}</h2>
        <v-form
          ref="authForms"
          v-model="isFormValidAuth"
        >
          <v-row>
            <v-text-field
              v-if="!is_update"
              v-model="employee_id"
              type="text"
              :label="$t('employee_entry.placeholder.employee_id')"
              :rules="employeeIdRule"
              prepend-icon="mdi-account"
              required
            />
            <v-text-field
              v-else
              v-model="employee_id"
              type="text"
              :label="$t('employee_entry.placeholder.employee_id_readonly')"
              prepend-icon="mdi-account"
              readonly
              required
            />
          </v-row>
          <v-row v-if="!is_update">
            <v-text-field
              v-model="password"
              :append-icon="isPasswordShown ? 'mdi-eye' : 'mdi-eye-off'"
              :type="isPasswordShown ? 'text' : 'password'"
              :label="$t('employee_entry.placeholder.password')"
              :rules="passwordRule"
              prepend-icon="mdi-lock"
              required
              @click:append="isPasswordShown = !isPasswordShown"
            />
          </v-row>
          <v-row v-if="!is_update">
            <v-text-field
              v-model="password_again"
              :append-icon="isPasswordAgainShown ? 'mdi-eye' : 'mdi-eye-off'"
              :type="isPasswordAgainShown ? 'text' : 'password'"
              :label="$t('employee_entry.placeholder.password_again')"
              :rules="passwordAgainRule"
              prepend-icon="mdi-lock"
              required
              @click:append="isPasswordAgainShown = !isPasswordAgainShown"
            />
          </v-row>
        </v-form>
        <h2 style="margin-top: 30px">
          {{ $t("employee_entry.title_profile") }}
        </h2>
        <v-form
          ref="profileForms"
          v-model="isFormValidProfile"
        >
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="last_name"
                type="text"
                :label="$t('employee_entry.placeholder.last_name')"
                :rules="[requireRule]"
                required
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="first_name"
                type="text"
                :label="$t('employee_entry.placeholder.first_name')"
                :rules="[requireRule]"
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="nickname"
                type="text"
                :label="$t('employee_entry.placeholder.nickname')"
                :rules="[requireRule, duplicateNicknameRule(nicknameList)]"
                required
              />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              sm="1"
            >
              {{ $t("employee_entry.placeholder.sex.title") }}
            </v-col>
            <v-col
              cols="12"
              sm="11"
            >
              <v-radio-group
                v-model="sex"
                row
                :rules="[requireRule]"
              >
                <v-radio
                  :label="$t('employee_entry.placeholder.sex.male')"
                  :value="'male'"
                />
                <v-radio
                  :label="$t('employee_entry.placeholder.sex.female')"
                  :value="'female'"
                />
                <v-radio
                  :label="$t('employee_entry.placeholder.sex.other')"
                  :value="'other'"
                />
              </v-radio-group>
            </v-col>
          </v-row>
          <!--エリア指定プルダウンメニュー (エリアの取得処理は未実装)
          <v-row>
            <v-col>
              <v-select
                v-model="selectedArea"
                :items="areaList"
                item-text="name"
                item-value="_id"
                :label="$t('employee_entry.area_selector_label')"
                prepend-icon="mdi-map-marker-radius"
              />
            </v-col>
          </v-row>
          -->
          <!-- 権限のラジオボタン -->
          <v-row>
            <v-col
              cols="12"
              sm="1"
            >
              <h3>{{ $t("employee_entry.placeholder.authority.title") }} </h3>
            </v-col>
            <v-col
              cols="12"
              sm="11"
            >
              <v-radio-group
                v-model="authority"
                row
              >
                <v-radio
                  :label="$t('employee_entry.placeholder.authority.headquarters')"
                  :value="'manager'"
                  :disabled="!$can('component.selectable_head')"
                />
                <!-- <v-radio
                  :label="$t('employee_entry.placeholder.authority.area')"
                  :value="'area'"
                /> -->
                <v-radio
                  :label="$t('employee_entry.placeholder.authority.general')"
                  :value="'staff'"
                  :disabled="!$can('component.selectable_staff')"
                />
              </v-radio-group>
            </v-col>
          </v-row>
          <!-- 拡張プロフィール入力欄
          <v-row>
            <v-col
              cols="2"
            >
              {{ $t('employee_entry.placeholder.extra_attribute') }}
            </v-col>
            <v-col
              cols="10"
            >
              <v-row
                v-for="(value, key) in extra_attribute"
                :key="key"
              >
                <v-text-field
                  v-model="extra_attribute[key]"
                  type="text"
                  :rules="[requireRule]"
                  :label="key"
                  required
                />
              </v-row>
            </v-col>
          </v-row>
          -->
        </v-form>
        <v-row
          justify="end"
          style="margin-top: 16px"
        >
          <v-btn
            v-if="canDelete && errors.length === 0"
            color="red"
            @click="openDeleteDialog()"
          >
            {{ $t("customer_detail.delete_button") }}
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="is_update"
            color="blue-grey lighten-2"
            @click="$router.go(-1)"
          >
            {{ $t('employee_entry.return_button') }}
          </v-btn>
          <v-btn
            v-if="!is_update"
            :disabled="!isFormValidAuth || !isFormValidProfile"
            color="primary"
            @click="entry"
          >
            {{ $t("employee_entry.button") }}
          </v-btn>
          <v-btn
            v-else
            :disabled="!isFormValidAuth || !isFormValidProfile"
            color="primary"
            @click="update"
          >
            {{ $t("employee_entry.update_button") }}
          </v-btn>
        </v-row>
      </div>
    </v-container>

    <!-- 削除ダイアログ -->
    <v-dialog
      v-model="showDeleteDialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t("employee_entry.delete.dialog.title") }}</span>
        </v-card-title>

        <v-card-text>
          <material-alert
            v-for="error in deleteErrors"
            :key="error"
            color="error"
            dark
            dismissible
            icon="mdi-bell"
          >
            {{ error }}
          </material-alert>
        </v-card-text>

        <v-card-text class="line-break">
          {{ $t("employee_entry.delete.dialog.description") }}

          <v-form v-model="employeeNameIsValid">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="delete_full_name"
                  type="text"
                  :label="delete_form_placeholder"
                  :rules="[requireRule, employeeNameRule]"
                  required
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey lighten-2"
            @click="showDeleteDialog = false"
          >
            {{ $t("employee_entry.delete.dialog.cancel") }}
          </v-btn>
          <v-btn
            color="red"
            :disabled="!employeeNameIsValid"
            :loading="isDeleting"
            @click="deleteEmployee()"
          >
            {{ $t("employee_entry.delete.dialog.button") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import * as api from '../api.js'
  import MaterialAlert from '../components/atoms/MaterialAlert.vue'

  export default {
    name: 'StaffEntry',
    components: {
      MaterialAlert,
    },
    data () {
      return {
        errors: [],
        isSuccess: false,
        isProcessing: true,
        storeNameList: [], // storeListから店舗名のみ抽出
        employeeList: [],
        employeeIdList: [], // employeeListから従業員IDのみ抽出
        nicknameList: [], // employeeListからニックネームのみ抽出
        locale: 'en',
        selectedArea: 'xxx', // 現時点では設定不可なため仮の値を設定
        isFormValidAuth: false,
        isFormValidProfile: false,
        extra_attribute_valid: false,
        shift_category_valid: false,
        FailedGetEmployee: false,
        FailedGetStore: false,
        employee_id: '',
        password: '',
        isPasswordShown: false,
        password_again: '',
        isPasswordAgainShown: false,
        first_name: '',
        last_name: '',
        nickname: '',
        sex: '',
        authority: 'staff', // 現時点では設定不可なため仮の値を設定
        extra_attribute: { employee_comment: '' },
        store_name: '',
        is_update: false,
        isHeadOnly: false,
        passwordRule: [
          v => !!v || this.$t('employee_entry.errors.required'),
          v => this.validateLength(v) || this.$t('employee_entry.errors.too_short_password'),
          v => this.validatePassword(v) || this.$t('employee_entry.errors.invalid_password'),
        ],
        passwordAgainRule: [
          v => !!v || this.$t('employee_entry.errors.required'),
          v => this.validateLength(v) || this.$t('employee_entry.errors.too_short_password'),
          v => this.validatePassword(v) || this.$t('employee_entry.errors.invalid_password'),
          v => this.isSamePassword(v) || this.$t('employee_entry.errors.different_password'),
        ],
        employeeIdRule: [
          v => !!v || this.$t('employee_entry.errors.required'),
          v => this.validateEmployeeId(v) || this.$t('employee_entry.errors.duplicate_employee_id'),
        ],
        requireRule: v => !!v || this.$t('employee_entry.errors.required'),
        duplicateNicknameRule: function (nicknameList) {
          return v => nicknameList.filter(x => x === v).length === 0 || this.$t('employee_entry.errors.duplicate_nickname')
        },
        updateSuccess: false,
        // 削除関連
        showDeleteDialog: false,
        delete_full_name: '',
        isDeleting: false,
        employeeNameIsValid: false,
        employeeNameRule: v => v === this.last_name + ' ' + this.first_name || this.$t('employee_entry.delete.dialog.name_not_match'),
        canDelete: false,
        deleteErrors: [],
      }
    },
    computed: {
      delete_form_placeholder () {
        return this.last_name + ' ' + this.first_name
      },
    },
    watch: {
    },
    beforeCreate () {
      if (!this.$can('component.employee_update')) {
        const selectedEmployeeId = this.$route.params.id
        if (!(selectedEmployeeId === localStorage.getItem('userId') && this.$can('component.employee_update_self'))) {
          const p = this.$route.path
          this.$router.replace({
            // 404ページに飛べればどこでも良い
            path: '/404',
          }).then(res => {
            // 404ページに移動した後にパスを書き換えて、移動していないかのように見せる
            window.history.replaceState(null, null, '#' + p)
          })
        }
      }
    },
    beforeUpdate () {
      if (!this.$can('component.employee_update')) {
        const selectedEmployeeId = this.$route.params.id
        if (!(selectedEmployeeId === localStorage.getItem('userId') && this.$can('component.employee_update_self'))) {
          const p = this.$route.path
          this.$router.replace({
            // 404ページに飛べればどこでも良い
            path: '/404',
          }).then(res => {
            // 404ページに移動した後にパスを書き換えて、移動していないかのように見せる
            window.history.replaceState(null, null, '#' + p)
          })
        }
      }
    },
    mounted () {
      this.locale = process.env.VUE_APP_I18N_LOCALE
      moment.locale(this.locale)

      if (this.$can('component.selectable_head')) {
        this.isHeadOnly = true
      }

      // 削除可能かの設定
      if (this.$can('component.delete_employee')) {
        if ((this.$route.params.id && this.$route.params.id !== localStorage.getItem('userId'))) {
          this.canDelete = true
        }
      }

      // 更新と新規登録で同じコンポーネントを使用するため、この二者の行き来では再描画されない。
      // データの初期化を行うため、遷移時に処理を明示的に呼び出す。
      this.$router.beforeEach((to, from, next) => {
        if ((from.name === 'Employee Update' && to.name === 'Employee Entry') ||
          (from.name === 'Employee Entry' && to.name === 'Employee Update') ||
          (from.name === 'Employee Update' && to.name === 'Employee Update')) {
          // this.$options.data()ではダメ。data()で設定される値以外の情報は維持したいので、
          // 元からあるコンテキストthisを引き継ぐようにする。
          Object.assign(this.$data, this.$options.data.call(this))
          this.getEmployeeData()
        }
        next()
      })

      this.getEmployeeData()
    },
    methods: {
      getEmployeeData () {
        api.getAllEmployees().then(res => {
          if (res.data.length === 0) {
            this.employeeList = []
            this.employeeIdList = []
            this.nicknameList = []
            this.extra_attribute = { employee_comment: '' }
            this.FailedGetEmployee = false
          } else {
            // スタッフリストの取得、ソート
            this.employeeList = res.data
            this.employeeList.sort(function (a, b) {
              if (a.employee_id < b.employee_id) {
                return -1
              }
              if (a.employee_id > b.employee_id) {
                return 1
              }
              return 0
            })
            // スタッフID/ニックネームの抽出
            this.employeeIdList = this.employeeList.map(x => x.employee_id)
            this.nicknameList = this.employeeList.map(x => x.nickname)
            // 拡張プロフィールの要素名抽出
            const extraAttributeKeys = Object.keys(this.employeeList[0].extra_attribute)
            // 拡張プロフィールのObjectを初期化
            for (const k of extraAttributeKeys) {
              this.extra_attribute[k] = ''
            }
            if (extraAttributeKeys.length === 0) {
              this.extra_attribute = { employee_comment: '' }
            }
            this.FailedGetEmployee = false

            // スタッフ一覧から飛んできた場合入力フォームに既存の値をセット
            if (this.$route.params.id) {
              this.setEmployeeInfoToFields(this.$route.params.id)
              this.is_update = true
            }
          }
        }).catch(e => {
          console.log(e)
          this.FailedGetEmployee = true
        }).finally(() => {
          this.isProcessing = false
        })
      },
      enterEmployeeInfo () {
        this.isSuccess = false
        this.errors = []
        try {
          const employeeInfoToSend = {
            employee_id: this.employee_id,
            role: this.authority,
            first_name: this.first_name,
            last_name: this.last_name,
            sex: this.sex,
            nickname: this.nickname,
            extra_attribute: this.extra_attribute,
            password: this.password,
          }
          api.enterEmployeeInfo(employeeInfoToSend).then(res => {
            this.isSuccess = true
            // フォームをリセットする
            this.employee_id = ''
            this.authority = 'staff'
            this.first_name = ''
            this.last_name = ''
            this.sex = ''
            this.nickname = ''
            this.extra_attribute = { employee_comment: '' }
            this.password = ''
            this.password_again = ''
          })
            .catch(e => {
              console.log(e)
              if (e.code === 'ECONNABORTED') {
                this.errors.push(this.$t('errors.api_timeout'))
              } else if (e.response && e.response.status === 409) {
                this.errors.push(this.$t('employee_entry.errors.duplicate_employee_id'))
              } else {
                this.errors.push(this.$t('errors.unexpected'))
              }
            })
            .finally(() => {
              // then()の中に記述するとauthForms/profileFormsがundefineになるのでfinally()に記述
              this.isProcessing = false
              this.$nextTick(() => {
                this.$refs.authForms.resetValidation()
                this.$refs.profileForms.resetValidation()
              })
            })
        } catch (e) {
          console.log(e)
          this.errors.push(this.$t('errors.unexpected'))
          this.isProcessing = false
        }
      },
      entry () {
        this.isProcessing = true
        try {
          this.enterEmployeeInfo()
        } catch (e) {
          console.log(e)
        } finally {
          scrollTo(0, 0)
        }
      },
      setEmployeeInfoToFields (employeeId) {
        api.getEmployee(employeeId).then(res => {
          if (res.data.length === 1) {
            const employee = res.data[0]
            this.employee_id = employee.employee_id
            this.authority = employee.role
            this.first_name = employee.first_name
            this.last_name = employee.last_name
            this.sex = employee.sex
            this.nickname = employee.nickname
            // 拡張プロフィールの要素名抽出
            const extraAttributeKeys = Object.keys(employee.extra_attribute)
            // 拡張プロフィールのObjectを初期化
            for (const k of extraAttributeKeys) {
              this.extra_attribute[k] = employee.extra_attribute[k]
            }
            this.duplicateNicknameRule = function (nicknameList) {
              return v => (v === employee.nickname || nicknameList.filter(x => x === v).length === 0) || this.$t('employee_entry.errors.duplicate_nickname')
            }
          }
        }).catch(e => {
          if (e.code === 'ECONNABORTED') {
            this.errors.push(this.$t('errors.api_timeout'))
          } else if (e.response && e.response.status === 404) {
            this.errors.push(this.$t('employee_entry.errors.employee_profile_api'))
          } else {
            this.errors.push(this.$t('errors.unexpected'))
          }
        }).finally(() => {
          // then()の中に記述するとauthForms/profileFormsがundefineになるのでfinally()に記述
          this.$refs.authForms.resetValidation()
          this.$refs.profileForms.resetValidation()
        })
      },
      updateEmployeeInfo () {
        this.updateSuccess = false
        try {
          const requestBody = {
            employee_id: this.employee_id,
            role: this.authority,
            first_name: this.first_name,
            last_name: this.last_name,
            sex: this.sex,
            nickname: this.nickname,
            extra_attribute: this.extra_attribute,
          }
          api.updateEmployeeInfo(requestBody).then(res => {
            if (this.$can('route.employee_browsing')) {
              this.$router.push({
                name: 'Employee Browsing',
              })
            }
            this.updateSuccess = true
          }).catch(e => {
            console.log(e)
            if (e.code === 'ECONNABORTED') {
              this.errors.push(this.$t('errors.api_timeout'))
            } else if (e.response && e.response.status === 409) {
              if (e.response.data.error_code === '1003') {
                this.errors.push(this.$t('employee_entry.errors.duplicate_employee_id'))
              } else if (e.response.data.error_code === '1004') {
                this.errors.push(this.$t('employee_entry.errors.some_reservations_exist'))
              } else if (e.response.data.error_code === '1005') {
                this.errors.push(this.$t('employee_entry.errors.some_shifts_exist'))
              } else {
                this.errors.push(this.$t('errors.unexpected'))
              }
            } else {
              this.errors.push(this.$t('errors.unexpected'))
            }
          }).finally(() => {
            this.isProcessing = false
          })
        } catch (e) {
          console.log(e)
          this.errors.push(this.$t('errors.unexpected'))
          this.isProcessing = false
        }
      },
      update () {
        this.isProcessing = true
        try {
          this.updateEmployeeInfo()
        } catch (e) {
          console.log(e)
        } finally {
          scrollTo(0, 0)
        }
      },
      validateLength (target) {
        return target.length >= 8
      },
      validatePassword (target) {
        // 小文字、大文字、数字が含まれるか判定する正規表現を定義
        const ratz = /[a-z]/
        const rAtZ = /[A-Z]/
        const r0t9 = /[0-9]/
        return ratz.test(target) && rAtZ.test(target) && r0t9.test(target)
      },
      isSamePassword (target) {
        return target === this.password
      },
      validateEmployeeId: function (v) {
        return this.employeeIdList.filter(x => x === v).length === 0
      },
      openDeleteDialog () {
        this.deleteErrors = []
        this.showDeleteDialog = true
      },
      deleteEmployee () {
        this.deleteErrors = []
        this.isDeleting = true
        api.deleteEmployee(this.employee_id).then(res => {
          this.$router.push({
            name: 'Employee Browsing',
          })
        }).catch(e => {
          console.log(e)
          if (e.code === 'ECONNABORTED') {
            this.deleteErrors.push(this.$t('errors.api_timeout'))
          } else if (e.response && e.response.status === 404) {
            this.deleteErrors.push(this.$t('employee_entry.delete.errors.not_found'))
          } else if (e.response && e.response.status === 409) {
            this.deleteErrors.push(this.$t('employee_entry.delete.errors.conflict'))
          } else {
            this.deleteErrors.push(this.$t('errors.unexpected'))
          }
          this.isDeleting = false
        })
      },
    },
  }
</script>
<style scoped>
.line-break {
  white-space:pre-wrap;
  word-wrap:break-word;
}
.v-progress-circular {
  margin: 1rem;
}
.right-input {
  padding-top: 0px;
}
.right-input >>> input {
  text-align: right!important;
}
.v-picker.v-card {
  margin: 0px;
}
.top-card {
  margin-top: 0px;
  width: fit-content;
}
.margin-additional {
  margin-top: 28px;
}
</style>
