<template>
  <v-container
    v-if="!inited"
    id="grid"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-progress-circular
        indeterminate
        color="primary"
        :size="100"
      />
    </v-row>
  </v-container>
  <v-container
    v-else
    id="grid"
    fluid
    tag="section"
  >
    <base-material-alert
      v-for="error in errors"
      :key="error"
      color="error"
      dark
      dismissible
      icon="mdi-bell"
    >
      {{ error }}
    </base-material-alert>
    <h2>{{ $t("dashboard_browsing.title_monthly_total") }} ({{ now_year }}/{{ now_month }})</h2>
    <v-row>
      <v-col
        cols="12"
        md="4"
        lg="4"
        xl="2"
      >
        <v-card
          class="mt-0 mb-0"
          min-width="200"
        >
          <v-card-text>
            <div class="text-h4 color-white">
              {{ $t('dashboard_browsing.title_friend_count') }}
            </div>
            <div class="text-h1 text-right color-white">
              {{ monthly_friend_count }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
        lg="4"
        xl="2"
      >
        <v-card
          class="mt-0 mb-0"
          min-width="200"
        >
          <v-card-text>
            <div class="text-h4 color-white">
              {{ $t('dashboard_browsing.title_personal_information_count') }}
            </div>
            <div class="text-h1 text-right color-white">
              {{ monthly_personal_information_count }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-divider />
    <br>
    <h2>{{ $t("dashboard_browsing.title_all_total") }}</h2>
    <v-row>
      <v-col
        cols="12"
        md="4"
        lg="4"
        xl="2"
      >
        <v-card
          class="mt-0 mb-0"
          min-width="200"
        >
          <v-card-text>
            <div class="text-h4 color-white">
              {{ $t('dashboard_browsing.title_friend_count') }}
            </div>
            <div class="text-h1 text-right color-white">
              {{ all_friend_count }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
        lg="4"
        xl="2"
      >
        <v-card
          class="mt-0 mb-0"
          min-width="200"
        >
          <v-card-text>
            <div class="text-h4 color-white">
              {{ $t('dashboard_browsing.title_personal_information_count') }}
            </div>
            <div class="text-h1 text-right color-white">
              {{ all_personal_information_count }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-divider />
    <br>
    <h2>{{ $t("dashboard_browsing.title_time_series") }}</h2>
    <v-row>
      <v-col
        cols="12"
        md="4"
        lg="4"
        xl="2"
      >
        <v-select
          v-model="pickedDate"
          :items="yearList"
          :label="$t('dashboard_browsing.date_picker_label')"
          prepend-icon="mdi-calendar"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="6"
        xl="4"
      >
        <chart
          v-if="loaded"
          :chart-data="series_friend_count_data"
          :options="series_friend_count_options"
          :height="280"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import * as api from '../api.js'
  import Chart from '../components/atoms/Chart.vue'

  // 開始年
  const START_YEAR = 2020

  export default {
    name: 'DashboardBrowsing',
    components: {
      Chart,
    },
    data: () => ({
      inited: false,
      selected: null,
      errors: [],
      isProcessing: false,
      isHeadOnly: false,
      loaded: false,
      selectedStore: '',
      dashboards: [],
      yearList: [],
      pickedDate: null,
      formattedPickedDate: '',
      locale: 'en',
      // 今月の数値
      monthly_friend_count: '',
      monthly_personal_information_count: '',
      // 過去からの累計値
      all_friend_count: '',
      all_personal_information_count: '',
      // 時系列変化
      series_friend_count: { },
      series_friend_count_data: {},
      series_friend_count_options: {},
      datacollection: {},
      options: {
        responsive: false,
      },
      now_month: '',
      now_year: '',
    }),
    computed: {
      year: function () {
        return moment(this.pickedDate).format('YYYY')
      },
    },
    watch: {
      pickedDate: function (n, o) {
        this.formattedPickedDate = this.pickedDate
        // 初期表示ではmountedで更新する
        if (o !== null) {
          this.selecetedParamsCheckAndUpdate()
        }
      },
    },
    mounted () {
      this.locale = process.env.VUE_APP_I18N_LOCALE
      moment.locale(this.locale)

      // 追加
      if (this.$can('component.selectable_head')) {
        this.isHeadOnly = true
      }

      // システム日付の年と月を取得
      const today = new Date()
      this.now_year = today.getFullYear()
      this.now_month = today.getMonth() + 1

      // 年リストを取得
      let y = START_YEAR
      while (y < this.now_year + 1) {
        this.yearList.push(String(y))
        y++
      }
      const now = moment()
      this.pickedDate = now.format('YYYY')
      this.getAggregatedData()
      this.inited = true
    },
    methods: {
      selecetedParamsCheckAndUpdate () {
        if (this.pickedDate.search(/^\d{4}$/g) === 0 && this.selectedStore !== null) {
          this.getAggregatedData()
        } else {
          console.log('invalid date format')
        }
      },
      // 集計値の取得
      getAggregatedData () {
        api.getDashboard(this.selectedStore, this.year, '1').then(res => {
          const dashboards = res.data
          // 今月の数値
          this.monthly_friend_count = String(dashboards[0].friend_count.monthly_count[0])
          this.monthly_personal_information_count = String(dashboards[0].personal_information_count.monthly_count[0])
          // 過去からの累計値
          this.all_friend_count = String(dashboards[0].friend_count.all_count[0])
          this.all_personal_information_count = String(dashboards[0].personal_information_count.all_count[0])
          // 時系列変化
          const labels = []
          // ラベルを作成（1月から12月）
          for (let i = 1; i < 13; i++) {
            labels.push(String(i) + this.$t('dashboard_browsing.labels'))
          }
          // 友達登録数/個人情報登録数
          const seriesList1 = dashboards[0].friend_count.series
          const seriesList2 = dashboards[0].personal_information_count.series
          const label1 = this.$t('dashboard_browsing.title_friend_count')
          const label2 = this.$t('dashboard_browsing.title_personal_information_count')
          this.series_friend_count = createLineChartJSObject(seriesList1, seriesList2, labels, label1, label2, 100, '#666600')
          this.series_friend_count_data = this.series_friend_count.data
          this.series_friend_count_options = this.series_friend_count.options
          this.isProcessing = false
          this.loaded = true
        }).catch(e => {
          if (e.response !== undefined && e.response.status === 404) {
            this.errors.push(this.$t('dashboard_browsing.errors.not_found'))
          } else if (e.code === 'ECONNABORTED') {
            this.errors.push(this.$t('errors.api_timeout'))
          } else {
            this.errors.push(this.$t('errors.unexpected'))
          }
          this.isProcessing = false
        })
      },
    },
  }
  // グラフ用のオブジェクトを返却する
  function createLineChartJSObject (seriesList1, seriesList2, labels, title1, title2, maxvalue, color2) {
    const lineChartJSObject = {
      data: {
        labels: [],
        datasets: [
          {
            label: '',
            data: ['', '', '', '', '', '', '', '', '', '', '', ''],
            fill: false,
            animation: true,
            borderColor: '#073088',
            borderWidth: 2,
            lineTension: 0.3,
            pointRadius: 2,
          },
          {
            label: '',
            data: ['', '', '', '', '', '', '', '', '', '', '', ''],
            fill: false,
            animation: true,
            borderColor: '',
            borderWidth: 2,
            lineTension: 0.3,
            pointRadius: 2,
          },
        ],
      },
      options: {
        scales: {
          responsive: true,
          yAxes: [{
            ticks: {
              min: 0,
              suggestedMax: 0,
            },
          }],
        },
      },
    }
    lineChartJSObject.data.datasets[0].data = seriesList1
    lineChartJSObject.data.datasets[1].data = seriesList2
    lineChartJSObject.data.labels = labels
    lineChartJSObject.data.datasets[0].label = title1
    lineChartJSObject.data.datasets[1].label = title2
    lineChartJSObject.data.datasets[1].borderColor = color2
    lineChartJSObject.options.scales.yAxes[0].ticks.suggestedMax = maxvalue
    return lineChartJSObject
  }
</script>
<style scoped>
.line-break {
  white-space:pre-wrap;
  word-wrap:break-word;
}
.v-progress-circular {
  margin: 1rem;
}
.v-card {
  background-color:#000087;
}
.color-white {
  color:white;
}
</style>
