<template>
  <v-container>
    <v-container
      v-show="isStoreProcessing || isEmployeeProcessing || isCustomerProcessing || isHistoryProcessing"
      fluid
      tag="section"
    >
      <v-row justify="center">
        <v-progress-circular
          indeterminate
          color="primary"
          :size="100"
        />
      </v-row>
    </v-container>

    <v-container
      v-if="FailedGetCustomer && isCustomerNotFound"
      fluid
    >
      <material-alert
        color="error"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ $t('customer_reservation_history.errors.customer_not_found') }}
      </material-alert>
    </v-container>

    <v-container
      v-if="(FailedGetStore || FailedGetEmployee || FailedGetHistory || FailedGetCustomer) && isApiTimeout"
      fluid
    >
      <material-alert
        color="error"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ $t('errors.api_timeout') }}
      </material-alert>
    </v-container>

    <v-container
      v-if="(FailedGetStore || FailedGetEmployee || FailedGetHistory || FailedGetCustomer) && !isApiTimeout && !isCustomerNotFound"
      fluid
    >
      <material-alert
        color="error"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ $t('errors.unexpected') }}
      </material-alert>
    </v-container>

    <v-container
      id="grid"
      :class="{ hide: isStoreProcessing || isEmployeeProcessing || isCustomerProcessing || isHistoryProcessing || FailedGetStore || FailedGetEmployee || FailedGetHistory || FailedGetCustomer }"
      fluid
      tag="section"
    >
      <material-alert
        v-for="error in errors"
        :key="error"
        color="error"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ error }}
      </material-alert>
      <material-alert
        v-if="isUpdateSuccess"
        color="success"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ $t('customer_reservation_browsing.update_success') }}
      </material-alert>
      <material-alert
        v-if="isDeleteSuccess"
        color="success"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ $t('customer_reservation_browsing.delete_success') }}
      </material-alert>
      <v-card style="padding-top: 10px; padding-bottom: 10px">
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <v-toolbar-title>
            {{ customerName + ' ' + $t('customer_reservation_history.honorific_title') }}
          </v-toolbar-title>

          <template v-slot:extension>
            <v-tabs
              v-model="tab"
              align-with-title
            >
              <v-tabs-slider color="secondary" />

              <v-tab
                v-for="(item, index) in tabList"
                :key="index"
              >
                {{ item }}
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <v-tabs-items v-model="tab">
          <material-alert
            v-if="isZeroReservations[tab].zeroFlag"
            color="warning"
            dark
            dismissible
            icon="mdi-bell"
          >
            {{ isZeroReservations[tab].msg }}
          </material-alert>
          <v-tab-item
            v-for="(reservations, index) in displayList"
            :key="index"
          >
            <v-card
              v-for="res in reservations"
              :key="res._id"
              :class="{ hide: FailedGetHistory }"
            >
              <v-row
                align="center"
                justify="center"
              >
                <v-col
                  cols="12"
                  sm="10"
                >
                  <v-text-field
                    v-model="res._id"
                    :label="$t('customer_reservation_history.reservation_id')"
                    type="text"
                    disabled
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                >
                  <v-btn
                    color="blue-grey darken-1"
                    small
                    :disabled="showContent"
                    @click="showDialog(res)"
                  >
                    {{ $t('customer_reservation_history.detail_button') }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col
                  cols="12"
                  sm="2"
                >
                  {{ $t("customer_reservation_history.reservation_type.title") }}
                </v-col>
                <v-col
                  cols="12"
                  sm="10"
                >
                  <v-radio-group
                    v-model="res.reservation_type"
                    row
                  >
                    <v-radio
                      :label="$t('customer_reservation_history.reservation_type.store')"
                      :value="'store'"
                      readonly
                    />
                    <v-radio
                      :label="$t('customer_reservation_history.reservation_type.web')"
                      :value="'web'"
                      readonly
                    />
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="res.reservation_date"
                    :label="$t('customer_reservation_history.reservation_date')"
                    disabled
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-text-field
                    v-model="res.start_time"
                    :label="$t('customer_reservation_history.start_time')"
                    disabled
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-text-field
                    v-model="res.end_time"
                    :label="$t('customer_reservation_history.end_time')"
                    disabled
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="res.store_id"
                    :items="storeList"
                    item-text="name"
                    item-value="_id"
                    :label="$t('customer_reservation_history.store_name')"
                    type="text"
                    disabled
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="res.employee_id"
                    :items="employeeList"
                    item-text="fullname"
                    item-value="employee_id"
                    :label="$t('customer_reservation_history.employee_name')"
                    type="text"
                    disabled
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <a
                    :href="res.meetingUrl"
                    target="_blank"
                    rel="noopener"
                  >
                    <v-text-field
                      v-if="res.reservation_type === 'web'"
                      v-model="res.meetingUrl"
                      :label="$t('customer_reservation_history.video_meeting_url')"
                      type="text"
                      disabled
                    />
                  </a>
                </v-col>
              </v-row>
            </v-card>
            <v-pagination
              v-if="!isZeroReservations[tab].zeroFlag"
              v-model="pages[tab]"
              :length="pageLengths[tab]"
              @input="changeDisplayedReservation"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
      <v-row
        justify="end"
        style="margin-top: 16px"
      >
        <v-btn
          color="blue-grey lighten-2"
          to="/components/customer-search"
        >
          {{ $t('customer_reservation_history.return_button') }}
        </v-btn>
      </v-row>
    </v-container>

    <v-dialog
      v-model="showContent"
      persistent
      max-width="800px"
    >
      <v-card
        v-if="showContent"
      >
        <v-card-title>
          <span class="text-h5">{{ $t('customer_reservation_browsing.detail.title') }}</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="detailForm"
            v-model="isFormValid"
          >
            <v-container>
              <v-row
                v-if="!isReadOnly && displayDetail.isEditableReservation"
                style="font-size: 12px;"
              >
                {{ $t("customer_reservation_browsing.detail.notation") }}
              </v-row>
              <v-row justify="end">
                <v-btn
                  color="blue-grey lighten-2"
                  small
                  :disabled="isUpdating"
                  @click="closeDialog"
                >
                  {{ $t('customer_reservation_browsing.detail.button.cancel') }}
                </v-btn>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="displayDetail.reservation._id"
                    :label="$t('customer_reservation_browsing.detail.reservation_id')"
                    type="text"
                    disabled
                  />
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col
                  cols="12"
                  sm="2"
                >
                  {{ $t("customer_reservation_browsing.detail.reservation_type.title") }}
                </v-col>
                <v-col
                  cols="12"
                  sm="10"
                >
                  <v-radio-group
                    v-model="displayDetail.reservation.reservation_type"
                    row
                  >
                    <v-radio
                      :label="$t('customer_reservation_browsing.detail.reservation_type.store')"
                      :value="'store'"
                      disabled
                    />
                    <v-radio
                      :label="$t('customer_reservation_browsing.detail.reservation_type.web')"
                      :value="'web'"
                      disabled
                    />
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-menu
                    v-model="dialogDatePickerMenu"
                    offset-y
                    :close-on-content-click="false"
                    max-width="auto"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="displayDetail.formattedPickedDate"
                        :label="$t('customer_reservation_browsing.detail.date_picker_label')"
                        :rules="reservationDateRule"
                        :disabled="isReadOnly || isUpdating || !displayDetail.isEditableReservation"
                        readonly
                        prepend-icon="mdi-calendar"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="dialogPickedDate"
                      :locale="locale"
                      :disabled="isReadOnly || isUpdating || !displayDetail.isEditableReservation"
                      :day-format="date => new Date(date).getDate()"
                      @click="dialogDatePickerMenu = false"
                    />
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-text-field
                    v-model="displayDetail.startTime"
                    :label="$t('customer_reservation_browsing.detail.start_time')"
                    type="time"
                    :rules="startTimeRule"
                    :disabled="isReadOnly || isUpdating || !displayDetail.isEditableReservation"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-text-field
                    v-model="displayDetail.endTime"
                    :label="$t('customer_reservation_browsing.detail.end_time')"
                    type="time"
                    :rules="endTimeRule"
                    :disabled="isReadOnly || isUpdating || !displayDetail.isEditableReservation"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-model="displayDetail.employeeId"
                    :items="displayDetail.employeeList"
                    item-text="fullname"
                    item-value="employee_id"
                    :label="$t('customer_reservation_browsing.detail.employee')"
                    prepend-icon="mdi-map-marker-radius"
                    required
                    :disabled="isReadOnly || isUpdating || !displayDetail.isEditableReservation"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="displayDetail.storeName"
                    :label="$t('customer_reservation_browsing.detail.store')"
                    prepend-icon="mdi-store"
                    disabled
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <a
                    :href="displayDetail.meetingUrl"
                    target="_blank"
                    rel="noopener"
                  >
                    <v-text-field
                      v-if="displayDetail.reservation.reservation_type === 'web'"
                      v-model="displayDetail.meetingUrl"
                      :label="$t('customer_reservation_browsing.detail.meeting_url')"
                      prepend-icon="mdi-video"
                      disabled
                    />
                  </a>
                </v-col>
              </v-row>
              <v-row justify="start">
                <v-col
                  cols="12"
                  sm="2"
                >
                  {{ $t('customer_reservation_browsing.detail.customer.title') }}
                </v-col>
                <!--
                <v-col
                  cols="12"
                  sm="10"
                >
                  <v-btn
                    color="blue-grey lighten-1"
                    x-small
                    :disabled="isUpdating"
                    @click="showCustomerDetail(displayDetail.customer)"
                  >
                    {{ $t('customer_reservation_browsing.detail.customer.button.detail') }}
                  </v-btn>
                  <v-btn
                    color="blue-grey lighten-1"
                    x-small
                    :disabled="isUpdating"
                    @click="closeDialog"
                  >
                    {{ $t('customer_reservation_browsing.detail.customer.button.history') }}
                  </v-btn>
                </v-col>
                -->
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="displayDetail.customer.profile.lastname"
                    type="text"
                    prepend-icon="mdi-account"
                    :label="$t('customer_reservation_browsing.detail.customer.last_name')"
                    disabled
                    required
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="displayDetail.customer.profile.firstname"
                    type="text"
                    :label="$t('customer_reservation_browsing.detail.customer.first_name')"
                    disabled
                    required
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="displayDetail.customer.profile.lastname_kana"
                    prepend-icon="mdi-account"
                    type="text"
                    :label="$t('customer_reservation_browsing.detail.customer.last_name_kana')"
                    required
                    disabled
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="displayDetail.customer.profile.firstname_kana"
                    type="text"
                    :label="$t('customer_reservation_browsing.detail.customer.first_name_kana')"
                    required
                    disabled
                  />
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col
                  cols="12"
                  sm="2"
                >
                  {{ $t("customer_reservation_browsing.detail.customer.gender.title") }}
                </v-col>
                <v-col
                  cols="12"
                  sm="10"
                >
                  <v-radio-group
                    v-model="displayDetail.customer.profile.gender"
                    row
                  >
                    <v-radio
                      :label="$t('customer_reservation_browsing.detail.customer.gender.male')"
                      :value="'男性'"
                      disabled
                    />
                    <v-radio
                      :label="$t('customer_reservation_browsing.detail.customer.gender.female')"
                      :value="'女性'"
                      disabled
                    />
                    <v-radio
                      :label="$t('customer_reservation_browsing.detail.customer.gender.other')"
                      :value="'その他'"
                      disabled
                    />
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="2"
                >
                  {{ $t('customer_reservation_browsing.detail.extra_question') }}
                </v-col>
              </v-row>
              <v-row
                v-for="(value, key) in displayDetail.reservation.extra_question"
                :key="key"
                style="padding-left: 43px"
              >
                <v-text-field
                  v-model="displayDetail.reservation.extra_question[key]"
                  type="text"
                  :label="key"
                  disabled
                  required
                />
              </v-row>
            </v-container>
          </v-form>
          <material-alert
            v-for="error in errorsInUpdating"
            :key="error"
            color="error"
            dark
            dismissible
            icon="mdi-bell"
          >
            {{ error }}
          </material-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="isDeletable && displayDetail.isEditableReservation"
            color="red"
            :disabled="isUpdating"
            @click="openDeleteDialog"
          >
            {{ $t('customer_reservation_browsing.detail.button.delete') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="blue-grey lighten-2"
            :disabled="isUpdating"
            @click="closeDialog"
          >
            {{ $t('customer_reservation_browsing.detail.button.cancel') }}
          </v-btn>
          <v-btn
            v-if="!isReadOnly && displayDetail.isEditableReservation"
            color="primary"
            :loading="isUpdating"
            :disabled="isUpdating || !isFormValid"
            @click="updateAndCloseDialog"
          >
            {{ $t('customer_reservation_browsing.detail.button.update') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 削除確認ダイアログ -->
    <v-dialog
      v-model="showDeleteDialog"
      persistent
      max-width="800"
    >
      <v-card v-if="showDeleteDialog">
        <v-card-title>
          <span class="text-h5">{{ $t("customer_reservation_browsing.delete.title") }}</span>
        </v-card-title>

        <v-card-text class="line-break">
          <material-alert
            v-for="error in errorsInDeleting"
            :key="error"
            color="error"
            dark
            dismissible
            icon="mdi-bell"
          >
            {{ error }}
          </material-alert>
          {{ cautionMessage }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey lighten-2"
            :disabled="isDeleting"
            @click="closeDeleteDialog"
          >
            {{ $t('customer_reservation_browsing.delete.button.cancel') }}
          </v-btn>
          <v-btn
            color="red"
            :loading="isDeleting"
            :disabled="isDeleting"
            @click="deleteAndCloseDeleteDialog"
          >
            {{ $t('customer_reservation_browsing.delete.button.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import * as api from '../api.js'
  import MaterialAlert from '../components/atoms/MaterialAlert.vue'

  class DisplayDetail {
    constructor () {
      this.id = ''
      this.reservation = null
      this.customer = null
      this.formattedPickedDate = ''
      this.storeName = ''
      this.meetingUrl = ''
      this.startTime = ''
      this.endTime = ''
      this.employeeId = ''
      this.isEditableReservation = false
    }

    startDatetime () {
      return moment(this.formattedPickedDate + ' ' + this.startTime)
    }

    endDatetime () {
      return moment(this.formattedPickedDate + ' ' + this.endTime)
    }

    updateCalendarEvent (calendarEvent) {
      const color = this.employeeList.find(x => x.employee_id === this.reservation.employee_id).color
      calendarEvent.setStart(this.startDatetime().format('YYYY-MM-DD HH:mmZ'))
      calendarEvent.setEnd(this.endDatetime().format('YYYY-MM-DD HH:mmZ'))
      calendarEvent.setProp('color', color)
      calendarEvent.setExtendedProp('reservation', JSON.parse(JSON.stringify(this.reservation)))
    }

    clear () {
      this.id = ''
      this.reservation = null
      this.customer = null
      this.formattedPickedDate = ''
      this.storeName = ''
      this.meetingUrl = ''
    }

    toApi () {
      // 一度JSON文字列化してパースすることで参照渡しを防ぐ
      const params = JSON.parse(JSON.stringify(this.reservation))
      params.reservation_date = moment(this.formattedPickedDate).format('YYYY-MM-DD')
      params.start_time = this.startTime
      params.end_time = this.endTime
      params.employee_id = this.employeeId
      return params
    }

    static fromCalendarEvent (calendarEvent, storeName) {
      const ret = new DisplayDetail()
      ret.id = calendarEvent.id
      ret.customer = calendarEvent.extendedProps.customer
      ret.reservation = calendarEvent.extendedProps.reservation
      ret.startTime = calendarEvent.extendedProps.reservation.start_time
      ret.endTime = calendarEvent.extendedProps.reservation.end_time
      ret.employeeId = calendarEvent.extendedProps.reservation.employee_id
      ret.formattedPickedDate = moment(ret.reservation.reservation_date).format('YYYY/MM/DD')
      ret.storeName = storeName
      if (ret.reservation.reservation_type === 'web') {
        if (ret.reservation.online_support.toolname === 'Google Meet') {
          ret.meetingUrl = 'https://meet.google.com/' + ret.reservation.online_support.token
        } else {
          ret.meetingUrl = ret.reservation.online_support.admin_url
        }
      }
      ret.employeeList = calendarEvent.extendedProps.employeeList.map(item => {
        // 表示用のプロパティ追加
        item.fullname = item.last_name + ' ' + item.first_name
        return item
      })
      return ret
    }

    static fromReservationHistory (reservation, storeName, employeeList, customer) {
      const ret = new DisplayDetail()
      ret.reservation = reservation
      ret.customer = customer
      ret.startTime = reservation.start_time
      ret.endTime = reservation.end_time
      ret.employeeId = reservation.employee_id
      ret.formattedPickedDate = moment(ret.reservation.reservation_date).format('YYYY/MM/DD')
      ret.storeName = storeName
      if (ret.reservation.reservation_type === 'web') {
        if (ret.reservation.online_support.toolname === 'Google Meet') {
          ret.meetingUrl = 'https://meet.google.com/' + ret.reservation.online_support.token
        } else {
          ret.meetingUrl = ret.reservation.online_support.admin_url
        }
      }
      ret.isEditableReservation = moment(ret.reservation.reservation_date + ' ' + ret.reservation.start_time).isSameOrAfter(moment()) && ret.reservation.available
      if (ret.isEditableReservation) {
        // 予約が変更可能な場合、予約に紐付いた店舗に所属するスタッフ+未指定表示用のスタッフを抽出
        ret.employeeList = employeeList.filter(item => item.store_id === reservation.store_id || item.employee_id === '')
      } else {
        // 予約が変更不可な場合、予約に紐付いたスタッフのみを抽出 (削除/異動されたスタッフはダミーデータがヒットする)
        ret.employeeList = employeeList.filter(item => item.employee_id === reservation.employee_id)
      }
      return ret
    }
  }

  export default {
    name: 'CustomerReservationHistory',
    components: {
      MaterialAlert,
    },
    data () {
      return {
        tab: 0,
        pages: { 0: 1, 1: 1 },
        pageSize: 5,
        pageLengths: [],
        customer: null,
        customerId: null,
        customerName: '',
        tabList: [
          this.$t('customer_reservation_history.tabs.decided'),
          this.$t('customer_reservation_history.tabs.canceled'),
        ],
        reservationList: [],
        displayList: [],
        storeList: [],
        employeeList: [],
        isStoreProcessing: true,
        isEmployeeProcessing: true,
        isCustomerProcessing: true,
        isHistoryProcessing: true,
        isZeroReservations: [
          { zeroFlag: false, msg: this.$t('customer_reservation_history.errors.zero_decided_reservation') },
          { zeroFlag: false, msg: this.$t('customer_reservation_history.errors.zero_canceled_reservation') },
        ],
        FailedGetStore: false,
        FailedGetEmployee: false,
        FailedGetCustomer: false,
        FailedGetHistory: false,
        isApiTimeout: false,
        isCustomerNotFound: false,
        showContent: false,
        showDeleteDialog: false,
        selectedDate: null,
        isFormValid: false,
        displayDetail: new DisplayDetail(),
        dialogPickedDate: null,
        dialogDatePickerMenu: false,
        locale: 'en',
        isUpdating: false,
        isDeleting: false,
        errors: [],
        isUpdateSuccess: false,
        isDeleteSuccess: false,
        errorsInUpdating: [],
        errorsInDeleting: [],
        reservationDateRule: [
          v => !!v || this.$t('customer_reservation_browsing.errors.required'),
          v => this.validateReservationDate(v) || this.$t('customer_reservation_browsing.errors.past_date'),
        ],
        startTimeRule: [
          v => !!v || this.$t('customer_reservation_browsing.errors.required'),
          v => this.validateTime(v) || this.$t('customer_reservation_browsing.errors.past_time'),
          v => this.judgeTimeRel(v, true) || this.$t('customer_reservation_browsing.errors.late_start_time'),
          v => this.judgeTimeDiff(v, true) || this.$t('customer_reservation_browsing.errors.invalid_time_diff'),
          v => v.split(':')[1] === '00' || this.$t('customer_reservation_browsing.errors.minutes_not_allowed'),
        ],
        endTimeRule: [
          v => !!v || this.$t('customer_reservation_browsing.errors.required'),
          v => this.validateTime(v) || this.$t('customer_reservation_browsing.errors.past_time'),
          v => this.judgeTimeRel(v, false) || this.$t('customer_reservation_browsing.errors.early_end_time'),
          v => this.judgeTimeDiff(v, false) || this.$t('customer_reservation_browsing.errors.invalid_time_diff'),
          v => v.split(':')[1] === '00' || this.$t('customer_reservation_browsing.errors.minutes_not_allowed'),
        ],
        isReadOnly: false,
        isDeletable: false,
      }
    },
    watch: {
      dialogPickedDate: function (n, o) {
        this.displayDetail.formattedPickedDate = moment(this.dialogPickedDate).format('YYYY/MM/DD')
        if (this.$refs.detailForm !== undefined) {
          this.$refs.detailForm.validate()
        }
      },
      'displayDetail.startTime': function (n, o) {
        if (this.$refs.detailForm !== undefined) {
          this.displayDetail.endTime = moment(n, 'HH:mm').add(1, 'h').format('HH:mm')
          this.$refs.detailForm.validate()
        }
      },
      'displayDetail.endTime': function (n, o) {
        if (this.$refs.detailForm !== undefined) {
          this.displayDetail.startTime = moment(n, 'HH:mm').subtract(1, 'h').format('HH:mm')
          this.$refs.detailForm.validate()
        }
      },
    },
    mounted () {
      this.locale = process.env.VUE_APP_I18N_LOCALE
      moment.locale(this.locale)
      if (typeof this.$route.params.id !== 'undefined') {
        this.customerId = this.$route.params.id
      } else {
        this.$router.push({
          name: 'Customer Search',
        })
      }

      // 権限設定
      this.isReadOnly = !this.$can('component.update_customer_reservation')
      this.isDeletable = this.$can('component.delete_customer_reservation')

      this.getStoreData()
      this.getCustomerData()

      // 予約履歴リスト作成時に、予約に紐付けられたスタッフが存在するかチェックするため同期をとる
      const p = this.getEmployeeData()
      p.then(this.getReservationData)
    },
    methods: {
      getStoreData () {
        this.isStoreProcessing = true
        api.getStore().then(res => {
          if (res.data.length === 0) {
            this.storeList = []
            this.FailedGetStore = true
          } else {
            this.storeList = res.data
            this.storeList.sort(function (a, b) {
              if (a.name < b.name) {
                return -1
              }
              if (a.name > b.name) {
                return 1
              }
              return 0
            })
            this.storeList.push({ _id: 'none', name: this.$t('customer_search.none_store') })
            this.FailedGetStore = false
          }
        }).catch(e => {
          console.log(e)
          this.FailedGetStore = true
          if (e.code === 'ECONNABORTED') {
            this.isApiTimeout = true
            this.errors.push(this.$t('errors.api_timeout'))
          } else {
            this.errors.push(this.$t('errors.unexpected'))
          }
        }).finally(() => {
          this.isStoreProcessing = false
        })
      },
      getEmployeeData () {
        this.isEmployeeProcessing = true
        this.employeeList = [
          // 対応スタッフが未指定の予約用のダミーデータを定義しておく
          {
            employee_id: '',
            last_name: this.$t('customer_reservation_history.staff_unspecified'),
            first_name: '',
          },
        ]
        const p = api.getAllEmployees().then(r => {
          this.employeeList.push(...r.data)
          this.employeeList = this.employeeList.map(item => {
            // 表示用のプロパティ追加
            item.fullname = item.last_name + ' ' + item.first_name
            return item
          })
          this.FailedGetEmployee = false
        }).catch(e => {
          this.FailedGetEmployee = true
          if (e.code === 'ECONNABORTED') {
            this.isApiTimeout = true
            this.errors.push(this.$t('errors.api_timeout'))
          } else {
            this.errors.push(this.$t('errors.unexpected'))
          }
        }).finally(() => {
          this.isEmployeeProcessing = false
        })
        return p
      },
      getCustomerData () {
        this.isCustomerProcessing = true
        api.getCustomer(this.customerId).then(res => {
          this.customer = res.data
          this.customerName = this.customer.profile.lastname + ' ' + this.customer.profile.firstname
          this.FailedGetCustomer = false
          this.isCustomerNotFound = false
        }).catch(e => {
          console.log(e)
          this.FailedGetCustomer = true
          if (e.code === 'ECONNABORTED') {
            this.isApiTimeout = true
            this.errors.push(this.$t('errors.api_timeout'))
          } else if (e.response && (e.response.status === 403 || e.response.status === 404)) {
            this.isCustomerNotFound = true
          } else {
            this.errors.push(this.$t('errors.unexpected'))
          }
        }).finally(() => {
          this.isCustomerProcessing = false
        })
      },
      getReservationData: function () {
        this.isHistoryProcessing = true
        let storeId = null
        if (localStorage.getItem('role') !== 'manager') {
          storeId = localStorage.getItem('storeId')
        }
        api.getCustomerReservationHistory(this.customerId, storeId).then(res => {
          if (res.data.length === 0) {
            this.isZeroReservations[0].zeroFlag = true
            this.isZeroReservations[1].zeroFlag = true
          } else {
            const reservations = res.data.map(item => {
              if (item.reservation_type === 'web') {
                if (item.online_support.toolname === 'Google Meet') {
                  item.meetingUrl = 'https://meet.google.com/' + item.online_support.token
                } else {
                  item.meetingUrl = item.online_support.admin_url
                }
              }
              const employee = this.employeeList.find(x => x.employee_id === item.employee_id)
              if (!employee) {
                // 予約に紐付いたスタッフが削除や異動されていた場合、表示用のダミーデータをスタッフリストに追加する
                this.employeeList.push({
                  employee_id: item.employee_id,
                  fullname: item.employee_id + this.$t('customer_reservation_history.staff_undefined'),
                })
              }
              return item
            })
            // 確定予約とキャンセル済み予約を分けてリスト化
            this.reservationList = [
              reservations.filter(x => x.available),
              reservations.filter(x => !x.available),
            ]
            // 予約数が0でないかチェック
            this.isZeroReservations[0].zeroFlag = this.reservationList[0].length === 0
            this.isZeroReservations[1].zeroFlag = this.reservationList[1].length === 0
            // 画面表示用の確定予約とキャンセル済み予約を分けてリスト化
            this.displayList = [
              this.reservationList[0].slice(0, this.pageSize),
              this.reservationList[1].slice(0, this.pageSize),
            ]
            // 確定予約とキャンセル済み予約のページ数をそれぞれ算出
            this.pageLengths = [
              Math.ceil(this.reservationList[0].length / this.pageSize),
              Math.ceil(this.reservationList[1].length / this.pageSize),
            ]
          }
          this.FailedGetHistory = false
        }).catch(e => {
          console.log(e)
          this.FailedGetHistory = true
        }).finally(() => {
          this.isHistoryProcessing = false
        })
      },
      changeDisplayedReservation: function (pageNumber) {
        scrollTo(0, 0)
        this.displayList[this.tab] = this.reservationList[this.tab].slice(
          this.pageSize * (pageNumber - 1),
          this.pageSize * (pageNumber))
      },
      showDialog: function (res) {
        this.isUpdateSuccess = false
        this.isDeleteSuccess = false
        this.errorsInUpdating = []
        this.dialogPickedDate = moment(res.reservation_date).format('YYYY-MM-DD')
        this.showContent = true
        const storeName = this.storeList.find(item => item._id === res.store_id).name
        this.displayDetail = DisplayDetail.fromReservationHistory(res, storeName, this.employeeList, this.customer)
      },
      closeDialog () {
        this.showContent = false
        this.errorIsnUpdating = []
        this.dialogDatePickerMenu = false
      },
      updateAndCloseDialog () {
        this.isUpdating = true
        this.errorsInUpdating = []
        const params = this.displayDetail.toApi()
        api.updateCustomerReservation(params._id, params).then(res => {
          const idx = this.reservationList[this.tab].findIndex(x => x._id === params._id)
          this.reservationList[this.tab].splice(idx, 1, params)
          this.reservationList[this.tab].sort(this.compareReservationDatetime)
          this.changeDisplayedReservation(this.pages[this.tab])
          this.isUpdateSuccess = true
          scrollTo(0, 0)
          this.closeDialog()
        }).catch(e => {
          this.isUpdateSuccess = false
          if (e.code === 'ECONNABORTED') {
            this.isApiTimeout = true
            this.errorsInUpdating.push(this.$t('errors.api_timeout'))
          } else if (e.response !== undefined && e.response.data.error_code === 1000) {
            this.errorsInUpdating.push(this.$t('customer_reservation_browsing.errors.update.capacity_exceeded'))
          } else if (e.response !== undefined && e.response.data.error_code === 1001) {
            this.errorsInUpdating.push(this.$t('customer_reservation_browsing.errors.update.reservation_duplication'))
          } else if (e.response !== undefined && e.response.data.error_code === 1002) {
            this.errorsInUpdating.push(this.$t('customer_reservation_browsing.errors.update.staff_absence'))
          } else {
            this.errorsInUpdating.push(this.$t('errors.unexpected'))
          }
        }).finally(() => {
          this.isUpdating = false
        })
      },
      openDeleteDialog () {
        const customerName = this.displayDetail.customer.profile.lastname + this.displayDetail.customer.profile.firstname + ' ' + this.$t('customer_reservation_browsing.event.honorific_title')
        const date = moment(this.displayDetail.formattedPickedDate).format('LL')
        const time = this.displayDetail.startTime + '~' + this.displayDetail.endTime
        this.cautionMessage = this.$t('customer_reservation_browsing.delete.message').replace('{{customer_name}}', customerName).replace('{{datetime}}', date + time)
        this.errorsInDeleting = []
        this.showDeleteDialog = true
      },
      closeDeleteDialog () {
        this.showDeleteDialog = false
      },
      deleteAndCloseDeleteDialog () {
        this.isDeleting = true
        const _id = this.displayDetail.toApi()._id
        api.deleteCustomerReservation(_id).then(res => {
          // 削除した予約をキャンセル済みリストに移動
          const idx = this.reservationList[0].findIndex(x => x._id === _id)
          const deletedReservation = Object.create(this.reservationList[0][idx])
          deletedReservation.available = false
          this.reservationList[1].push(deletedReservation)
          this.reservationList[1].sort(this.compareReservationDatetime)
          this.pageLengths[1] = Math.ceil(this.reservationList[1].length / this.pageSize)
          this.displayList[1] = this.reservationList[1].slice(0, this.pageSize)
          this.pages[1] = 1
          this.isZeroReservations[1].zeroFlag = false

          // 削除した予約を確定予約リストから除外
          this.reservationList[0].splice(idx, 1)
          this.isZeroReservations[0].zeroFlag = this.reservationList[0].length === 0
          this.pageLengths[0] = Math.ceil(this.reservationList[0].length / this.pageSize)
          this.changeDisplayedReservation(this.pages[0])

          // キャッシュの予約確定数/キャンセル数の値を変更
          if (this.$store.state.customerSearchResult != null) {
            const target = this.$store.state.customerSearchResult.find(x => x.customer_id === this.customerId)
            target.decidedResNum -= 1
            target.canceledResNum += 1
          }

          this.isDeleteSuccess = true
          this.closeDeleteDialog()
          this.closeDialog()
        }).catch(e => {
          console.log(e)
          if (e.code === 'ECONNABORTED') {
            this.errorsInDeleting.push(this.$t('errors.api_timeout'))
          } else if (e.response && e.response.status === 404) {
            this.errorsInDeleting.push(this.$t('customer_reservation_browsing.errors.delete_not_found'))
          } else {
            this.errorsInDeleting.push(this.$t('errors.unexpected'))
          }
          this.isDeleteSuccess = false
        }).finally(() => {
          this.isDeleting = false
        })
      },
      validateReservationDate (v) {
        const now = moment()
        return moment(v).isSameOrAfter(now, 'day')
      },
      validateTime (v) {
        const now = moment()
        const reservationDatetime = moment(this.displayDetail.formattedPickedDate + ' ' + v)
        return reservationDatetime.isAfter(now)
      },
      judgeTimeRel (v, isStart) {
        let start = null
        let end = null
        if (isStart) {
          start = moment(this.displayDetail.formattedPickedDate + ' ' + v)
          end = moment(this.displayDetail.formattedPickedDate + ' ' + this.displayDetail.endTime)
        } else {
          start = moment(this.displayDetail.formattedPickedDate + ' ' + this.displayDetail.startTime)
          end = moment(this.displayDetail.formattedPickedDate + ' ' + v)
        }
        return end.isAfter(start)
      },
      judgeTimeDiff (v, isStart) {
        let start = null
        let end = null
        if (isStart) {
          start = moment(this.displayDetail.formattedPickedDate + ' ' + v)
          end = moment(this.displayDetail.formattedPickedDate + ' ' + this.displayDetail.endTime)
        } else {
          start = moment(this.displayDetail.formattedPickedDate + ' ' + this.displayDetail.startTime)
          end = moment(this.displayDetail.formattedPickedDate + ' ' + v)
        }
        return end.diff(start, 'hours') === 1
      },
      compareReservationDatetime (a, b) {
        const datetimeA = a.reservation_date + a.start_time
        const datetimeB = b.reservation_date + b.start_time

        let comparison = 0
        if (datetimeA > datetimeB) {
          comparison = 1
        } else if (datetimeA > datetimeB) {
          comparison = -1
        }
        return comparison * -1
      },
    },
  }
</script>

<style>
.calendar {
  margin-top: 0px;
  margin-bottom: 0px;
}
.v-progress-circular {
  margin: 1rem;
}
.v-picker.v-card {
  margin: 0px;
}
/* スピナーを回すときのコンテンツ非表示用
 * v-showだとカレンダーの表示が崩れるため
 * v-ifだとdomが生成されず、カレンダーの初期化に失敗するため
 */
.hide {
  visibility:hidden;
}
.fc-col-header-cell.fc-day-sat {
  background-color: #d2d8fc;
}
.fc-col-header-cell.fc-day-sun {
  background-color:#fae1e1;
}
.holiday {
  background-color:#fae1e1;
}
.theme--light .v-input--is-disabled * {
  color: rgba(0,0,0,0.87) !important
}
.theme--light .black-label .v-label {
  color: rgba(0,0,0,0.87) !important
}
div.col {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
div.col-sm-10.col-12 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
div.col-sm-2.col-12 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
div.col-sm-3.col-12 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
div.col-sm-6.col-12 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.v-card {
  padding-left: 1rem;
  padding-right: 1rem;
}
</style>
