<template>
  <v-container
    v-if="!inited"
    id="grid"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-progress-circular
        indeterminate
        color="primary"
        :size="100"
      />
    </v-row>
  </v-container>

  <v-container
    v-else
    id="grid"
    fluid
    tag="section"
  >
    <v-row style="font-size: 12px; margin-left: 16px">
      {{ $t("entry.notation") }}
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <v-select
          v-model="selectedStore"
          :items="storeList"
          item-text="name"
          item-value="_id"
          :label="$t('entry.store_selector')"
          prepend-icon="mdi-map-marker-radius"
          :disabled="validated && errors.length === 0"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="8"
      >
        <v-file-input
          v-model="selected"
          accept=".xlsx"
          :label="$t('entry.placeholder')"
          lazy-validation
          @click="clear"
          @click:clear="clear"
        />
      </v-col>

      <v-col cols="2">
        <v-btn
          v-if="validated && errors.length === 0"
          block
          color="secondary"
          :disabled="isProcessing"
          @click="entryShift"
        >
          {{ $t("entry.button.entry") }}
        </v-btn>
        <v-btn
          v-else
          block
          color="primary"
          :disabled="isProcessing || selected == null || selectedStore == null"
          @click="check"
        >
          {{ $t("entry.button.validate") }}
        </v-btn>
      </v-col>
    </v-row>

    <material-alert
      v-for="(error, index) in errors"
      :key="index"
      color="error"
      dark
      dismissible
      icon="mdi-bell"
    >
      {{ error }}
    </material-alert>

    <material-alert
      v-if="validated && errors.length === 0"
      color="success"
      dark
      dismissible
      icon="mdi-bell"
    >
      {{ $t("entry.validation_success") }}
    </material-alert>

    <v-dialog
      v-model="showDialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t("entry.dialog.title") }}</span>
        </v-card-title>
        <v-card-text class="line-break">
          {{ $t("entry.dialog.description") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey lighten-2"
            @click="showDialog = false"
          >
            {{ $t("entry.dialog.cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            @click="convertAndEntry"
          >
            {{ $t("entry.dialog.entry") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row justify="center">
      <v-progress-circular
        v-if="isProcessing"
        indeterminate
        color="primary"
        :size="100"
      />
    </v-row>
  </v-container>
</template>

<script>
  import xlsx from 'xlsx'
  import moment from 'moment'
  import * as api from '../api.js'
  import MaterialAlert from '../components/atoms/MaterialAlert.vue'

  export default {
    name: 'EntryShift',
    components: {
      MaterialAlert,
    },
    data: () => ({
      inited: false,
      selected: null,
      validated: false,
      errors: [],
      shiftCategory: null,
      year: '',
      month: '',
      uploadedData: null,
      showDialog: false,
      isProcessing: false,
      storeList: [],
      selectedStore: null,
    }),
    mounted () {
      api.getStore().then(res => {
        if (res.data.length === 0) {
          this.storeList = []
        } else {
          this.storeList = res.data
          this.storeList.sort(function (a, b) {
            if (a.name < b.name) {
              return -1
            }
            if (a.name > b.name) {
              return 1
            }
            return 0
          })
          this.selectedStore = res.data[0]._id // 先頭要素を初期値とする
        }
        this.inited = true
      }).catch(e => {
        console.log(e)
        this.inited = true
        this.errors.push(this.$t('entry.errors.store_api'))
      })
    },
    methods: {
      check () {
        this.isProcessing = true
        this.validated = false
        this.errors = []
        this.validate().then(x => {
          this.validated = true
          this.isProcessing = false
        }).catch(e => {
          console.log(e)
          this.errors.push(this.$t('entry.errors.unexpected'))
          this.validated = true
          this.isProcessing = false
        })
      },
      async validate () {
        if (!this.isExcelFile(this.selected.name)) {
          this.errors.push(this.selected.name + ' ' + this.$t('entry.errors.invalid_file_suffix'))
          return
        }
        this.uploadedData = await this.load(this.selected)
        if (this.uploadedData == null) {
          return
        }

        // get validation data
        this.shiftCategory = await api.getShiftCategory(this.selectedStore).catch(e => {
          this.errors.push(this.$t('entry.errors.shift_category_api'))
          return null
        })
        if (this.shiftCategory === null) {
          return
        } else {
          this.shiftCategory = this.shiftCategory.data
        }
        let staffs = await api.getStoreStaffs(this.selectedStore).catch(e => {
          this.errors.push(this.$t('entry.errors.employee_api'))
          return null
        })
        if (staffs === null) {
          return
        } else {
          staffs = staffs.data
        }

        // validate
        this.checkStaffExists(this.uploadedData, staffs)
        this.checkShiftCategoryExists(this.uploadedData, this.shiftCategory)
        this.checkStaffDuplicates(this.uploadedData)
      },
      async load (file) {
        try {
          const content = await this.readFileAsync(file)
          return content
        } catch (e) {
          console.log(e)
          this.errors.push(this.$t('entry.errors.cant_read_file'))
          return null
        }
      },
      readFileAsync (file) {
        return new Promise((resolve, reject) => {
          file.arrayBuffer().then((buffer) => {
            try {
              const workbook = xlsx.read(buffer, { type: 'buffer', bookVBA: true })
              const dat = this.getShiftData(workbook)
              resolve(dat)
              // const data = xlsx.utils.sheet_to_json(worksheet)
              // setExcelData(JSON.stringify(data))
            } catch (e) {
              reject(e)
            }
          })
        })
      },
      getShiftData (workbook) {
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]

        this.year = worksheet.B2.v
        this.month = worksheet.D2.v
        // 空白セルはundefinedになる
        let targetColumn = 'C'
        const targetRow = 4
        const totalDays = moment(new Date(this.year, this.month - 1, 1)).add(1, 'months').add(-1, 'days')
        const endRow = parseInt(totalDays.format('DD')) + targetRow + 1
        let count = targetColumn.charCodeAt(0)
        let targetCell = targetColumn + targetRow
        const staffShifts = []
        while (worksheet[targetCell] !== undefined) {
          const dat = {
            year: this.year,
            month: this.month,
          }
          const target = worksheet[targetCell]
          dat.id = target.v.toString().trim()
          dat.shifts = []
          for (let r = targetRow + 1; r < endRow; r++) {
            const tmp = worksheet[targetColumn + r]
            if (tmp === undefined) {
              dat.shifts.push('')
            } else {
              dat.shifts.push(tmp.v.toString().trim())
            }
          }
          staffShifts.push(dat)

          // increment
          count++
          targetColumn = String.fromCharCode(count)
          targetCell = targetColumn + targetRow
        }
        return staffShifts
      },
      checkStaffExists (uploadedData, storeStaffs) {
        const staffs = uploadedData.map(x => x.id)
        const storeStaffIds = storeStaffs.map(x => x.employee_id)
        staffs.forEach(s => {
          if (storeStaffIds.indexOf(s) === -1) {
            this.errors.push(this.$t('entry.errors.invalid_staff').replace('{{employee_id}}', s))
          }
        })
      },
      checkShiftCategoryExists (uploadedData, shiftCategorys) {
        const shifts = uploadedData.map(x => x.shifts).map(x => new Set(x)).reduce(
          (acc, cur) => this.union(acc, cur),
          new Set(),
        )
        const shiftCategoryNames = shiftCategorys.map(x => x.name)
        shifts.forEach(s => {
          if (s !== '' && shiftCategoryNames.indexOf(s) === -1) {
            this.errors.push(this.$t('entry.errors.invalid_shift_category').replace('{{category}}', s))
          }
        })
      },
      union (setA, setB) {
        const _union = new Set(setA)
        for (const elem of setB) {
          _union.add(elem)
        }
        return _union
      },
      isExcelFile (filename) {
        const ext = this.getExt(filename).toLowerCase()
        return ext === 'xlsx' || ext === 'xls'
      },
      getExt (filename) {
        const pos = filename.lastIndexOf('.')
        if (pos === -1) return ''
        return filename.slice(pos + 1)
      },
      checkStaffDuplicates (uploadedData) {
        const staffs = uploadedData.map(x => x.id.trim())
        const dups = new Map()
        staffs.forEach(s => {
          if (dups[s] !== undefined && dups[s] === 1) {
            dups[s] += 1
            this.errors.push(this.$t('entry.errors.user_duplicates').replace('{{employee_id}}', s))
          } else {
            dups[s] = 1
          }
        })
      },
      clear () {
        this.errors = []
        this.validated = false
        this.selected = null
        this.isProcessing = false
      },
      async checkShiftAlreadyEntered () {
        let ret = true
        ret = await api.getMonthlyShift(this.selectedStore, this.year, this.month).catch(e => {
          if (e.response !== undefined && e.response.status === 404) {
            return false
          } else {
            throw e
          }
        })
        return ret
      },
      entryShift () {
        this.checkShiftAlreadyEntered().then(res => {
          if (res) {
            this.showDialog = true
          } else {
            this.convertAndEntry()
          }
        }).catch(e => {
          console.log(e)
          this.errors.push(this.$t('errors.unexpected'))
        })
      },
      convertAndEntry () {
        this.isProcessing = true
        this.showDialog = false

        try {
          const dataToSend = {
            data: [],
            year_month: ('0000' + this.year).slice(-4) + '-' + ('00' + this.month).slice(-2),
            store_id: this.selectedStore,
          }
          this.uploadedData.forEach(d => {
            for (let i = 0; i < d.shifts.length; i++) {
              const doc = {
                employee_id: d.id,
                store_id: this.selectedStore,
              }
              doc.date = moment(new Date(this.year, this.month - 1, i + 1)).format('YYYY-MM-DDTHH:mm:ss')
              const category = this.shiftCategory.find(c => c.name === d.shifts[i])
              if (category !== undefined) {
                const timeframe = {}
                const tmp = moment(new Date(this.year, this.month - 1, i + 1, 0, 0, 0))
                for (let j = 0; j < 24; j++) {
                  const hour = tmp.format('HH:mm')
                  timeframe[hour] = (hour >= category.start_time && hour < category.end_time)
                  tmp.add(1, 'hours')
                }
                doc.timeframe = timeframe
                doc.shift_category_id = category.name
                dataToSend.data.push(doc)
              }
            }
          })
          api.entryReservationShift(dataToSend).then(res => {
            this.$router.push({
              name: 'Shift Browsing',
              params: {
                store_id: this.selectedStore,
                picked_date: moment(new Date(this.year, this.month - 1, 1)).format('YYYY-MM'),
              },
            })
          }).catch(e => {
            if (e.code === 'ECONNABORTED') {
              this.errors.push(this.$t('errors.api_timeout'))
            }
            this.isProcessing = false
          })
        } catch (e) {
          this.isProcessing = false
          this.errors.push(this.$t('entry.errors.unexpected'))
        }
      },
    },
  }
</script>
<style scoped>
.line-break {
  white-space:pre-wrap;
  word-wrap:break-word;
}
.v-progress-circular {
  margin: 1rem;
}
</style>
