<template>
  <v-container>
    <material-alert
      v-if="failedGetReward"
      color="error"
      dark
      dismissible
      icon="mdi-bell"
    >
      {{ $t('reward_history.errors.reward_api') }}
    </material-alert>
    <v-container
      v-if="!isRewardProcessing && !failedGetReward"
      id="grid"
      fluid
      tag="section"
    >
      <h2>{{ $t('reward_history.condition') }}</h2>
      <v-form
        ref="searchForm"
      >
        <v-row>
          <v-col
            cols="12"
            sm="3"
          >
            <v-select
              v-model="selectedReward"
              :items="rewardList"
              item-text="title"
              item-value="project_id"
              :label="$t('reward_history.placeholder.reward')"
              :disabled="isProcessing"
              prepend-icon="mdi-medal"
            />
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <v-text-field
              v-model="shortCustomerId"
              type="text"
              :label="$t('reward_history.placeholder.short_customer_id')"
              :disabled="isProcessing"
              prepend-icon="mdi-card-account-details"
            />
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <v-menu
              v-model="startDatePickerMenu"
              offset-y
              :close-on-content-click="false"
              max-width="auto"
              min-width="auto"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="formattedStartPickedDate"
                  :label="$t('reward_history.placeholder.start_date')"
                  :rules="reservationDateRule"
                  :disabled="isProcessing"
                  readonly
                  clearable
                  prepend-icon="mdi-calendar"
                  v-on="on"
                  @click:clear="startPickedDate = null"
                />
              </template>
              <v-date-picker
                v-model="startPickedDate"
                :locale="locale"
                :disabled="isProcessing"
                :day-format="date => new Date(date).getDate()"
                @click="startDatePickerMenu = false"
              />
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <v-menu
              v-model="endDatePickerMenu"
              offset-y
              :close-on-content-click="false"
              max-width="auto"
              min-width="auto"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="formattedEndPickedDate"
                  :label="$t('reward_history.placeholder.end_date')"
                  :rules="reservationDateRule"
                  :disabled="isProcessing"
                  readonly
                  clearable
                  prepend-icon="mdi-calendar"
                  v-on="on"
                  @click:clear="endPickedDate = null"
                />
              </template>
              <v-date-picker
                v-model="endPickedDate"
                :locale="locale"
                :disabled="isProcessing"
                :day-format="date => new Date(date).getDate()"
                @click="endDatePickerMenu = false"
              />
            </v-menu>
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-progress-circular
            v-if="isProcessing"
            indeterminate
            color="primary"
            :size="30"
          />
          <v-btn
            color="primary"
            :disabled="isProcessing || !validateDate()"
            @click="search"
          >
            {{ $t('reward_history.search_button') }}
          </v-btn>
        </v-row>
      </v-form>

      <hr>

      <material-alert
        v-for="error in errors"
        :key="error"
        color="error"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ error }}
      </material-alert>

      <material-alert
        v-if="isZeroHistory"
        color="error"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ $t('reward_history.errors.zero_history') }}
      </material-alert>

      <div v-if="isSearchCompleted && !isZeroHistory">
        <v-data-table
          :headers="headers"
          :items="rewardHistory"
          :items-per-page="10"
          item-key="customer_id"
          sort-by="datetime"
          :sort-desc="true"
          class="elevation-1"
        >
          <template v-slot:item.reward="{ item }">
            <div style="white-space: pre;">
              {{ item.reward }}
            </div>
          </template>
          <template
            v-slot:item.customer_detail="{ item }"
          >
            <v-icon
              small
              class="mr-2"
              @click="showDetail(item)"
            >
              mdi-account-details
            </v-icon>
          </template>
          <template
            v-slot:item.reservation="{ item }"
          >
            <v-icon
              small
              class="mr-2"
              @click="showReservationHistory(item)"
            >
              mdi-calendar
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </v-container>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import MaterialAlert from '../components/atoms/MaterialAlert.vue'
  import * as api from '../api.js'

  export default {
    name: 'RewardHistory',
    components: {
      MaterialAlert,
    },
    data () {
      return {
        errors: [],
        isRewardProcessing: true,
        isProcessing: false,
        isZeroHistory: false,
        isSearchCompleted: false,
        isHeadOnly: false,
        failedGetReward: false,
        selectedReward: '',
        shortCustomerId: '',
        rewardList: [],
        rewardHistory: [],
        locale: 'en',
        headers: [
          {
            text: this.$t('reward_history.header.datetime'),
            align: 'start',
            sortable: true,
            value: 'datetime',
            width: '15%',
            class: 'primary white--text',
          },
          {
            text: this.$t('reward_history.header.reward_title'),
            align: 'start',
            sortable: true,
            value: 'reward_title',
            width: '30%',
            class: 'primary white--text',
          },
          {
            text: this.$t('reward_history.header.short_customer_id'),
            align: 'start',
            sortable: true,
            value: 'short_customer_id',
            width: '20%',
            class: 'primary white--text',
          },
          {
            text: this.$t('reward_history.header.venue_name'),
            align: 'start',
            sortable: true,
            value: 'venue_name',
            width: '20%',
            class: 'primary white--text',
          },
          {
            text: this.$t('reward_history.header.operator_name'),
            align: 'start',
            sortable: true,
            value: 'operator_name',
            width: '15%',
            class: 'primary white--text',
          },
        ],
        startPickedDate: null,
        endPickedDate: null,
        formattedStartPickedDate: null,
        formattedEndPickedDate: null,
        startDatePickerMenu: false,
        endDatePickerMenu: false,
        reservationDateRule: [
          v => this.validateDate() || this.reservationDateError,
        ],
        reservationDateError: '',
      }
    },
    watch: {
      startPickedDate: function (n, o) {
        this.formattedStartPickedDate = moment(this.startPickedDate).format('YYYY/MM/DD')
        // 初回アクセス時以外はendPickedDateのバリデーションをかけなおす
        if (this.$refs.searchForm) {
          this.$refs.searchForm.validate()
        }
      },
      endPickedDate: function (n, o) {
        this.formattedEndPickedDate = moment(this.endPickedDate).format('YYYY/MM/DD')
        // 初回アクセス時以外はstartPickedDateのバリデーションをかけなおす
        if (this.$refs.searchForm) {
          this.$refs.searchForm.validate()
        }
      },
    },
    async mounted () {
      this.locale = process.env.VUE_APP_I18N_LOCALE
      moment.locale(this.locale)

      if (this.$can('component.selectable_head')) {
        this.isHeadOnly = true
      }

      try {
        // 検索条件のリワード一覧を取得してセット
        await this.setRewardList(this.startPickedDate, this.endPickedDate)
        this.isRewardProcessing = false
      } catch (e) {
        console.log(e)
        if (e.response && e.response.status === 404) {
          if (this.isHeadOnly) {
            this.rewardList = []
            this.failedGetReward = false
          } else {
            this.failedGetReward = true
          }
        } else {
          this.failedGetReward = true
        }
        this.isRewardProcessing = false
        return
      }

      // 利用日 from と 利用日 to を直近1週間にセット
      this.setDefaultUsedDate()

      // デフォルトの検索条件でリワード利用履歴を検索する
      await this.search()
    },
    methods: {
      async setRewardList () {
        const res = await api.getRewards()
        this.rewardList = res.data
        this.rewardList.sort(function (a, b) {
          if (a.title < b.title) {
            return -1
          }
          if (a.title > b.title) {
            return 1
          }
          return 0
        })
        this.rewardList.unshift({ project_id: '', title: this.$t('reward_history.all_reward') })
        // キャッシュの値がselectedRewardに入っていない場合、selectedRewardにリストの先頭のリワードIDを設定
        if (this.selectedReward === '') {
          this.selectedReward = this.rewardList[0].project_id
        }
        this.failedGetReward = false
      },
      setDefaultUsedDate () {
        const date = new Date()
        this.endPickedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
        date.setDate(date.getDate() - 6)
        this.startPickedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
      },
      async searchRewardHistory () {
        try {
          const res = await api.getRewardHistory(
            this.startPickedDate,
            this.endPickedDate,
            this.selectedReward,
            this.shortCustomerId,
          )
          this.rewardHistory = res.data
          this.isZeroHistory = this.rewardHistory.length === 0
        } catch (e) {
          this.rewardHistory = []
          if (e.code === 'ECONNABORTED') {
            this.errors.push(this.$t('errors.api_timeout'))
          } else {
            this.errors.push(this.$t('errors.unexpected'))
          }
        }
      },
      async search () {
        this.isProcessing = true
        this.isSearchCompleted = false
        try {
          await this.searchRewardHistory()
          this.isSearchCompleted = true
        } catch (e) {
          console.log(e)
        } finally {
          this.isProcessing = false
        }
      },
      validateDate () {
        this.reservationDateError = ''
        if (this.startPickedDate === null || this.endPickedDate === null) {
          this.reservationDateError = this.$t('reward_history.errors.require_date')
          return false
        }
        if (moment(this.endPickedDate).diff(moment(this.startPickedDate), 'days') > 30) {
          this.reservationDateError = this.$t('reward_history.errors.invalid_date_range')
          return false
        }
        if (!moment(this.endPickedDate).isSameOrAfter(this.startPickedDate)) {
          this.reservationDateError = this.$t('reward_history.errors.invalid_date')
          return false
        }
        return true
      },
    },
  }
</script>
<style scoped>
.line-break {
  white-space:pre-wrap;
  word-wrap:break-word;
}
.v-progress-circular {
  margin: 0.5rem;
}
.right-input {
  padding-top: 0px;
}
.right-input >>> input {
  text-align: right!important;
}
.v-picker.v-card {
  margin: 0px;
}
.top-card {
  margin-top: 0px;
  width: fit-content;
}
.margin-additional {
  margin-top: 28px;
}
.v-data-table >>> i.v-data-table-header__icon {
  color: white!important;
}
.v-data-table >>> th:first-child {
  border-radius: 4px 0px 0px 0px;
}
.v-data-table >>> th * {
  border-radius: 0px 0px 0px 0px;
  font-size: small;
}
.v-data-table >>> th:last-child {
  border-radius: 0px 4px 0px 0px;
}
hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.col-sm-4.col-12 {
  padding-top: 0;
  padding-bottom: 0;
}

</style>
