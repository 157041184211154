<template>
  <v-container
    id="password_change"
    tag="section"
  >
    <v-row
      v-if="isSuccess"
      justify="center"
    >
      <material-alert
        color="success"
        dark
      >
        <span
          class="text-uppercase"
        /> {{ $t('password_change.success_alert') }}
      </material-alert>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-alert
          v-if="error"
          :value="true"
          type="error"
          outline
        >
          {{ error }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col
        md="6"
        sm="8"
      >
        <v-card>
          <v-container fluid>
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-row>
                <v-text-field
                  v-model="current_password"
                  :append-icon="isCurrentPasswordShown ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="isCurrentPasswordShown ? 'text' : 'password'"
                  :label="$t('password_change.placeholder.current_password')"
                  :rules="rules.defaultRule"
                  required
                  @click:append="isCurrentPasswordShown = !isCurrentPasswordShown"
                />
              </v-row>
              <v-form
                ref="new"
              >
                <v-row>
                  <v-text-field
                    v-model="new_password"
                    :append-icon="isNewPasswordShown ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="isNewPasswordShown ? 'text' : 'password'"
                    :label="$t('password_change.placeholder.new_password')"
                    :rules="rules.passwordRule"
                    required
                    @click:append="isNewPasswordShown = !isNewPasswordShown"
                  />
                </v-row>
              </v-form>
              <v-form
                ref="new_again"
              >
                <v-row>
                  <v-text-field
                    v-model="new_password_again"
                    :append-icon="isNewPasswordAgainShown ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="isNewPasswordAgainShown ? 'text' : 'password'"
                    :label="$t('password_change.placeholder.new_password_again')"
                    :rules="rules.passwordAgainRule"
                    required
                    @click:append="isNewPasswordAgainShown = !isNewPasswordAgainShown"
                  />
                </v-row>
              </v-form>
              <v-row
                v-if="isProcessing"
                justify="end"
              >
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="30"
                />
              </v-row>
              <v-row
                v-else
                justify="end"
              >
                <v-btn
                  class="secondary text_on_secondary--text"
                  :disabled="!valid"
                  @click="changePassword"
                >
                  {{ $t('password_change.button') }}
                </v-btn>
              </v-row>
            </v-form>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
</style>

<script>

  import * as api from '../api.js'
  import MaterialAlert from '../components/atoms/MaterialAlert.vue'

  export default {
    name: 'PasswordChange',
    components: {
      MaterialAlert,
    },
    data () {
      return {
        valid: false,
        isProcessing: false,
        isSuccess: false,
        current_password: '',
        isCurrentPasswordShown: false,
        new_password: '',
        isNewPasswordShown: false,
        new_password_again: '',
        isNewPasswordAgainShown: false,
        error: null,
        rules: {
          defaultRule: [v => !!v || this.$t('password_change.required')],
          passwordRule: [
            v => !!v || this.$t('password_change.required'),
            v => this.validateLength(v) || this.$t('password_change.too_short_input'),
            v => this.validatePassword(v) || this.$t('password_change.invalid_password'),
            v => v !== this.current_password || this.$t('password_change.not_changed'),
          ],
          passwordAgainRule: [
            v => !!v || this.$t('password_change.required'),
            v => this.validateLength(v) || this.$t('password_change.too_short_input'),
            v => this.validatePassword(v) || this.$t('password_change.invalid_password'),
            v => this.isSamePassword(v) || this.$t('password_change.different_password'),
          ],
        },
      }
    },
    watch: {
      current_password: function () {
        if (this.new_password !== '') {
          this.$refs.new.validate()
        }
      },
      new_password: function () {
        if (this.new_password_again !== '') {
          this.$refs.new_again.validate()
        }
      },
    },
    updated () {
      // パスワード変更成功通知表示後、一定時間後に通知を消す
      setTimeout(() => { this.isSuccess = false }, 30000)
    },
    mounted () {
      if (this.$route.params.messageKey) {
        this.error = this.$t(this.$route.params.messageKey)
      }
    },
    methods: {
      async changePassword () {
        this.error = null
        if (this.$refs.form.validate() && this.$refs.new.validate() && this.$refs.new_again.validate()) {
          this.isProcessing = true
          api.changePassword(this.current_password, this.new_password).then(res => {
            this.isSuccess = true
            // フォームをリセットする
            this.current_password = ''
            this.new_password = ''
            this.new_password_again = ''
            this.$refs.form.resetValidation()
            this.$refs.new.resetValidation()
            this.$refs.new_again.resetValidation()
          }).catch(e => {
            if (e.response !== undefined && e.response.status === 403) {
              this.error = this.$t('password_change.error_message')
            } else if (e.code === 'ECONNABORTED') {
              this.error = this.$t('errors.api_timeout')
            } else {
              this.error = this.$t('errors.unexpected')
            }
            this.isSuccess = false
          }).finally(() => {
            this.isProcessing = false
          })
        }
      },
      validateLength (target) {
        return target.length >= 8
      },
      validatePassword (target) {
        // 小文字、大文字、数字が含まれるか判定する正規表現を定義
        const ratz = /[a-z]/
        const rAtZ = /[A-Z]/
        const r0t9 = /[0-9]/
        return ratz.test(target) && rAtZ.test(target) && r0t9.test(target)
      },
      isSamePassword (target) {
        return target === this.new_password
      },
    },
  }
</script>
