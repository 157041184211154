<template>
  <v-footer
    id="dashboard-core-footer"
  >
    <v-container class="pa-0">
      <v-row
        align="center"
        no-gutters
      >
        <v-col
          v-for="(link, i) in links"
          :key="i"
          class="text-center mb-sm-0 mb-5"
          cols="auto"
        >
          <a
            :href="link.href"
            class="mr-0 text--darken-3"
            rel="noopener"
            target="_blank"
            v-text="link.text"
          />
        </v-col>

        <v-spacer class="hidden-sm-and-down" />

        <v-col
          cols="12"
          md="auto"
        >
          <div class="text-body-1 font-weight-light pt-6 pt-md-0 text-center">
            &copy; {{ currentYear }}, <a href="https://www.smartwill.co.jp/">Smartwill Inc.</a>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'CommonFooter',
    data: () => ({
      links: [
      ],
      currentYear: new Date().getFullYear(),
    }),
  }
</script>

<style lang="sass">
  #dashboard-core-footer
    a
      font-size: 0.8rem !important
      font-weight: 500
      text-decoration: none
      text-transform: uppercase
    border: 0px solid !important
</style>
<style scoped>
.body-1.font-weight-light.pt-6.pt-md-0.text-center{
  font-size: 0.8rem !important
}
</style>
