import Vue from 'vue'
import Router from 'vue-router'
import * as Auth from './auth.js'
import PasswordChange from './views/PasswordChange.vue'
import EntryShift from './views/EntryShift.vue'
import ShiftBrowsing from './views/ShiftBrowsing.vue'
import CapacityEntry from './views/CapacityEntry.vue'
import CapacityBrowsing from './views/CapacityBrowsing.vue'
import StoreEntry from './views/StoreEntry.vue'
import StoreBrowsing from './views/StoreBrowsing.vue'
import StaffEntry from './views/StaffEntry.vue'
import EmployeeBrowsing from './views/EmployeeBrowsing.vue'
import CustomerSearch from './views/CustomerSearch.vue'
import CustomerDetail from './views/CustomerDetail.vue'
import CustomerReservationBrowsing from './views/CustomerReservationBrowsing.vue'
import DashboardBrowsing from './views/DashboardBrowsing.vue'
import DashboardManagement from './views/DashboardManagement.vue'
import CustomerReservationHistory from './views/CustomerReservationHistory.vue'
import RewardHistory from './views/RewardHistory.vue'
import NotFound from './views/404.vue'
import Login from './views/Login.vue'
// import HealthCheck from './views/HealthCheck.vue'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    // Home
    {
      path: '',
      redirect: '/login',
      meta: {
        can: 'all',
      },
    },
    // Health Check
    /*
    {
      name: 'Health Check',
      path: '/health-check',
      component: HealthCheck,
      meta: {
        isPublic: true,
        layout: 'simple',
        requiresAuth: false,
        can: 'route.health_check',
      },
    },
    */
    // Pages
    {
      name: 'Password Change',
      path: '/component/password-change',
      component: PasswordChange,
      meta: {
        can: 'route.password_change',
      },
    },
    {
      name: 'Shift Entry',
      path: '/components/shift-entry',
      component: EntryShift,
      meta: {
        can: 'route.shift_entry',
      },
    },
    {
      name: 'Shift Browsing',
      path: '/components/shift-browsing',
      component: ShiftBrowsing,
      meta: {
        can: 'route.shift_browsing',
      },
    },
    {
      name: 'Resesrvation Capacity Entry',
      path: '/components/reservation-capacity-entry',
      component: CapacityEntry,
      meta: {
        can: 'route.reservation_capacity_entry',
      },
    },
    {
      name: 'Resesrvation Capacity Browsing',
      path: '/components/reservation-capacity-browsing',
      component: CapacityBrowsing,
      meta: {
        can: 'route.reservation_capacity_browsing',
      },
    },
    {
      name: 'Store Entry',
      path: '/components/store-entry',
      component: StoreEntry,
      meta: {
        can: 'route.store_entry',
      },
    },
    {
      name: 'Store Browsing',
      path: '/components/store-browsing',
      component: StoreBrowsing,
      meta: {
        can: 'route.store_browsing',
      },
    },
    {
      name: 'Employee Entry',
      path: '/components/staff-entry',
      component: StaffEntry,
      meta: {
        can: 'route.employee_entry',
      },
    },
    {
      name: 'Employee Browsing',
      path: '/components/staff-browsing',
      component: EmployeeBrowsing,
      meta: {
        can: 'route.employee_browsing',
      },
    },
    {
      name: 'Employee Update',
      path: '/components/staff-browsing/:id',
      component: StaffEntry,
      meta: {
        can: 'route.employee_update',
      },
    },
    {
      name: 'Store Update',
      path: '/components/store-browsing/:id',
      component: StoreEntry,
      meta: {
        can: 'route.store_update',
      },
    },
    {
      name: 'Customer Search',
      path: '/components/customer-search',
      component: CustomerSearch,
      meta: {
        can: 'route.customer_search',
      },
    },
    {
      name: 'Customer Detail',
      path: '/components/customer-detail',
      component: CustomerDetail,
      meta: {
        can: 'route.customer_detail',
      },
    },
    {
      name: 'Customer Reservation Browsing',
      path: '/components/customer-reservation-browsing',
      component: CustomerReservationBrowsing,
      meta: {
        can: 'route.customer_reservation_browsing',
      },
    },
    {
      name: 'Dashboard Browsing',
      path: '/components/dashboard-browsing',
      component: DashboardBrowsing,
      meta: {
        can: 'route.dashboard_browsing',
      },
    },
    {
      name: 'Dashboard Management',
      path: '/components/dashboard-management',
      component: DashboardManagement,
      meta: {
        can: 'route.dashboard_management',
      },
    },
    {
      name: 'Customer Reservation History',
      path: '/components/customer-reservation-history/:id',
      component: CustomerReservationHistory,
    },
    {
      name: 'Reward History',
      path: '/components/reward-history',
      component: RewardHistory,
      meta: {
        can: 'route.reward_history',
      },
    },
    {
      name: 'Login',
      path: '/login',
      component: Login,
      meta: {
        isPublic: true,
        layout: 'simple',
      },
    },
    {
      path: '*',
      component: NotFound,
    },
  ],
})

router.beforeEach((to, from, next) => {
  // isPublic でない場合(=認証が必要な場合)、かつ、ログインしていない場合
  if (to.matched.some(record => !record.meta.isPublic) && !Auth.isLoggedIn()) {
    next({
      path: '/login',
      query: {
        redirect: to.fullPath,
      },
    })
  } else {
    next()
  }
})

export default router
