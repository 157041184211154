import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/sass/overrides.sass'

Vue.use(Vuetify)

// const theme = {
//   primary: '#4CAF50',
//   secondary: '#9C27b0',
//   accent: '#9C27b0',
//   info: '#00CAE3',
// }

const theme = {
    primary: '#101254',
    secondary: '#f9c460',
    // secondary: '#EBDCBD',
    accent: '#E1333F',
    // background: '#eee',
    background: '#DCDCDC',
    text_main: '#000000',
    text_on_primary: '#ffffff',
    text_on_secondary: '#000000',
  }

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
