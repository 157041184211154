<template>
  <v-container
    v-if="!inited"
    id="grid"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-progress-circular
        indeterminate
        color="primary"
        :size="100"
      />
    </v-row>
  </v-container>

  <v-container
    v-else
    id="grid"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <v-select
          v-model="selectedStore"
          :items="storeList"
          item-text="name"
          item-value="_id"
          :label="$t('shift_browsing.store_selector_label')"
          prepend-icon="mdi-map-marker-radius"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
      >
        <v-menu
          v-model="datePickerMenu"
          offset-y
          :close-on-content-click="false"
          max-width="auto"
          min-width="auto"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="formattedPickedDate"
              :label="$t('shift_browsing.date_picker_label')"
              readonly
              prepend-icon="mdi-calendar"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="pickedDate"
            type="month"
            :locale="locale"
            @click="datePickerMenu = false"
          />
        </v-menu>
      </v-col>
    </v-row>

    <material-alert
      v-for="error in errors"
      :key="error"
      color="error"
      dark
      dismissible
      icon="mdi-bell"
    >
      {{ error }}
    </material-alert>

    <v-row justify="center">
      <v-progress-circular
        v-if="isProcessing"
        indeterminate
        color="primary"
        :size="100"
      />
    </v-row>

    <div
      v-if="!isProcessing"
    >
      <material-alert
        v-if="errors.length === 0 && Object.keys(convertedShift).length === 0"
        color="error"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ $t('shift_browsing.errors.not_found') }}
      </material-alert>

      <material-alert
        v-if="successUpdate"
        color="success"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ $t('shift_browsing.success_update') }}
      </material-alert>

      <v-row justify="center">
        <v-card
          v-if="Object.keys(convertedShift).length > 0"
          class="px-5 py-3"
          style="width:fit-content"
        >
          <v-card-title>
            {{ $t('shift_browsing.calendar_title_format').replace('${year}', year).replace('${month}', month) }}
          </v-card-title>
          <v-card-text>
            <v-row
              justify="center"
            >
              <table
                class="hoge"
                rules="rows"
              >
                <thead>
                  <tr
                    align="center"
                    class="primary"
                  >
                    <th>
                      {{ $t('shift_browsing.day_of_week') }}
                    </th>
                    <th>
                      {{ $t('shift_browsing.date') }}
                    </th>
                    <th
                      v-for="e in employees"
                      :key="e.employee_id"
                    >
                      <span v-if="!e.not_exists">
                        {{ e.nickname | omittedText }}
                      </span>
                      <span v-else>
                        {{ e.employee_id | omittedText }}
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="mr-2"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-chat-alert
                            </v-icon>
                          </template>
                          <span>{{ $t('shift_browsing.deleted_employee_notification') }}</span>
                        </v-tooltip>
                      </span>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(d, index) in oneMonth"
                    :key="index"
                    align="center"
                    :class="{thursday: d.get('day') === 6, sunday: d.get('day') === 0}"
                  >
                    <td>{{ d.format('ddd') }}</td>
                    <td>{{ d.format('D') }}</td>
                    <td
                      v-for="name in employee_ids"
                      :key="name"
                      :class="{ 'text-color-absent': convertedShift[name][index] === $t('shift_browsing.absent') }"
                    >
                      <a
                        href="javascript:void(0)"
                        @click.prevent.stop="openDetail(name, d)"
                      >
                        {{ convertedShift[name][index] }}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-row>
          </v-card-text>
        </v-card>
      </v-row>
    </div>

    <v-dialog
      v-if="showDialog"
      v-model="showDialog"
      max-width="80vw"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t('shift_browsing.detail.title') }}</span>
        </v-card-title>
        <v-card-text class="line-break">
          <v-row>
            <material-alert
              v-for="error in updateErrors"
              :key="error"
              color="error"
              dark
              dismissible
              width="100%"
              icon="mdi-bell"
            >
              {{ error }}
            </material-alert>
          </v-row>
          <v-row>
            {{ $t('shift_browsing.detail.nickname') }}：{{ currentEmployee.nickname }} {{ $t('shift_browsing.detail.suffix_name') }}
            <br>
            {{ $t('shift_browsing.detail.fullname') }}：{{ currentFullname }} {{ $t('shift_browsing.detail.suffix_name') }}
            <br>
            {{ $t('shift_browsing.detail.store') }}：{{ storeList.find(x => x._id === selectedStore).name }}
            <br>
            {{ $t('shift_browsing.detail.date') }}：{{ currentDate.format('YYYY-MM-DD') }}
          </v-row>
          <v-row style="margin-left: 0px; margin-top: 12px">
            <v-row>
              <h3>{{ $t('shift_browsing.detail.timeframe_title') }}</h3>
            </v-row>
            <v-row>
              <v-col
                v-for="(value, key) in currentDetail.timeframe"
                :key="key"
                lg="1"
                md="2"
                sm="4"
                cols="12"
                style="padding: 0;"
              >
                <v-checkbox
                  v-model="currentDetail.timeframe[key]"
                  :label="key"
                  class="black-label"
                />
              </v-col>
            </v-row>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey lighten-2"
            @click="showDialog = false"
          >
            {{ $t('shift_browsing.detail.close') }}
          </v-btn>
          <v-btn
            v-if="canUpdate()"
            color="primary"
            @click="confirmDialog = true"
          >
            {{ $t('shift_browsing.detail.update') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmDialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t("shift_browsing.detail.confirm.title") }}</span>
        </v-card-title>
        <v-card-text class="line-break">
          {{ $t("shift_browsing.detail.confirm.description") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey lighten-2"
            @click="confirmDialog = false"
          >
            {{ $t("shift_browsing.detail.confirm.cancel") }}
          </v-btn>
          <v-btn
            v-if="currentDetail && currentDetail.shift_category_id === 'none'"
            color="primary"
            :loading="updating"
            @click="entryShift()"
          >
            {{ $t('shift_browsing.detail.update') }}
          </v-btn>
          <v-btn
            v-else
            color="primary"
            :loading="updating"
            @click="updateShift()"
          >
            {{ $t('shift_browsing.detail.update') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import * as api from '../api.js'
  import MaterialAlert from '../components/atoms/MaterialAlert.vue'

  export default {
    name: 'ShiftBrowsing',
    components: {
      MaterialAlert,
    },
    filters: {
      omittedText (text) {
        // 7文字目以降は"…"
        return text.length > 7 ? text.slice(0, 7) + '…' : text
      },
    },
    data: () => ({
      inited: false,
      selected: null,
      errors: [],
      shiftCategory: null,
      uploadedData: null,
      showDialog: false,
      isProcessing: false,
      storeList: [],
      selectedStore: null,
      shifts: [],
      employee_ids: [],
      employees: [],
      oneMonth: [],
      convertedShift: new Map(),
      pickedDate: null,
      formattedPickedDate: '',
      datePickerMenu: false,
      locale: 'en',
      currentDetail: null,
      currentDate: null,
      currentEmployee: null,
      updating: false,
      confirmDialog: false,
      updateErrors: [],
      successUpdate: false,
      // ↓権限管理フラグ
      updateOther: false,
      updateSelf: false,
    }),
    computed: {
      year: function () {
        return moment(this.pickedDate).format('YYYY')
      },
      month: function () {
        return moment(this.pickedDate).format('MM')
      },
      currentFullname: function () {
        if (this.currentEmployee) {
          return this.currentEmployee.last_name + ' ' + this.currentEmployee.first_name
        } else {
          return ''
        }
      },
    },
    watch: {
      selectedStore: function (n, o) {
        // 初期表示ではmountedで更新する
        if (o !== null) {
          this.selecetedParamsCheckAndUpdate()
        }
      },
      pickedDate: function (n, o) {
        this.formattedPickedDate = this.pickedDate.replace('-', '/')
        // 初期表示ではmountedで更新する
        if (o !== null) {
          this.selecetedParamsCheckAndUpdate()
        }
      },
      showDialog: function (v) {
        // ダイアログが閉じられたときに、エラーをリセットする
        if (!v) {
          this.updateErrors = []
        }
      },
    },
    mounted () {
      this.locale = process.env.VUE_APP_I18N_LOCALE
      moment.locale(this.locale)

      if (this.$can('component.update_reservation_shift_other')) {
        this.updateOther = true
      }
      if (this.$can('component.update_reservation_shift_self')) {
        this.updateSelf = true
      }

      api.getStore().then(res => {
        if (res.data.length === 0) {
          this.storeList = []
        } else {
          this.storeList = res.data
          this.storeList.sort(function (a, b) {
            if (a.name < b.name) {
              return -1
            }
            if (a.name > b.name) {
              return 1
            }
            return 0
          })
          // 登録後遷移の場合、登録対象の店舗・年月を表示する
          if (this.$route.params.store_id && this.$route.params.picked_date) {
            this.selectedStore = this.$route.params.store_id
            this.pickedDate = this.$route.params.picked_date
          } else {
            this.selectedStore = res.data[0]._id // 先頭要素を初期値とする
            const now = moment()
            this.pickedDate = now.format('YYYY-MM')
          }
          this.selecetedParamsCheckAndUpdate()
        }
        this.inited = true
      }).catch(e => {
        if (e.response !== undefined && e.response.status === 404) {
          this.errors.push(this.$t('shift_browsing.errors.store_not_found'))
        } else if (e.code === 'ECONNABORTED') {
          this.errors.push(this.$t('errors.api_timeout'))
        } else {
          this.errors.push(this.$t('errors.unexpected'))
        }
        this.inited = true
      })
    },
    methods: {
      getOneMonth (year, month) {
        const start = moment(new Date(year, month - 1, 1))
        const end = start.clone().add(1, 'months').add(-1, 'days')
        const endDay = end.get('date')
        const ret = [start]
        let tmp = moment(new Date(year, month - 1, 1))
        for (let i = 1; i < endDay; i++) {
          tmp = tmp.clone().add(1, 'days')
          ret.push(tmp)
        }
        return ret
      },
      getShifts () {
        this.isProcessing = true
        this.shifts = []
        this.convertedShift = {}

        api.getMonthlyShift(this.selectedStore, this.year, this.month).then(res => {
          this.shifts = res.data
          this.employee_ids = Array.from(new Set(this.shifts.map(x => x.employee_id)))
          api.getStoreStaffs(this.selectedStore).then(res => {
            this.employees = this.employee_ids.map(function (i) {
              let ret = res.data.find(x => x.employee_id === i)
              // 削除や異動されたスタッフは見つからないので、代わりのデータを挿入する
              if (!ret) {
                ret = {
                  employee_id: i,
                  nickname: i,
                  not_exists: true,
                  first_name: '',
                  last_name: i,
                }
              }
              return ret
            })
            // moment.lang('ja', { weekdays: ['日', '月', '火', '水', '木', '金', '土'] })
            this.oneMonth = this.getOneMonth(this.year, this.month)

            this.convertedShift = {}
            this.employee_ids.forEach(i => {
              const hoge = this.oneMonth.map(x => this.$t('shift_browsing.absent'))
              this.convertedShift[i] = hoge
              this.shifts.filter(s => s.employee_id === i).forEach(x => {
                const d = moment(x.date).get('date')
                hoge[d - 1] = this.checkShiftCategory(x)
              })
            })
            this.isProcessing = false
          })
        }).catch(e => {
          if (e.response !== undefined && e.response.status === 404) {
          } else if (e.code === 'ECONNABORTED') {
            this.errors.push(this.$t('errors.api_timeout'))
          } else {
            this.errors.push(this.$t('errors.unexpected'))
          }
          this.isProcessing = false
        })
      },
      selecetedParamsCheckAndUpdate () {
        if (this.pickedDate.search(/^\d{4}-\d{2}$/g) === 0 && this.selectedStore !== null) {
          this.getShifts()
        } else {
          console.log('invalid date format')
        }
      },
      openDetail (employeeId, date) {
        const target = Array.from(new Set(this.shifts.filter(x => x.employee_id === employeeId && x.date === date.format('YYYY-MM-DD'))))
        if (target.length > 0) {
          this.currentDetail = target[0]
        } else {
          // 不在（=データがない）ときは別日のデータを加工することでデータを用意する
          const tmp = this.shifts.find(x => x.employee_id === employeeId)
          tmp.date = date.format('YYYY-MM-DD')
          Object.keys(tmp.timeframe).forEach(key => {
            tmp.timeframe[key] = false
          })
          this.currentDetail = tmp
          this.currentDetail.shift_category_id = 'none'
          delete this.currentDetail._id
        }
        this.currentDate = date
        this.currentEmployee = this.employees.filter(x => x.employee_id === employeeId)[0]
        this.showDialog = true
      },
      updateShift () {
        this.successUpdate = false
        this.updating = true
        this.updateErrors = []
        api.updateOneReservationShift(
          this.currentDetail._id,
          this.currentDetail,
        ).then(res => {
          // 一覧表示の文言更新
          this.selecetedParamsCheckAndUpdate()
          // フラグ更新
          this.updating = false
          this.showDialog = false
          this.confirmDialog = false
          this.successUpdate = true
        }).catch(e => {
          console.log(e)
          if (e.code === 'ECONNABORTED') {
            this.updateErrors.push(this.$t('errors.api_timeout'))
          } else {
            this.updateErrors.push(this.$t('errors.unexpected'))
          }
          this.updating = false
          this.confirmDialog = false
          // ダイアログのスクロールを一番上に戻す
          this.scrollToDialogTop()
        })
      },
      entryShift () {
        this.successUpdate = false
        this.updateErrors = []
        this.currentDetail.shift_category_id = 'カスタム'
        this.updating = true
        api.entryOneReservationShift(
          this.currentDetail,
        ).then(res => {
          // 一覧表示の文言更新
          this.selecetedParamsCheckAndUpdate()
          // フラグ更新
          this.updating = false
          this.showDialog = false
          this.confirmDialog = false
          this.successUpdate = true
        }).catch(e => {
          console.log(e)
          if (e.code === 'ECONNABORTED') {
            this.updateErrors.push(this.$t('errors.api_timeout'))
          } else {
            this.updateErrors.push(this.$t('errors.unexpected'))
          }
          this.updating = false
          this.confirmDialog = false
          // ダイアログのスクロールを一番上に戻す
          this.scrollToDialogTop()
        })
      },
      scrollToDialogTop () {
        const elements = document.getElementsByClassName('v-dialog--active')
        if (!(!elements || !elements.length)) {
          // 要素が取得できなかった場合は終了
          elements.forEach(el => {
            el.scrollTop = 0
          })
        }
      },
      checkShiftCategory (targetShift) {
        let ret = targetShift.shift_category_id
        if (targetShift.updated) {
          // すべてのチェックが外れていたら不在とする
          const isAbsent = !Object.values(targetShift.timeframe).reduce((acc, x) => acc || x, false)
          if (isAbsent) {
            return this.$t('shift_browsing.absent')
          }
          for (const t of Object.keys(targetShift.timeframe)) {
            if (targetShift.timeframe[t] !== targetShift.updated.original_timeframe[t]) {
              ret = this.$t('shift_browsing.custom')
              break
            }
          }
        }
        return ret
      },
      canUpdate () {
        const ownEmployeeId = localStorage.getItem('userId')
        if (ownEmployeeId === this.currentDetail.employee_id) {
          return this.updateSelf
        } else {
          return this.updateOther
        }
      },
    },
  }
</script>
<style scoped>
.line-break {
  white-space:pre-wrap;
  word-wrap:break-word;
}
.v-progress-circular {
  margin: 1rem;
}

.hoge table {
  width: auto!important;
}
table {
  table-layout: fixed;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  width: auto!important;
}
th, td {
  font-weight: 300;
  padding: 6px 6px!important;
  height: 16px!important;
  max-width: 120px!important;
  min-width: 80px!important;
  border-width: 1px 0px; /* 上下だけ引く */
   border-color: lightgray;
}
.thursday {
  background-color: #d2d8fc ;
}
.sunday {
  background-color: #fae1e1;
}
.v-picker.v-card {
  margin: 0px;
}
.text-color-absent {
  color: lightgray;
}
thead th {
  color: white!important;
}
thead th i {
  color: white!important;
}

.theme--light .black-label >>> .v-label {
  color: rgba(0,0,0,0.87) !important
}
</style>
