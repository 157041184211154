
function readCSV (dataPath) {
  const request = new XMLHttpRequest()
  let ret = null
  request.addEventListener('load', (event) => {
      const response = event.target.responseText
      ret = toArray(response)
  })
  request.open('GET', dataPath, false)
  request.send()
  return ret
}

function toArray (data) {
  const dataArray = []
  const dataString = data.split('\n')
  for (let i = 0; i < dataString.length; i++) {
      dataArray[i] = dataString[i].split(',')
  }
  return dataArray
}

export function getHolidays (country) {
  const datapath = './holidays/' + country + '.csv'
  return readCSV(datapath)
}
