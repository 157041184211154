<template>
  <v-container
    id="login"
    tag="section"
    style="margin-bottom: 40px"
  >
    <v-row
      justify="center"
      align="center"
    >
      <v-col
        md="6"
        sm="8"
        align-self="center"
      >
        <v-card outlined>
          <v-container fluid>
            <v-row
              justify="center"
              align="center"
            >
              <v-img
                src="@/assets/bocrm_logo_1l.png"
                max-width="50%"
              />
            </v-row>
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-row>
                <v-col cols="12">
                  <v-alert
                    v-if="error"
                    :value="true"
                    type="error"
                    outline
                  >
                    {{ error }}
                  </v-alert>
                </v-col>
              </v-row>
              <v-row>
                <v-text-field
                  v-model="tenant"
                  type="text"
                  :label="$t('login.placeholder.tenant')"
                  :rules="rules.defaultRule"
                  required
                />
              </v-row>
              <v-row>
                <v-text-field
                  v-model="username"
                  type="text"
                  :label="$t('login.placeholder.user')"
                  :rules="rules.defaultRule"
                  required
                />
              </v-row>
              <v-row>
                <v-text-field
                  v-model="password"
                  :append-icon="isPasswordShown ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="isPasswordShown ? 'text' : 'password'"
                  :label="$t('login.placeholder.password')"
                  :rules="rules.defaultRule"
                  required
                  @click:append="isPasswordShown = !isPasswordShown"
                />
              </v-row>
              <v-row justify="end">
                <v-btn
                  class="primary"
                  :disabled="!valid || isProcessing"
                  :loading="isProcessing"
                  @click="login"
                >
                  {{ $t('login.button') }}
                </v-btn>
              </v-row>
            </v-form>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
</style>

<script>
  import * as Auth from '../auth.js'

  export default {
    name: 'Login',
    data () {
      return {
        valid: false,
        tenant: '',
        username: '',
        password: '',
        isPasswordShown: false,
        isProcessing: false,
        error: null,
        rules: {
          defaultRule: [
            v => !!v || this.$t('login.required'),
            v => this.shorterThanLimit(v) || this.$t('login.too_long_input'),
          ],
        },
      }
    },
    mounted () {
      if (Auth.isLoggedIn()) {
        this.checkRoleAndNavigate(null)
      }
      if (this.$route.params.messageKey) {
        this.error = this.$t(this.$route.params.messageKey)
      }
    },
    methods: {
      async login () {
        this.error = null
        if (this.$refs.form.validate()) {
          this.isProcessing = true
          Auth.login(this.tenant, this.username, this.password).then(res => {
            if (this.$route.query.redirect === undefined) {
              this.checkRoleAndNavigate(res.data)
            } else {
              this.$router.push({
                path: this.$route.query.redirect,
              })
            }
          }).catch(e => {
            if (e.code === 'ECONNABORTED') {
              this.error = this.$t('errors.api_timeout')
            } else {
              this.error = this.$t('login.error_message')
            }
            console.log(e)
          }).finally(() => {
            this.isProcessing = false
          })
        }
      },
      shorterThanLimit (target) {
        return target.length < 256
      },
      checkRoleAndNavigate (params) {
        if (this.$can('component.home_dashboard')) {
          this.$router.push({
            name: 'Dashboard Browsing',
            params: params,
          })
        } else if (this.$can('component.home_reservation')) {
          this.$router.push({
            name: 'Customer Reservation Browsing',
            params: params,
          })
        } else {
          console.log('invalid role.')
        }
      },
    },
  }
</script>
<style scoped>
.v-card.v-sheet.theme--light {
    background-color: #ffffff !important;
    border: 0px solid !important
}
</style>
