import * as api from './api.js'
import axios from 'axios'
import router from './router.js'

if (localStorage.getItem('isLoggedIn') === null) {
  localStorage.setItem('isLoggedIn', 'false')
}
if (localStorage.getItem('expiredOn') === null) {
  localStorage.setItem('expiredOn', '')
}
if (localStorage.getItem('userId') === null) {
  localStorage.setItem('userId', '')
}
if (localStorage.getItem('nickName') === null) {
  localStorage.setItem('nickName', '')
}
if (localStorage.getItem('tenant') === null) {
  localStorage.setItem('tenant', '')
}
if (localStorage.getItem('tenantName') === null) {
  localStorage.setItem('tenantName', '')
}
if (localStorage.getItem('planId') === null) {
  localStorage.setItem('planId', '')
}
if (localStorage.getItem('userName') === null) {
  localStorage.setItem('userName', '')
}
if (localStorage.getItem('role') === null) {
  localStorage.setItem('role', '')
}
if (localStorage.getItem('storeId') === null) {
  localStorage.setItem('storeId', '')
}
function toBoolean (data) {
  return data.toLowerCase() === 'true'
}

export function isLoggedIn () {
  return toBoolean(localStorage.getItem('isLoggedIn'))
}
export function login (tenantId, userId, password) {
  return api.login({
    tenant_id: tenantId,
    user_id: userId,
    password: password,
  }).then(res => {
    localStorage.setItem('isLoggedIn', 'true')
    localStorage.setItem('userId', res.data.user_id)
    localStorage.setItem('tenant', res.data.tenant)
    localStorage.setItem('userName', res.data.user_name)
    localStorage.setItem('tenantName', res.data.tenant_name)
    localStorage.setItem('planId', res.data.plan_id)
    localStorage.setItem('role', res.data.role)
    localStorage.setItem('storeId', res.data.store_id)
    return res
    // this.$router.push({
    //   name: 'Sample',
    //   params: res.data,
    // })
  })
}
export function logout () {
  localStorage.setItem('isLoggedIn', 'false')
  return api.logout().then(res => {
    localStorage.setItem('userId', '')
    // localStorage.setItem('nickName', '')
    localStorage.setItem('tenant', '')
    localStorage.setItem('userName', '')
    localStorage.setItem('tenantName', '')
    localStorage.setItem('planId', '')
    localStorage.setItem('role', '')
    localStorage.setItem('storeId', '')
  })
}

export function getCurrentUser () {
  return {
    userId: localStorage.getItem('userId'),
    // nickName: localStorage.getItem('nickName'),
    tenant: localStorage.getItem('tenant'),
    userName: localStorage.getItem('userName'),
    tenantName: localStorage.getItem('tenantName'),
  }
}

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response !== undefined && error.response.status === 401) {
      localStorage.setItem('isLoggedIn', 'false')
      router.push({
        name: 'Login',
        params: {
          messageKey: 'login.session_expired',
        },
        query: {
          redirect: router.history.current.path,
        },
      })
    }
    return Promise.reject(error)
  },
)
