<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-break-point="960"
    app
    width="260"
    v-bind="$attrs"
    dark
    style="background-color:#000039"
  >
    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-list-item>
        <v-container>
          <v-row
            justify="center"
          >
            <v-img
              src="@/assets/bocrm_logo_2l.png"
              max-width="78%"
            />
          </v-row>
        </v-container>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      dense
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <item-group
          v-if="item.children"
          :key="`group-${i}`"
          v-can="item.can"
          :item="item"
        />

        <item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>

    <!-- <template v-slot:append>
      <base-item
        :item="{
          title: $t('settings'),
          icon: 'mdi-cog',
          to: '/upgrade',
        }"
      />
    </template> -->
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'
  import * as Acl from '../../plugins/acl.js'
  import ItemGroup from '../molecules/ItemGroup.vue'
  import Item from '../atoms/Item.vue'

  export default {
    name: 'MainDrawer',
    components: {
      Item,
      ItemGroup,
    },
    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      items: [
        // {
        //   icon: 'mdi-view-dashboard',
        //   title: 'dashboard',
        //   to: '/',
        // },
        {
          title: 'dashboard',
          icon: 'mdi-view-dashboard',
          group: '',
          children: [
            {
              title: 'dashboard_browsing.title',
              to: 'components/dashboard-browsing',
              can: 'dashboard_browsing',
            },
          ],
          can: 'all',
        },
        {
          title: 'employee_management',
          icon: 'mdi-human-handsdown',
          group: '',
          children: [
            {
              title: 'employee_entry.title',
              to: 'components/staff-entry',
              can: 'employee_entry',
            },
            {
              title: 'employee_browsing.title',
              to: 'components/staff-browsing',
              can: 'employee_browsing',
            },
            {
              title: 'employee_browsing_self.title',
              to: 'components/staff-browsing/' + Acl.user().userId,
              can: 'employee_browsing_self',
            },
          ],
          can: 'all',
        },
        {
          title: 'customer_management',
          icon: 'mdi-shield-account',
          group: '',
          children: [
            {
              title: 'customer_search.title',
              to: 'components/customer-search',
              can: 'customer_search',
            },
          ],
          can: 'all',
        },
        {
          title: 'reward_management',
          icon: 'mdi-medal',
          group: '',
          children: [
            {
              title: 'reward_history.title',
              to: 'components/reward-history',
              can: 'reward_history',
            },
          ],
          can: 'all',
        },
      ],
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('avatar'),
        }
      },
    },

    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
    },
  }
</script>

<style lang="sass">
  @import '../../../node_modules/vuetify/src/styles/tools/rtl'

  #core-navigation-drawer
    .v-list-item--active
      color: black!important
    .v-list-group__header.v-list-item--active:before
      opacity: .24
    .v-list-group__header
      .v-icon
        color: white
      .v-list-item__title
        color: white
        padding-top: 2px

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
