<template>
  <v-container>
    <v-container
      v-if="isProcessing"
      id="grid"
      fluid
      tag="section"
    >
      <v-row justify="center">
        <v-progress-circular
          indeterminate
          color="primary"
          :size="100"
        />
      </v-row>
    </v-container>

    <v-container
      v-else
      id="grid"
      fluid
      tag="section"
    >
      <material-alert
        v-for="error in errors"
        :key="error"
        color="error"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ error }}
      </material-alert>

      <div
        v-if="!FailedGetStore && !FailedGetEmployee"
      >
        <v-data-table
          :headers="headers"
          :items="displayedEmployeeList"
          :items-per-page="10"
          item-key="employee_id"
          :custom-sort="customSort"
          sort-by="employee_id"
          :sort-desc="false"
          class="elevation-1"
        >
          <template
            v-slot:item.actions="{ item }"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{ $t("employee_browsing.tooltip.detail") }}</span>
            </v-tooltip>
            <!-- <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="showPasswordResetDialog(item)"
                >
                  mdi-lock-reset
                </v-icon>
              </template>
              <span>{{ $t("employee_browsing.tooltip.password_reset") }}</span>
            </v-tooltip> -->
          </template>
          <template
            v-slot:item.others="{ item }"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="showPasswordResetDialog(item)"
                >
                  mdi-lock-reset
                </v-icon>
              </template>
              <span>{{ $t("employee_browsing.tooltip.password_reset") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </div>
    </v-container>

    <v-dialog
      v-model="showDialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t("employee_browsing.password_reset.dialog.title") }}</span>
        </v-card-title>
        <v-card-text class="line-break">
          <v-row>
            <material-alert
              v-for="error in resetErrors"
              :key="error"
              color="error"
              dark
              dismissible
              width="100%"
              icon="mdi-bell"
            >
              {{ error }}
            </material-alert>
          </v-row>
          <b>{{ selectedEmployeeFullName }}</b>{{ $t("employee_browsing.password_reset.dialog.description") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey lighten-2"
            @click="closeDialog"
          >
            {{ $t("employee_browsing.password_reset.dialog.cancel") }}
          </v-btn>
          <v-btn
            color="error"
            :loading="isResetting"
            @click="resetPassword()"
          >
            {{ $t("employee_browsing.password_reset.dialog.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="resultDialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t("employee_browsing.password_reset.dialog.title") }}</span>
        </v-card-title>
        <v-card-text class="line-break">
          <b>{{ selectedEmployeeFullName }}</b>{{ $t("employee_browsing.password_reset.dialog.result") }}
          <v-row justify="center">
            <h2 class="spacing-around">
              {{ newPassword }}
            </h2>
          </v-row>
          <v-row>
            {{ $t("employee_browsing.password_reset.dialog.notice") }}
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey lighten-2"
            @click="resultDialog = false"
          >
            {{ $t("employee_browsing.password_reset.dialog.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import * as api from '../api.js'
  import MaterialAlert from '../components/atoms/MaterialAlert.vue'

  const UNDEFINED_STORE = '-2'
  const ALL_STORE = '-1'

  export default {
    name: 'EmployeeBrowsing',
    components: {
      MaterialAlert,
    },
    data () {
      return {
        errors: [],
        showDialog: false,
        isProcessing: true,
        isHeadOnly: false,
        storeList: [],
        displayedEmployeeList: [],
        employeeList: [],
        locale: 'en',
        selectedStore: null,
        employeeInUndefiendStore: [],
        FailedGetEmployee: false,
        FailedGetStore: false,
        headers: [
          {
            text: this.$t('employee_browsing.header.employee_id'),
            align: 'start',
            sortable: true,
            value: 'employee_id',
            width: '12%',
            class: 'primary white--text',
          },
          { text: this.$t('employee_browsing.header.name'), value: 'name', width: '20%', class: 'primary white--text' },
          {
            text: this.$t('employee_browsing.header.nickname'),
            sortable: true,
            value: 'nickname',
            width: '20%',
            class: 'primary white--text',
          },
          { text: this.$t('employee_browsing.header.detail'), value: 'actions', sortable: false, width: '12%', class: 'primary white--text' },
          { text: this.$t('employee_browsing.header.other'), value: 'others', sortable: false, width: '12%', class: 'primary white--text' },
        ],
        // パスワードリセット関連
        selectedEmployee: null,
        selectedEmployeeFullName: '',
        isResetting: false,
        newPassword: '',
        resultDialog: false,
        resetErrors: [],
      }
    },
    computed: {
    },
    watch: {
      selectedStore: function () {
        if (this.selectedStore !== null) {
          this.toDisplayList(this.selectedStore)
        }
      },
    },
    mounted () {
      this.locale = process.env.VUE_APP_I18N_LOCALE
      moment.locale(this.locale)

      // 追加
      if (this.$can('component.selectable_head')) {
        this.isHeadOnly = true
      }

      // TODO: 店舗一覧取得せずとも画面描画できるのでいずれ削除したい
      const p1 = api.getStore().then(res => {
        this.setStoreData(res.data)
        this.FailedGetStore = false
      }).catch(e => {
        console.log(e)
        if (e.code === 'ECONNABORTED') {
          this.errors.push(this.$t('errors.api_timeout'))
          this.FailedGetStore = true
        } else if (e.response && e.response.status === 404) {
          if (this.isHeadOnly) {
            this.setStoreData([])
          } else {
            this.errors.push(this.$t('employee_browsing.errors.store_not_found'))
            this.FailedGetStore = true
          }
        } else {
          this.errors.push(this.$t('errors.unexpected'))
          this.FailedGetStore = true
        }
        this.isProcessing = false
      })
      p1.then(() => {
        api.getAllEmployees().then(res => {
          this.employeeList = res.data
          this.toDisplayList(ALL_STORE)
        }).catch(e => {
          console.log(e)
          if (e.code === 'ECONNABORTED') {
            this.errors.push(this.$t('errors.api_timeout'))
            this.FailedGetEmployee = true
          } else if (e.response && e.response.status === 404) {
            this.employeeList = []
            this.toDisplayList(ALL_STORE)
          } else {
            this.errors.push(this.$t('errors.unexpected'))
            this.FailedGetEmployee = true
          }
        }).finally(() => {
          this.isProcessing = false
        })
      })
    },
    methods: {
      closeDialog () {
        this.isProcessing = false
        this.showDialog = false
      },
      customSort (items, index, isDesc) {
        if (index.length === 0 || isDesc.length === 0) {
          // ソートをoffにしたときの順番
          // console.log('default')
          items.sort((x, y) => x.employee_id.localeCompare(y.employee_id, this.locale, { numeric: true }))
          return items
        }
        const k = index[0]
        if (isDesc[0]) {
          // console.log('desc')
          items.sort((x, y) => -x[k].localeCompare(y[k], this.locale, { numeric: true }))
        } else {
          // console.log('asc')
          items.sort((x, y) => x[k].localeCompare(y[k], this.locale, { numeric: true }))
        }
        return items
      },
      toDisplayList (storeId) {
        this.employeeInUndefiendStore = []
        const gfn = this.getFullName
        if (storeId === UNDEFINED_STORE) {
          this.toDisplayList(ALL_STORE)
          this.displayedEmployeeList = this.employeeInUndefiendStore
        } else if (storeId === ALL_STORE) {
          this.displayedEmployeeList = this.employeeList.map(x => {
            let storeName = this.$t('employee_browsing.non_store')
            // 店舗未所属の場合の店舗IDは'none'。
            if (x.store_id !== 'none') {
              const tmp = this.storeList.find(y => y._id === x.store_id)
              if (tmp !== undefined) {
                storeName = tmp.name
              } else {
                storeName = this.$t('employee_browsing.undefined')
              }
            }
            const ret = {
              _id: x._id,
              name: gfn(x, this.locale),
              store_name: storeName,
              employee_id: x.employee_id,
              nickname: x.nickname,
            }
            if (storeName === this.$t('employee_browsing.undefined')) {
              this.employeeInUndefiendStore.push(ret)
            }
            return ret
          })
        } else {
          const storeName = this.storeList.find(y => y._id === storeId).name
          this.displayedEmployeeList = this.employeeList.filter(x => x.store_id === storeId).map(x => {
            return {
              name: gfn(x, this.locale),
              store_name: storeName,
              employee_id: x.employee_id,
              nickname: x.nickname,
            }
          })
        }
      },
      getFullName (employeeProfile, language) {
        if (language === 'ja') {
          return employeeProfile.last_name + ' ' + employeeProfile.first_name
        } else {
          return employeeProfile.first_name + ' ' + employeeProfile.last_name
        }
      },
      setStoreData (data) {
        this.storeList = data
        this.storeList.sort(function (a, b) {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })
        // 追加
        if (this.isHeadOnly) {
          this.storeList.push({
            _id: ALL_STORE,
            name: this.$t('employee_browsing.all_store'),
          })
          this.storeList.push({
            _id: 'none',
            name: this.$t('employee_browsing.non_store'),
          })
          this.storeList.push({
            _id: UNDEFINED_STORE,
            name: this.$t('employee_browsing.undefined'),
          })
          this.selectedStore = ALL_STORE
        } else {
          this.selectedStore = this.storeList[0]._id
        }
      },
      editItem (item) {
        this.$router.push({
          name: 'Employee Update',
          params: {
            id: encodeURIComponent(item.employee_id),
          },
        })
      },
      showPasswordResetDialog (item) {
        this.selectedEmployee = item
        this.selectedEmployeeFullName = item.name
        this.resetErrors = []
        this.showDialog = true
      },
      resetPassword () {
        this.isResetting = true
        api.resetPassword(this.selectedEmployee.employee_id).then(res => {
          this.newPassword = res.data.new_password
          this.isResetting = false
          this.showDialog = false
          this.resultDialog = true
        }).catch(e => {
          console.log(e)
          if (e.code === 'ECONNABORTED') {
            this.resetErrors.push(this.$t('errors.api_timeout'))
          } else {
            this.resetErrors.push(this.$t('errors.unexpected'))
          }
          this.isResetting = false
        })
      },
    },
  }
</script>
<style scoped>
.line-break {
  white-space:pre-wrap;
  word-wrap:break-word;
}
.v-progress-circular {
  margin: 1rem;
}
.right-input {
  padding-top: 0px;
}
.right-input >>> input {
  text-align: right!important;
}
.v-picker.v-card {
  margin: 0px;
}
.top-card {
  margin-top: 0px;
  width: fit-content;
}
.margin-additional {
  margin-top: 28px;
}
.v-data-table >>> i.v-data-table-header__icon {
  color: white!important;
}
.v-data-table >>> th:first-child {
  border-radius: 4px 0px 0px 0px;
}
.v-data-table >>> th * {
  border-radius: 0px 0px 0px 0px;
  font-size: small;
}
.v-data-table >>> th:last-child {
  border-radius: 0px 4px 0px 0px;
}
.spacing-around {
  margin: 16px;
}

</style>
