import Vue from 'vue'
import Acl from 'vue-browser-acl'
import router from '../router.js'
// import axios from 'axios'

// example user from backend, you can provide a function
// instead in case the user retrieved asynchronously
// const user = () => store.auth.user

export const roles = {
  HEAD: 'manager',
  STORE_MANAGER: 'store_manager',
  STAFF_MEMBER: 'staff',
}

// TODO: ローカルにキャッシュされた情報を参照するようにする
export const user = () => ({
  userId: localStorage.getItem('userId'),
  role: localStorage.getItem('role'),
  storeId: localStorage.getItem('storeId'),
})

export function hasManagerRole () {
  return roles.HEAD === user().role
}

export function hasStoreManagerRole () {
  return roles.STORE_MANAGER === user().role
}

export function hasStaffRole () {
  return roles.STAFF_MEMBER === user().role
}

const menuList = [
  'health_check',
  'store_entry',
  'store_browsing',
  'store_browsing_self',
  'reservation_capacity_entry',
  'reservation_capacity_browsing',
  'shift_entry',
  'shift_browsing',
  'employee_entry',
  'employee_browsing',
  'employee_browsing_self',
  'customer_reservation_browsing',
  'customer_search',
  'dashboard_browsing',
  'dashboard_management',
  'reward_history',
]
const menuRoles = {
  [roles.HEAD]: [
    'health_check',
    'store_entry',
    'store_browsing',
    'reservation_capacity_entry',
    'reservation_capacity_browsing',
    'shift_entry',
    'shift_browsing',
    'employee_entry',
    'employee_browsing',
    'customer_reservation_browsing',
    'customer_search',
    'dashboard_browsing',
    'dashboard_management',
    'reward_history',
  ],
  [roles.STORE_MANAGER]: [
    'store_browsing',
    'reservation_capacity_entry',
    'reservation_capacity_browsing',
    'shift_entry',
    'shift_browsing',
    'employee_entry',
    'employee_browsing',
    'customer_reservation_browsing',
    'customer_search',
    'dashboard_browsing',
  ],
  [roles.STAFF_MEMBER]: [
    'store_browsing_self',
    'reservation_capacity_browsing',
    'shift_browsing',
    'employee_browsing_self',
    'customer_reservation_browsing',
    'customer_search',
    'dashboard_browsing',
  ],
}

const routingList = [
  'route.health_check',
  'route.password_change',
  'route.shift_entry',
  'route.shift_browsing',
  'route.reservation_capacity_entry',
  'route.reservation_capacity_browsing',
  'route.store_entry',
  'route.store_browsing',
  'route.employee_entry',
  'route.employee_browsing',
  'route.employee_update',
  'route.store_update',
  'route.customer_reservation_browsing',
  'route.customer_search',
  'route.customer_detail',
  'route.dashboard_browsing',
  'route.dashboard_management',
  'route.reward_history',
]
const routingRoles = {
  [roles.HEAD]: routingList,
  [roles.STORE_MANAGER]: [
    'route.password_change',
    'route.shift_entry',
    'route.shift_browsing',
    'route.reservation_capacity_entry',
    'route.reservation_capacity_browsing',
    'route.store_browsing',
    'route.employee_entry',
    'route.employee_browsing',
    'route.employee_update',
    'route.store_update',
    'route.customer_reservation_browsing',
    'route.customer_search',
    'route.customer_detail',
    'route.dashboard_browsing',
  ],
  [roles.STAFF_MEMBER]: [
    'route.password_change',
    'route.shift_browsing',
    'route.reservation_capacity_browsing',
    'route.store_update',
    'route.employee_update',
    'route.customer_reservation_browsing',
    'route.customer_search',
    'route.customer_detail',
    'route.dashboard_browsing',
  ],
}

const componentHandlingList = [
  'health_check',
  'component.update_store',
  'component.update_reservation_capacity',
  'component.update_customer_reservation',
  'component.selectable_head',
  'component.selectable_store_manager',
  'component.selectable_staff',
  'component.update_reservation_shift_other',
  'component.update_reservation_shift_self',
  'component.delete_customer_reservation',
  'component.delete_employee',
  'component.update_customer',
  'component.home_dashboard',
  'component.home_reservation',
  'component.employee_update_self',
  'component.employee_update',
]
const componentHandlingRoles = {
  [roles.HEAD]: componentHandlingList,
  [roles.STORE_MANAGER]: [
    'component.update_store',
    'component.update_reservation_capacity',
    'component.update_customer_reservation',
    'component.selectable_store_manager',
    'component.selectable_staff',
    'component.update_reservation_shift_other',
    'component.update_reservation_shift_self',
    'component.delete_customer_reservation',
    'component.delete_employee',
    'component.home_reservation',
    'component.employee_update_self',
    'component.employee_update',
  ],
  [roles.STAFF_MEMBER]: [
    'component.selectable_staff',
    'component.update_reservation_shift_self',
    'component.home_reservation',
    'component.employee_update_self',
  ],
}

function containRole (user, roleList) {
  return roleList.includes(user.role)
}

function genAddBasicRule (acl) {
  return function (ruleName, roleList) {
    acl.rule(ruleName, (user) => {
      return containRole(user, roleList)
    })
  }
}

function genRuleFromList (acl, roleList) {
  return function (actionName) {
    acl.rule(actionName, (user) => roleList[user.role].includes(actionName))
  }
}

Vue.use(Acl, user, (acl) => {
  const addRule = genAddBasicRule(acl)
  addRule('store_menu', [roles.HEAD, roles.STORE_MANAGER])
  // addRule('reservation_capacity_menu', [HEAD, STORE_MANAGER])
  // addRule('shift_menu', [HEAD, STORE_MANAGER, STAFF_MEMBER])
  // addRule('employee_menu', [HEAD, STORE_MANAGER])
  // addRule('over_store_manager', [HEAD, STORE_MANAGER])
  addRule('head', [roles.HEAD])
  // addRule('all', [HEAD, STORE_MANAGER, STAFF_MEMBER])
  acl.rule('all', (user) => {
    return true
  })
  // addRule('staff_member', [STAFF_MEMBER])
  const menuRule = genRuleFromList(acl, menuRoles)
  menuList.forEach(i => {
    menuRule(i)
  })
  const routingRule = genRuleFromList(acl, routingRoles)
  routingList.forEach(i => {
    routingRule(i)
  })
  const componentHandlingRole = genRuleFromList(acl, componentHandlingRoles)
  componentHandlingList.forEach(i => {
    componentHandlingRole(i)
  })
}, {
    router: router,
    failRoute: (to, from) => {
      router.replace({
        // 404ページに飛べればどこでも良い
        path: '/404',
      }).then(res => {
        // 404ページに移動した後にパスを書き換えて、移動していないかのように見せる
        window.history.replaceState(null, null, '#' + to.path)
      })
    },
  },
)

// axios.interceptors.request.use(request => {
//   console.log(request.url)
//   return request
// })

// const apiRoles = {
//   HEAD: [
//     'getMonthlyShift',
//     'entryReservationShift',
//     'getStoreStaffs',
//     'getAllEmployees',
//     'getEmployee',
//     'getShiftCategory',
//     'enterShiftCategory',
//     'updateShiftCategory',
//     'deleteShiftCategory',
//     'getStore',
//     'enterStoreInfo',
//     'enterEmployeeInfo',
//     'updateEmployeeInfo',
//     'updateStoreInfo',
//     'getReservationCapacityMonthly',
//     'enterReservationCapacity',
//     'changePassword',
//     'updateReservationCapacity',
//   ],
//   STORE_MANAGER: [
//     'getMonthlyShift',
//     'entryReservationShift',
//     'getStoreStaffs',
//     'getAllEmployees',
//     'getEmployee',
//     'getShiftCategory',
//     'enterShiftCategory',
//     'updateShiftCategory',
//     'deleteShiftCategory',
//     'getStore',
//     'enterEmployeeInfo',
//     'updateEmployeeInfo',
//     'updateStoreInfo',
//     'getReservationCapacityMonthly',
//     'enterReservationCapacity',
//     'changePassword',
//     'updateReservationCapacity',
//   ],
//   STAFF_MEMBER: [
//     'getMonthlyShift',
//     'entryReservationShift',
//     'getStoreStaffs',
//     'getEmployee',
//     'getShiftCategory',
//     'getStore',
//     'updateEmployeeInfo',
//     'getReservationCapacityMonthly',
//     'changePassword',
//     'updateReservationCapacity',
//   ],
// }
