<template>
  <v-container>
    <v-container
      id="grid"
      fluid
      tag="section"
    >
      <h2>{{ $t('customer_search.condition') }}</h2>
      <v-form
        ref="searchForm"
      >
        <v-expansion-panels
          v-model="panel"
          multiple
        >
          <v-expansion-panel>
            <v-expansion-panel-header>会員属性</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-text-field
                    v-model="shortCustomerId"
                    type="text"
                    :label="$t('customer_search.placeholder.short_customer_id')"
                    :disabled="isProcessing"
                    prepend-icon="mdi-card-account-details"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-text-field
                    v-model="mailAddress"
                    type="text"
                    :label="$t('customer_search.placeholder.mail_address')"
                    :disabled="isProcessing"
                    prepend-icon="mdi-email"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-text-field
                    v-model="lastName"
                    type="text"
                    :label="$t('customer_search.placeholder.last_name')"
                    :disabled="isProcessing"
                    prepend-icon="mdi-account"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-text-field
                    v-model="firstName"
                    type="text"
                    :label="$t('customer_search.placeholder.first_name')"
                    :disabled="isProcessing"
                    prepend-icon="mdi-account"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-select
                    v-model="gender"
                    :items="genderList"
                    item-text="label"
                    item-value="value"
                    :label="$t('customer_search.placeholder.gender')"
                    :disabled="isProcessing"
                    prepend-icon="mdi-human-male-female"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-text-field
                    v-model="phoneNumber"
                    type="text"
                    :label="$t('customer_search.placeholder.phone_number')"
                    :disabled="isProcessing"
                    prepend-icon="mdi-phone"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-text-field
                    v-model="address"
                    type="text"
                    :label="$t('customer_search.placeholder.address')"
                    :disabled="isProcessing"
                    prepend-icon="mdi-map-marker-radius"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-select
                    v-model="selectedStatusRank"
                    :items="statusRankList"
                    item-text="name"
                    item-value="_id"
                    :label="$t('customer_search.placeholder.status_rank')"
                    :disabled="isProcessing"
                    prepend-icon="mdi-chevron-triple-up"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-select
                    v-model="selectedRegistrationStatus"
                    :items="registrationStatusList"
                    item-text="name"
                    item-value="_id"
                    :label="$t('customer_search.placeholder.registration_status')"
                    :disabled="isProcessing"
                    prepend-icon="mdi-account-details"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-text-field
                    v-model="memo"
                    type="text"
                    :label="$t('customer_search.placeholder.memo')"
                    :disabled="isProcessing"
                    prepend-icon="mdi-note-search"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-menu
                    v-model="birthDateFromMenu"
                    offset-y
                    :close-on-content-click="false"
                    max-width="auto"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="formattedBirthDateFrom"
                        :label="$t('customer_search.placeholder.birth_date_from')"
                        :rules="validateBirthDateRule"
                        :disabled="isProcessing"
                        readonly
                        clearable
                        prepend-icon="mdi-calendar"
                        v-on="on"
                        @click:clear="birthDateFrom = null"
                      />
                    </template>
                    <v-date-picker
                      v-model="birthDateFrom"
                      :locale="locale"
                      :disabled="isProcessing"
                      :day-format="date => new Date(date).getDate()"
                      :width="datePickerWidth"
                      @click="birthDateFromMenu = false"
                    />
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-menu
                    v-model="birthDateToMenu"
                    offset-y
                    :close-on-content-click="false"
                    max-width="auto"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="formattedBirthDateTo"
                        :label="$t('customer_search.placeholder.birth_date_to')"
                        :rules="validateBirthDateRule"
                        :disabled="isProcessing"
                        readonly
                        clearable
                        prepend-icon="mdi-calendar"
                        v-on="on"
                        @click:clear="birthDateTo = null"
                      />
                    </template>
                    <v-date-picker
                      v-model="birthDateTo"
                      :locale="locale"
                      :disabled="isProcessing"
                      :day-format="date => new Date(date).getDate()"
                      :width="datePickerWidth"
                      @click="birthDateToMenu = false"
                    />
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-menu
                    v-model="weddingDateFromMenu"
                    offset-y
                    :close-on-content-click="false"
                    max-width="auto"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="formattedWeddingDateFrom"
                        :label="$t('customer_search.placeholder.wedding_date_from')"
                        :rules="validateWeddingDateRule"
                        :disabled="isProcessing"
                        readonly
                        clearable
                        prepend-icon="mdi-calendar"
                        v-on="on"
                        @click:clear="weddingDateFrom = null"
                      />
                    </template>
                    <v-date-picker
                      v-model="weddingDateFrom"
                      :locale="locale"
                      :disabled="isProcessing"
                      :day-format="date => new Date(date).getDate()"
                      :width="datePickerWidth"
                      @click="weddingDateFromMenu = false"
                    />
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-menu
                    v-model="weddingDateToMenu"
                    offset-y
                    :close-on-content-click="false"
                    max-width="auto"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="formattedWeddingDateTo"
                        :label="$t('customer_search.placeholder.wedding_date_to')"
                        :rules="validateWeddingDateRule"
                        :disabled="isProcessing"
                        readonly
                        clearable
                        prepend-icon="mdi-calendar"
                        v-on="on"
                        @click:clear="weddingDateTo = null"
                      />
                    </template>
                    <v-date-picker
                      v-model="weddingDateTo"
                      :locale="locale"
                      :disabled="isProcessing"
                      :day-format="date => new Date(date).getDate()"
                      :width="datePickerWidth"
                      @click="weddingDateToMenu = false"
                    />
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-menu
                    v-model="familyBirthdayFromMenu"
                    offset-y
                    :close-on-content-click="false"
                    max-width="auto"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="formattedFamilyBirthdayFrom"
                        :label="$t('customer_search.placeholder.family_birthday_from')"
                        :rules="validateFamilyBirthdayRule"
                        :disabled="isProcessing"
                        readonly
                        clearable
                        prepend-icon="mdi-calendar"
                        v-on="on"
                        @click:clear="familyBirthdayFrom = null"
                      />
                    </template>
                    <v-date-picker
                      v-model="familyBirthdayFrom"
                      :locale="locale"
                      :disabled="isProcessing"
                      :day-format="date => new Date(date).getDate()"
                      :width="datePickerWidth"
                      @click="familyBirthdayFromMenu = false"
                    />
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-menu
                    v-model="familyBirthdayToMenu"
                    offset-y
                    :close-on-content-click="false"
                    max-width="auto"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="formattedFamilyBirthdayTo"
                        :label="$t('customer_search.placeholder.family_birthday_to')"
                        :rules="validateFamilyBirthdayRule"
                        :disabled="isProcessing"
                        readonly
                        clearable
                        prepend-icon="mdi-calendar"
                        v-on="on"
                        @click:clear="familyBirthdayTo = null"
                      />
                    </template>
                    <v-date-picker
                      v-model="familyBirthdayTo"
                      :locale="locale"
                      :disabled="isProcessing"
                      :day-format="date => new Date(date).getDate()"
                      :width="datePickerWidth"
                      @click="familyBirthdayToMenu = false"
                    />
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-menu
                    v-model="weddingAnniversaryFromMenu"
                    offset-y
                    :close-on-content-click="false"
                    max-width="auto"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="formattedWeddingAnniversaryFrom"
                        :label="$t('customer_search.placeholder.wedding_anniversary_from')"
                        :rules="validateWeddingAnniversaryRule"
                        :disabled="isProcessing"
                        readonly
                        clearable
                        prepend-icon="mdi-calendar"
                        v-on="on"
                        @click:clear="weddingAnniversaryFrom = null"
                      />
                    </template>
                    <v-date-picker
                      v-model="weddingAnniversaryFrom"
                      :locale="locale"
                      :disabled="isProcessing"
                      :day-format="date => new Date(date).getDate()"
                      :width="datePickerWidth"
                      @click="weddingAnniversaryFromMenu = false"
                    />
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-menu
                    v-model="weddingAnniversaryToMenu"
                    offset-y
                    :close-on-content-click="false"
                    max-width="auto"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="formattedWeddingAnniversaryTo"
                        :label="$t('customer_search.placeholder.wedding_anniversary_to')"
                        :rules="validateWeddingAnniversaryRule"
                        :disabled="isProcessing"
                        readonly
                        clearable
                        prepend-icon="mdi-calendar"
                        v-on="on"
                        @click:clear="weddingAnniversaryTo = null"
                      />
                    </template>
                    <v-date-picker
                      v-model="weddingAnniversaryTo"
                      :locale="locale"
                      :disabled="isProcessing"
                      :day-format="date => new Date(date).getDate()"
                      :width="datePickerWidth"
                      @click="weddingAnniversaryToMenu = false"
                    />
                  </v-menu>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>利用回数（店舗別・年度別）</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="venueId"
                    :items="venuesList"
                    item-text="venue_title"
                    item-value="venue_id"
                    :label="$t('customer_search.placeholder.venue')"
                    :disabled="isProcessing"
                    prepend-icon="mdi-store"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-select
                    v-model="usageYearFrom"
                    :items="usageYearList"
                    :label="$t('customer_search.placeholder.usage_year_from')"
                    :rules="validateUsageYearRule"
                    :disabled="isProcessing"
                    prepend-icon="mdi-calendar"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-select
                    v-model="usageYearTo"
                    :items="usageYearList"
                    :label="$t('customer_search.placeholder.usage_year_to')"
                    :rules="validateUsageYearRule"
                    :disabled="isProcessing"
                    prepend-icon="mdi-calendar"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-text-field
                    v-model="usageCountFrom"
                    type="number"
                    max="999"
                    min="0"
                    :label="$t('customer_search.placeholder.usage_count_from')"
                    :rules="validateUsageCountRule"
                    :disabled="isProcessing"
                    prepend-icon="mdi-counter"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-text-field
                    v-model="usageCountTo"
                    type="number"
                    max="999"
                    min="0"
                    :label="$t('customer_search.placeholder.usage_count_to')"
                    :rules="validateUsageCountRule"
                    :disabled="isProcessing"
                    prepend-icon="mdi-counter"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>利用実績</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-text-field
                    v-model="startPointsPerYear"
                    type="number"
                    max="999"
                    min="0"
                    :label="$t('customer_search.placeholder.points_per_fiscal_year_from')"
                    :disabled="isProcessing"
                    prepend-icon="mdi-hand-coin-outline"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-text-field
                    v-model="endPointsPerYear"
                    type="number"
                    max="999"
                    min="0"
                    :label="$t('customer_search.placeholder.points_per_fiscal_year_to')"
                    :disabled="isProcessing"
                    prepend-icon="mdi-hand-coin-outline"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-text-field
                    v-model="pointsBalanceFrom"
                    type="number"
                    max="9999"
                    min="0"
                    :label="$t('customer_search.placeholder.points_balance_from')"
                    :disabled="isProcessing"
                    prepend-icon="mdi-star-four-points-circle"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-text-field
                    v-model="pointsBalanceTo"
                    type="number"
                    max="9999"
                    min="0"
                    :label="$t('customer_search.placeholder.points_balance_to')"
                    :disabled="isProcessing"
                    prepend-icon="mdi-star-four-points-circle"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-select
                    v-model="selectedLengthTime"
                    :items="lengthTimeList"
                    item-text="name"
                    item-value="_id"
                    :label="$t('customer_search.placeholder.final_use_length_time')"
                    :disabled="isProcessing"
                    prepend-icon="mdi-sun-clock"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-menu
                    v-model="pointExpireDateMenu"
                    offset-y
                    :close-on-content-click="false"
                    max-width="auto"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="formattedPointExpireDate"
                        :label="$t('customer_search.placeholder.point_expire_date')"
                        :disabled="isProcessing"
                        readonly
                        clearable
                        prepend-icon="mdi-calendar"
                        v-on="on"
                        @click:clear="pointExpireDate = null"
                      />
                    </template>
                    <v-date-picker
                      v-model="pointExpireDate"
                      :locale="locale"
                      :disabled="isProcessing"
                      :day-format="date => new Date(date).getDate()"
                      :width="datePickerWidth"
                      @click="pointExpireDateMenu = false"
                    />
                  </v-menu>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-row
          justify="end"
          class="mt-8"
        >
          <v-progress-circular
            v-if="isProcessing"
            indeterminate
            color="primary"
            :size="30"
          />
          <v-btn
            color="primary"
            :disabled="isProcessing || !isInputValid()"
            @click="search"
          >
            {{ $t('customer_search.search_button') }}
          </v-btn>
          <v-btn
            v-if="canDownload"
            color="primary"
            :disabled="isProcessing || !isInputValid()"
            @click="download"
          >
            {{ $t('customer_search.download_button') }}
          </v-btn>
          <v-btn
            v-if="canDownload"
            color="green"
            :disabled="isProcessing || !isInputValid()"
            @click="lineDownload"
          >
            {{ $t('customer_search.line_download_button') }}
          </v-btn>
        </v-row>
      </v-form>
      <div
        v-if="isDoneLineDownload"
        class="line-link-section"
      >
        <v-btn
          color="green"
          @click="toLineDashboard"
        >
          {{ $t('customer_search.to_line_dashboard_button') }}
        </v-btn>
        <p class="line-caution">
          ※メッセージ配信には料金がかかる可能性があります。詳しくは運営事務局にお問合せください。
        </p>
      </div>
      <hr>
      <material-alert
        v-for="error in errors"
        :key="error"
        color="error"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ error }}
      </material-alert>

      <material-alert
        v-if="isZeroCustomer"
        color="error"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ $t('customer_search.errors.zero_customer') }}
      </material-alert>

      <div v-if="!isZeroCustomer">
        <p>
          <strong>{{ $t('customer_search.search_result') }}</strong>
          ： {{ customerList.length }}
          {{ $t('customer_search.search_result_unit') }}
        </p>
      </div>
      <div v-if="isSearchCompleted && !isZeroCustomer">
        <v-data-table
          :headers="headers"
          :items="customerList"
          :items-per-page="10"
          item-key="customer_id"
          sort-by="kana_last_name"
          :sort-desc="false"
          class="elevation-1"
        >
          <template v-slot:item.store="{ item }">
            <div style="white-space: pre;">
              {{ item.store }}
            </div>
          </template>
          <template
            v-slot:item.detail="{ item }"
          >
            <v-icon
              small
              class="mr-2"
              @click="showDetail(item)"
            >
              mdi-account-details
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </v-container>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import * as api from '../api.js'
  import { hasManagerRole } from '../plugins/acl.js'
  import MaterialAlert from '../components/atoms/MaterialAlert.vue'
  import {
    STATUS_RANK_LIST,
    FINAL_USE_LENGTH_TIME_LIST,
    CUSTOMER_REGISTRATION_STATUS_LIST,
  } from '../config/constants.js'

  // 利用年度プルダウンの開始年度
  const START_YEAR = 2022

  export default {
    name: 'CustomerSearch',
    components: {
      MaterialAlert,
    },
    beforeRouteEnter (to, from, next) {
      const fromList = ['Customer Detail', 'Customer Reservation History']
      if (fromList.includes(from.name) && to.name === 'Customer Search') {
        // 顧客検索->顧客詳細に遷移してから戻ってきた時、検索条件と結果をセットする
        next(vm => {
          const condition = vm.$store.state.customerSearchCondition
          const customerSearchResult = vm.$store.state.customerSearchResult

          // URL直打ち等での詳細画面アクセス->強制リダイレクトでキャッシュが保存されていないケースを弾く
          if (condition !== null && customerSearchResult !== null) {
            vm.firstName = condition.first_name
            vm.lastName = condition.last_name
            vm.selectedStore = condition.store_id
            vm.phoneNumber = condition.phone_number
            vm.mailAddress = condition.mail_address
            vm.gender = condition.gender
            vm.address = condition.address
            vm.birthDateFrom = condition.birth_date_from
            vm.birthDateTo = condition.birth_date_to
            vm.weddingDateFrom = condition.wedding_date_from
            vm.weddingDateTo = condition.wedding_date_to
            vm.familyBirthdayFrom = condition.family_birthday_from
            vm.familyBirthdayTo = condition.family_birthday_to
            vm.weddingAnniversaryFrom = condition.wedding_anniversary_from
            vm.weddingAnniversaryTo = condition.wedding_anniversary_to
            vm.venueId = condition.venue_id
            vm.usageYearFrom = condition.usage_year_from
            vm.usageYearTo = condition.usage_year_to
            vm.usageCountFrom = condition.usage_count_from
            vm.usageCountTo = condition.usage_count_to
            vm.pointExpireDate = condition.point_expire_date
            vm.startPointsPerYear = condition.start_points_per_year
            vm.endPointsPerYear = condition.end_points_per_year
            vm.selectedLengthTime = condition.final_use_length_time
            vm.selectedStatusRank = condition.status_rank
            vm.memo = condition.memo
            vm.pointsBalanceFrom = condition.points_balance_from
            vm.pointsBalanceTo = condition.points_balance_to
            vm.selectedRegistrationStatus = condition.registration_status
            vm.customerList = customerSearchResult
            // 顧客情報削除後にここに遷移する際に、キャッシュに削除した顧客が含まれていればそれを取り除く。
            if (vm.$route.params.deleted_id) {
              vm.customerList = vm.customerList.filter(c => c.customer_id !== vm.$route.params.deleted_id)
            }

            // キャッシュはクリアしておく
            vm.$store.state.customerSearchResult = null
            vm.$store.state.customerSearchCondition = null
            vm.isSearchCompleted = true
          }
        })
      } else {
        // 顧客詳細以外のページからアクセスされた時、キャッシュの削除だけやる
        next(vm => {
          vm.$store.state.customerSearchResult = null
          vm.$store.state.customerSearchCondition = null
          vm.isSearchCompleted = false
        })
      }
    },
    data () {
      return {
        errors: [],
        isProcessing: false,
        isZeroCustomer: false,
        isSearchCompleted: false,
        isHeadOnly: false,
        shortCustomerId: '',
        lastName: '',
        firstName: '',
        selectedStore: '',
        phoneNumber: '',
        mailAddress: '',
        gender: '',
        genderList: [],
        address: '',
        venueId: '',
        venuesList: [],
        usageYearList: [],
        customerList: [],
        statusRankList: STATUS_RANK_LIST,
        registrationStatusList: CUSTOMER_REGISTRATION_STATUS_LIST,
        lengthTimeList: FINAL_USE_LENGTH_TIME_LIST,
        locale: 'en',
        headers: [
          { text: this.$t('customer_search.header.detail'), value: 'detail', width: '5%', class: 'primary white--text', sortable: false },
          {
            text: this.$t('customer_search.header.short_customer_id'),
            align: 'start',
            sortable: true,
            value: 'short_customer_id',
            width: '11%',
            class: 'primary white--text',
          },
          {
            text: this.$t('customer_search.header.kana_last_name'),
            align: 'start',
            sortable: true,
            value: 'profile.lastname_kana',
            width: '8%',
            class: 'primary white--text',
          },
          {
            text: this.$t('customer_search.header.kana_first_name'),
            sortable: true,
            value: 'profile.firstname_kana',
            width: '8%',
            class: 'primary white--text',
          },
          {
            text: this.$t('customer_search.header.last_name'),
            align: 'start',
            sortable: false,
            value: 'profile.lastname',
            width: '5%',
            class: 'primary white--text',
          },
          {
            text: this.$t('customer_search.header.first_name'),
            sortable: false,
            value: 'profile.firstname',
            width: '5%',
            class: 'primary white--text',
          },
          { text: this.$t('customer_search.header.gender'), value: 'profile.gender', width: '4%', class: 'primary white--text', sortable: false },
          { text: this.$t('customer_search.header.phone_number'), value: 'profile.phonenumber', width: '8%', class: 'primary white--text', sortable: false },
          { text: this.$t('customer_search.header.mail_address'), value: 'profile.email', width: '17%', class: 'primary white--text', sortable: false },
          { text: this.$t('customer_search.header.birth_date'), value: 'profile.birth_date', width: '10%', class: 'primary white--text', sortable: false },
          { text: this.$t('customer_search.header.address'), value: 'profile.address', width: '20%', class: 'primary white--text', sortable: false },
          { text: this.$t('customer_search.header.status_rank'), value: 'kinchaku_data.tier', width: '5%', class: 'primary white--text', sortable: false },
          { text: this.$t('customer_search.header.registration_status'), value: 'registration_status', width: '5%', class: 'primary white--text', sortable: false },
          { text: this.$t('customer_search.header.wedding_date'), value: 'profile.wedding_date', width: '5%', class: 'primary white--text', sortable: false },
          { text: this.$t('customer_search.header.uses_per_year'), value: 'property.customer.uses_per_year', width: '5%', class: 'primary white--text', sortable: false },
          { text: this.$t('customer_search.header.final_use_length_time'), value: 'property.customer.final_use_length_time', width: '5%', class: 'primary white--text', sortable: false },
          { text: this.$t('customer_search.header.points_per_year'), value: 'kinchaku_data.value_year_total', width: '5%', class: 'primary white--text', sortable: false },
          { text: this.$t('customer_search.header.points_balance'), value: 'kinchaku_data.value_sum', width: '5%', class: 'primary white--text', sortable: false },
        ],
        birthDateFrom: null,
        birthDateTo: null,
        formattedBirthDateFrom: null,
        formattedBirthDateTo: null,
        birthDateFromMenu: false,
        birthDateToMenu: false,
        validateBirthDateRule: [
          v => this.validateBirthDate() || this.$t('customer_search.errors.invalid_date'),
        ],
        weddingDateFrom: null,
        weddingDateTo: null,
        formattedWeddingDateFrom: null,
        formattedWeddingDateTo: null,
        weddingDateFromMenu: false,
        weddingDateToMenu: false,
        validateWeddingDateRule: [
          v => this.validateWeddingDate() || this.$t('customer_search.errors.invalid_date'),
        ],
        familyBirthdayFrom: null,
        familyBirthdayTo: null,
        formattedFamilyBirthdayFrom: null,
        formattedFamilyBirthdayTo: null,
        familyBirthdayFromMenu: false,
        familyBirthdayToMenu: false,
        validateFamilyBirthdayRule: [
          v => this.validateFamilyBirthday() || this.$t('customer_search.errors.invalid_date'),
        ],
        weddingAnniversaryFrom: null,
        weddingAnniversaryTo: null,
        formattedWeddingAnniversaryFrom: null,
        formattedWeddingAnniversaryTo: null,
        weddingAnniversaryFromMenu: false,
        weddingAnniversaryToMenu: false,
        validateWeddingAnniversaryRule: [
          v => this.validateWeddingAnniversary() || this.$t('customer_search.errors.invalid_date'),
        ],
        usageYearFrom: '',
        usageYearTo: '',
        validateUsageYearRule: [
          v => this.validateUsageYear() || this.$t('customer_search.errors.invalid_year'),
        ],
        usageCountFrom: '',
        usageCountTo: '',
        validateUsageCountRule: [
          v => this.validateUsageCount() || this.$t('customer_search.errors.invalid_usage_count'),
        ],
        pointExpireDate: null,
        formattedPointExpireDate: null,
        pointExpireDateMenu: false,
        startPointsPerYear: '',
        endPointsPerYear: '',
        selectedLengthTime: '',
        selectedStatusRank: '',
        memo: '',
        selectedRegistrationStatus: '',
        pointsBalanceFrom: '',
        pointsBalanceTo: '',
        isMounted: false,
        isDoneLineDownload: false,
        panel: [0, 1, 2],
        datePickerWidth: '220',
      }
    },
    computed: {
      canDownload () {
        return hasManagerRole()
      },
    },
    watch: {
      birthDateFrom: function (n, o) {
        this.formattedBirthDateFrom = moment(this.birthDateFrom).format('YYYY/MM/DD')
        // 初回アクセス時以外はbirthDateToのバリデーションをかけなおす
        if (this.isMounted) {
          this.$refs.searchForm.validate()
        }
      },
      birthDateTo: function (n, o) {
        this.formattedBirthDateTo = moment(this.birthDateTo).format('YYYY/MM/DD')
        // 初回アクセス時以外はbirthDateFromのバリデーションをかけなおす
        if (this.isMounted) {
          this.$refs.searchForm.validate()
        }
      },
      weddingDateFrom: function (n, o) {
        this.formattedWeddingDateFrom = moment(this.weddingDateFrom).format('YYYY/MM/DD')
        // 初回アクセス時以外はweddingDateToのバリデーションをかけなおす
        if (this.isMounted) {
          this.$refs.searchForm.validate()
        }
      },
      weddingDateTo: function (n, o) {
        this.formattedWeddingDateTo = moment(this.weddingDateTo).format('YYYY/MM/DD')
        // 初回アクセス時以外はweddingDateFromのバリデーションをかけなおす
        if (this.isMounted) {
          this.$refs.searchForm.validate()
        }
      },
      familyBirthdayFrom: function (n, o) {
        this.formattedFamilyBirthdayFrom = moment(this.familyBirthdayFrom).format('YYYY/MM/DD')
        // 初回アクセス時以外はfamilyBirthdayToのバリデーションをかけなおす
        if (this.isMounted) {
          this.$refs.searchForm.validate()
        }
      },
      familyBirthdayTo: function (n, o) {
        this.formattedFamilyBirthdayTo = moment(this.familyBirthdayTo).format('YYYY/MM/DD')
        // 初回アクセス時以外はfamilyBirthdayFromのバリデーションをかけなおす
        if (this.isMounted) {
          this.$refs.searchForm.validate()
        }
      },
      weddingAnniversaryFrom: function (n, o) {
        this.formattedWeddingAnniversaryFrom = moment(this.weddingAnniversaryFrom).format('YYYY/MM/DD')
        // 初回アクセス時以外はweddingAnniversaryToのバリデーションをかけなおす
        if (this.isMounted) {
          this.$refs.searchForm.validate()
        }
      },
      weddingAnniversaryTo: function (n, o) {
        this.formattedWeddingAnniversaryTo = moment(this.weddingAnniversaryTo).format('YYYY/MM/DD')
        // 初回アクセス時以外はweddingAnniversaryFromのバリデーションをかけなおす
        if (this.isMounted) {
          this.$refs.searchForm.validate()
        }
      },
      pointExpireDate: function (n, o) {
        this.formattedPointExpireDate = moment(this.pointExpireDate).format('YYYY/MM/DD')
        // 初回アクセス時以外はpointExpireDateのバリデーションをかけなおす
        if (this.isMounted) {
          this.$refs.searchForm.validate()
        }
      },
    },
    mounted () {
      this.venuesList = []
      this.genderList = [
        {
          value: '',
          label: '',
        },
        {
          value: this.$t('customer_search.gender.male'),
          label: this.$t('customer_search.gender.male'),
        },
        {
          value: this.$t('customer_search.gender.female'),
          label: this.$t('customer_search.gender.female'),
        },
        {
          value: this.$t('customer_search.gender.other'),
          label: this.$t('customer_search.gender.other'),
        },
      ]
      this.usageYearList = []
      this.locale = process.env.VUE_APP_I18N_LOCALE
      moment.locale(this.locale)

      if (this.$can('component.selectable_head')) {
        this.isHeadOnly = true
      }

      // 店舗一覧をAPIから取得
      api.getVenuesList()
        .then(res => {
          this.venuesList = res.data
          this.venuesList.unshift({
            venue_id: '',
            venue_title: '',
          })
        })
        .catch(e => console.log(e))

      // 利用年度プルダウン選択肢として、2022年度から現在年度の配列を作る
      const today = new Date()
      let nowYear = today.getFullYear()
      const nowMonth = today.getMonth() + 1
      if (nowMonth < 4) --nowYear
      for (let i = START_YEAR; i <= nowYear; i++) this.usageYearList.push(i)
      this.usageYearList.unshift('')
    },
    methods: {
      searchCustomer () {
        const requestBody = {
          first_name: this.firstName,
          last_name: this.lastName,
          store_id: this.selectedStore,
          phone_number: this.phoneNumber,
          mail_address: this.mailAddress,
          gender: this.gender,
          address: this.address,
          birth_date_from: this.birthDateFrom === null ? '' : this.birthDateFrom,
          birth_date_to: this.birthDateTo === null ? '' : this.birthDateTo,
          wedding_date_from: this.weddingDateFrom === null ? '' : this.weddingDateFrom,
          wedding_date_to: this.weddingDateTo === null ? '' : this.weddingDateTo,
          family_birthday_from: this.familyBirthdayFrom === null ? '' : this.familyBirthdayFrom,
          family_birthday_to: this.familyBirthdayTo === null ? '' : this.familyBirthdayTo,
          wedding_anniversary_from: this.weddingAnniversaryFrom === null ? '' : this.weddingAnniversaryFrom,
          wedding_anniversary_to: this.weddingAnniversaryTo === null ? '' : this.weddingAnniversaryTo,
          short_customer_id: this.shortCustomerId,
          venue_id: this.venueId,
          usage_year_from: this.usageYearFrom,
          usage_year_to: this.usageYearTo,
          usage_count_from: this.usageCountFrom,
          usage_count_to: this.usageCountTo,
          point_expire_date: this.pointExpireDate === null ? '' : this.pointExpireDate,
          start_points_per_year: this.startPointsPerYear,
          end_points_per_year: this.endPointsPerYear,
          final_use_length_time: this.selectedLengthTime,
          status_rank: this.selectedStatusRank,
          memo: this.memo,
          registration_status: this.selectedRegistrationStatus,
          point_value_sum_from: this.pointsBalanceFrom,
          point_value_sum_to: this.pointsBalanceTo,
        }
        api.searchCustomer(requestBody).then(res => {
          this.customerList = res.data
          this.isZeroCustomer = this.customerList.length === 0
          this.isSearchCompleted = true
        }).catch(e => {
          this.customerList = []
          this.isSearchCompleted = false
          if (e.code === 'ECONNABORTED') {
            this.errors.push(this.$t('errors.api_timeout'))
          } else {
            this.errors.push(this.$t('errors.unexpected'))
          }
        }).finally(() => {
          this.isProcessing = false
        })
      },
      search () {
        this.isProcessing = true
        this.isSearchCompleted = false
        try {
          this.searchCustomer()
        } catch (e) {
          console.log(e)
        }
      },
      async lineDownloadCustomer () {
        const requestBody = {
          first_name: this.firstName,
          last_name: this.lastName,
          store_id: this.selectedStore,
          phone_number: this.phoneNumber,
          mail_address: this.mailAddress,
          gender: this.gender,
          address: this.address,
          birth_date_from: this.birthDateFrom === null ? '' : this.birthDateFrom,
          birth_date_to: this.birthDateTo === null ? '' : this.birthDateTo,
          wedding_date_from: this.weddingDateFrom === null ? '' : this.weddingDateFrom,
          wedding_date_to: this.weddingDateTo === null ? '' : this.weddingDateTo,
          family_birthday_from: this.familyBirthdayFrom === null ? '' : this.familyBirthdayFrom,
          family_birthday_to: this.familyBirthdayTo === null ? '' : this.familyBirthdayTo,
          wedding_anniversary_from: this.weddingAnniversaryFrom === null ? '' : this.weddingAnniversaryFrom,
          wedding_anniversary_to: this.weddingAnniversaryTo === null ? '' : this.weddingAnniversaryTo,
          short_customer_id: this.shortCustomerId,
          venue_id: this.venueId,
          usage_year_from: this.usageYearFrom,
          usage_year_to: this.usageYearTo,
          usage_count_from: this.usageCountFrom,
          usage_count_to: this.usageCountTo,
          point_expire_date: this.pointExpireDate === null ? '' : this.pointExpireDate,
          start_points_per_year: this.startPointsPerYear,
          end_points_per_year: this.endPointsPerYear,
          final_use_length_time: this.selectedLengthTime,
          status_rank: this.selectedStatusRank,
          memo: this.memo,
          registration_status: this.selectedRegistrationStatus,
          point_value_sum_from: this.pointsBalanceFrom,
          point_value_sum_to: this.pointsBalanceTo,
        }
        try {
          const res = await api.lineDownloadCustomer(requestBody)
          const url = URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          const now = moment().format('YYYYMMDDHHmm')
          link.setAttribute('download', `line_audience_${now}.txt`)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(url)
        } catch (e) {
          console.log(e)
          if (e.code === 'ECONNABORTED') {
            this.errors.push(this.$t('errors.api_timeout'))
          } else {
            this.errors.push(this.$t('errors.unexpected'))
          }
        } finally {
          this.isProcessing = false
          this.isDoneLineDownload = true
        }
      },
      lineDownload () {
        this.isProcessing = true
        try {
          this.lineDownloadCustomer()
        } catch (e) {
          console.log(e)
        }
      },
      async downloadCustomer () {
        const requestBody = {
          first_name: this.firstName,
          last_name: this.lastName,
          store_id: this.selectedStore,
          phone_number: this.phoneNumber,
          mail_address: this.mailAddress,
          gender: this.gender,
          address: this.address,
          birth_date_from: this.birthDateFrom === null ? '' : this.birthDateFrom,
          birth_date_to: this.birthDateTo === null ? '' : this.birthDateTo,
          wedding_date_from: this.weddingDateFrom === null ? '' : this.weddingDateFrom,
          wedding_date_to: this.weddingDateTo === null ? '' : this.weddingDateTo,
          family_birthday_from: this.familyBirthdayFrom === null ? '' : this.familyBirthdayFrom,
          family_birthday_to: this.familyBirthdayTo === null ? '' : this.familyBirthdayTo,
          wedding_anniversary_from: this.weddingAnniversaryFrom === null ? '' : this.weddingAnniversaryFrom,
          wedding_anniversary_to: this.weddingAnniversaryTo === null ? '' : this.weddingAnniversaryTo,
          short_customer_id: this.shortCustomerId,
          start_uses_per_year: this.startUsesPerYear,
          venue_id: this.venueId,
          usage_year_from: this.usageYearFrom,
          usage_year_to: this.usageYearTo,
          usage_count_from: this.usageCountFrom,
          usage_count_to: this.usageCountTo,
          point_expire_date: this.pointExpireDate === null ? '' : this.pointExpireDate,
          end_points_per_year: this.endPointsPerYear,
          final_use_length_time: this.selectedLengthTime,
          status_rank: this.selectedStatusRank,
          memo: this.memo,
          registration_status: this.selectedRegistrationStatus,
          point_value_sum_from: this.pointsBalanceFrom,
          point_value_sum_to: this.pointsBalanceTo,
        }
        try {
          const res = await api.downloadCustomer(requestBody)
          const bom = new Uint8Array([0xEF, 0xBB, 0xBF])
          const url = URL.createObjectURL(new Blob([bom, res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'customers.csv')
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(url)
        } catch (e) {
          if (e.code === 'ECONNABORTED') {
            this.errors.push(this.$t('errors.api_timeout'))
          } else {
            this.errors.push(this.$t('errors.unexpected'))
          }
        } finally {
          this.isProcessing = false
        }
      },
      download () {
        this.isProcessing = true
        try {
          this.downloadCustomer()
        } catch (e) {
          console.log(e)
        }
      },
      showDetail (item) {
        // 検索条件と結果をキャッシュしておく
        this.$store.state.customerSearchResult = this.customerList
        this.$store.state.customerSearchCondition = {
          first_name: this.firstName,
          last_name: this.lastName,
          store_id: this.selectedStore,
          phone_number: this.phoneNumber,
          mail_address: this.mailAddress,
          gender: this.gender,
          address: this.address,
          birth_date_from: this.birthDateFrom,
          birth_date_to: this.birthDateTo,
          wedding_date_from: this.weddingDateFrom,
          wedding_date_to: this.weddingDateTo,
          family_birthday_from: this.familyBirthdayFrom,
          family_birthday_to: this.familyBirthdayTo,
          wedding_anniversary_from: this.weddingAnniversaryFrom,
          wedding_anniversary_to: this.weddingAnniversaryTo,
          venue_id: this.venueId,
          usage_year_from: this.usageYearFrom,
          usage_year_to: this.usageYearTo,
          usage_count_from: this.usageCountFrom,
          usage_count_to: this.usageCountTo,
          point_expire_date: this.pointExpireDate,
          start_points_per_year: this.startPointsPerYear,
          end_points_per_year: this.endPointsPerYear,
          final_use_length_time: this.selectedLengthTime,
          status_rank: this.selectedStatusRank,
          memo: this.memo,
          registration_status: this.selectedRegistrationStatus,
          point_value_sum_from: this.pointsBalanceFrom,
          point_value_sum_to: this.pointsBalanceTo,
        }
        this.$router.push({
          name: 'Customer Detail',
          params: { target: item },
        })
      },
      validateBirthDate () {
        return moment(this.birthDateTo).isSameOrAfter(this.birthDateFrom) ||
          this.birthDateFrom === null ||
          this.birthDateTo === null
      },
      validateWeddingDate () {
        return moment(this.weddingDateTo).isSameOrAfter(this.weddingDateFrom) ||
          this.weddingDateFrom === null ||
          this.weddingDateTo === null
      },
      validateFamilyBirthday () {
        return moment(this.familyBirthdayTo).isSameOrAfter(this.familyBirthdayFrom) ||
          this.familyBirthdayFrom === null ||
          this.familyBirthdayTo === null
      },
      validateWeddingAnniversary () {
        return moment(this.weddingAnniversaryTo).isSameOrAfter(this.weddingAnniversaryFrom) ||
          this.weddingAnniversaryFrom === null ||
          this.weddingAnniversaryTo === null
      },
      validateUsageYear () {
        return moment(this.usageYearTo).isSameOrAfter(this.usageYearFrom) ||
          this.usageYearFrom === '' ||
          this.usageYearTo === ''
      },
      validateUsageCount () {
        return moment(this.usageCountTo).isSameOrAfter(this.usageCountFrom) ||
          this.usageCountFrom === '' ||
          this.usageCountTo === ''
      },
      isInputValid () {
        return this.validateBirthDate() &&
          this.validateWeddingDate() &&
          this.validateFamilyBirthday() &&
          this.validateWeddingAnniversary() &&
          this.validateUsageYear() &&
          this.validateUsageCount()
      },
      toLineDashboard () {
        const url = 'https://manager.line.biz/account/@256wjpue'
        window.open(url, '_blank', 'noreferrer')
      },
    },
  }
</script>
<style scoped>
.line-break {
  white-space:pre-wrap;
  word-wrap:break-word;
}
.v-progress-circular {
  margin: 0.5rem;
}
.right-input {
  padding-top: 0px;
}
.right-input >>> input {
  text-align: right!important;
}
.v-picker.v-card {
  margin: 0px;
}
.top-card {
  margin-top: 0px;
  width: fit-content;
}
.margin-additional {
  margin-top: 28px;
}
.v-data-table {
  white-space: nowrap;
  overflow-x: auto;
}
.v-data-table >>> .v-data-table__wrapper {
  transform: rotateX(180deg);
}
.v-data-table >>> .v-data-table__wrapper::-webkit-scrollbar {
  height: 7px;/*バーの太さ*/
}
.v-data-table >>> .v-data-table__wrapper::-webkit-scrollbar-track {
  background:#fff;/*バーの背景色*/
}
.v-data-table >>> .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background:#9d9b9b;/*バーの色*/
}
.v-data-table >>> table {
  transform: rotateX(180deg);
}
.v-data-table >>> i.v-data-table-header__icon {
  color: white!important;
}
.v-data-table >>> th:first-child {
  border-radius: 4px 0px 0px 0px;
}
.v-data-table >>> th * {
  border-radius: 0px 0px 0px 0px;
  font-size: small;
}
.v-data-table >>> th:last-child {
  border-radius: 0px 4px 0px 0px;
}
hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.col-sm-4.col-12 {
  padding-top: 0;
  padding-bottom: 0;
}
.line-link-section {
  margin-top: 20px;
}
.line-caution {
  margin-top: 10px;
}

</style>
