<template>
  <v-app>
    <v-content>
      <router-view />
    </v-content>
    <v-content>
      <common-footer />
    </v-content>
  </v-app>
</template>

<script>
  import CommonFooter from './CommonFooter.vue'
  export default {
    name: 'SimpleLayout',
    components: {
      CommonFooter,
    },
  }
</script>

<style scoped>
.v-content {
  background-color: #ffffff !important;
  border: 0px solid !important
}
.theme--light .v-content {
  background-color: #fff
}
</style>
