<template>
  <v-container
    v-if="!inited"
    id="grid"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-progress-circular
        indeterminate
        color="primary"
        :size="100"
      />
    </v-row>
  </v-container>

  <v-container
    v-else
    id="grid"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        md="4"
        lg="4"
        xl="2"
      >
        <v-menu
          v-model="datePickerMenu"
          offset-y
          :close-on-content-click="false"
          max-width="auto"
          min-width="auto"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="formattedPickedDate"
              :label="$t('dashboard_management.date_picker_label')"
              readonly
              prepend-icon="mdi-calendar"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="pickedDate"
            type="month"
            :locale="locale"
            @click="datePickerMenu = false"
          />
        </v-menu>
      </v-col>
    </v-row>
    <material-alert
      v-for="error in errors"
      :key="error"
      color="error"
      dark
      dismissible
      icon="mdi-bell"
    >
      {{ error }}
    </material-alert>
    <v-row>
      <v-col
        cols="12"
        lg="8"
      >
        <bar-chart
          v-if="loaded"
          :chart-data="reservations_data"
          :options="reservations_options"
          :height="130"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        lg="8"
      >
        <bar-chart
          v-if="loaded"
          :chart-data="reservation_type_data"
          :options="reservation_type_options"
          :height="130"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import * as api from '../api.js'
  import BarChart from '../components/atoms/BarChart.vue'
  import MaterialAlert from '../components/atoms/MaterialAlert.vue'

  export default {
    name: 'DashboardManagement',
    components: {
      MaterialAlert,
      BarChart,
    },
    data: () => ({
      inited: false,
      selected: null,
      errors: [],
      isProcessing: false,
      loaded: false,
      dashboards: [],
      store_name_list: [],
      confirmed_reservations_list: [],
      cancel_reservations_list: [],
      visits_list: [],
      online_list: [],
      oneMonth: [],
      pickedDate: null,
      formattedPickedDate: '',
      datePickerMenu: false,
      locale: 'en',
      reservations: {},
      reservations_data: {},
      reservations_options: {},
      reservation_types: {},
      reservation_type_data: {},
      reservation_type_options: {},
    }),
    computed: {
      year: function () {
        return moment(this.pickedDate).format('YYYY')
      },
      month: function () {
        return moment(this.pickedDate).format('MM')
      },
    },
    watch: {
      pickedDate: function (n, o) {
        this.formattedPickedDate = this.pickedDate.replace('-', '/')
        // 初期表示ではmountedで更新する
        if (o !== null) {
          this.selecetedParamsCheckAndUpdate()
        }
      },
    },
    mounted () {
      this.locale = process.env.VUE_APP_I18N_LOCALE
      moment.locale(this.locale)

      if (this.$route.params.picked_date) {
        this.pickedDate = this.$route.params.picked_date
      } else {
        const now = moment()
        this.pickedDate = now.format('YYYY-MM')
      }
      this.selecetedParamsCheckAndUpdate()
      this.inited = true
    },
    methods: {
      getDashboards () {
        this.isProcessing = true
        this.dashboards = []

        api.getDashboard('', this.year, '0').then(res => {
          this.dashboards = res.data
          // 店舗のラベルを作成(グラフ共通)
          const storeList = Array.from(new Set(this.dashboards.map(x => x.store_name)))
          // 時系列変化
          let tmp
          let seriesList1
          let seriesList2
          let title
          let label1
          let label2
          // 予約確定数／予約キャンセル数
          title = this.$t('dashboard_management.title_reservation_cancel_count')
          label1 = this.$t('dashboard_management.title_confirmed_reservations_count')
          label2 = this.$t('dashboard_management.title_cancel_reservations_count')
          tmp = Array.from(new Set(this.dashboards.map(x => x.confirmed_reservations_count.series)))
          seriesList1 = this.getThisMonthValue(tmp, this.month, this.confirmed_reservations_list)
          tmp = Array.from(new Set(this.dashboards.map(x => x.cancel_reservations_count.series)))
          seriesList2 = this.getThisMonthValue(tmp, this.month, this.cancel_reservations_list)
          this.reservations = createBarChartJSObject(seriesList1, seriesList2, storeList, title, label1, label2, false, '#960636')
          this.reservations_data = this.reservations.data
          this.reservations_options = this.reservations.options

          // 来店数／オンライン接客数
          title = this.$t('dashboard_management.title_visits_online_count')
          label1 = this.$t('dashboard_management.title_visits_count')
          label2 = this.$t('dashboard_management.title_online_count')
          tmp = Array.from(new Set(this.dashboards.map(x => x.visits_count.series)))
          seriesList1 = this.getThisMonthValue(tmp, this.month, this.visits_list)
          tmp = Array.from(new Set(this.dashboards.map(x => x.online_count.series)))
          seriesList2 = this.getThisMonthValue(tmp, this.month, this.online_list)
          this.reservation_types = createBarChartJSObject(seriesList1, seriesList2, storeList, title, label1, label2, true, '#006400')
          this.reservation_type_data = this.reservation_types.data
          this.reservation_type_options = this.reservation_types.options

          this.isProcessing = false
          this.loaded = true
        }).catch(e => {
          if (e.response !== undefined && e.response.status === 404) {
            this.errors.push(this.$t('dashboard_management.errors.not_found'))
          } else if (e.code === 'ECONNABORTED') {
            this.errors.push(this.$t('errors.api_timeout'))
          } else {
            this.errors.push(this.$t('errors.unexpected'))
          }
          this.isProcessing = false
        })
      },
      selecetedParamsCheckAndUpdate () {
        if (this.pickedDate.search(/^\d{4}-\d{2}$/g) === 0) {
          this.getDashboards()
        } else {
          console.log('invalid date format')
        }
      },
      getThisMonthValue (tmp, month, valuelist) {
        let orglist
        const index = Number(month) - 1
        valuelist = []
        tmp.forEach((value, i) => {
          orglist = value
          valuelist.push(orglist[index])
        })
        return valuelist
      },
    },
  }
  // グラフ用のオブジェクトを返却する
  function createBarChartJSObject (seriesList1, seriesList2, storeList, title, label1, label2, stackFlg, color2) {
    const barChartJSObject = {
      data: {
        labels: [],
        datasets: [
          {
            label: [],
            data: [],
            backgroundColor: '#073088',
          },
          {
            label: [],
            data: [],
            backgroundColor: '',
          },
        ],
      },
      options: {
        responsive: true,
        title: {
          display: true,
          fontSize: 16,
          text: '',
        },
        legend: {
          position: 'bottom',
        },
        scales: {
        },
      },
    }
    barChartJSObject.data.labels = storeList
    barChartJSObject.data.datasets[0].data = seriesList1
    barChartJSObject.data.datasets[1].data = seriesList2
    barChartJSObject.data.datasets[0].label = label1
    barChartJSObject.data.datasets[1].label = label2
    barChartJSObject.data.datasets[1].backgroundColor = color2
    barChartJSObject.options.title.text = title
    if (stackFlg === true) {
      barChartJSObject.options.scales =
        {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
              ticks: {
                min: 0,
                suggestedMax: 30,
              },
            },
          ],
        }
    } else {
      barChartJSObject.options.scales =
        {
          yAxes: [
            {
              ticks: {
                min: 0,
                suggestedMax: 30,
              },
            },
          ],
        }
    }
    return barChartJSObject
  }
</script>
<style scoped>
.line-break {
  white-space:pre-wrap;
  word-wrap:break-word;
}
.v-progress-circular {
  margin: 1rem;
}
</style>
