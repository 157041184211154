<template>
  <v-container>
    <v-container
      v-show="isStoreProcessing || isCustomerProcessing || isEmployeeProcessing || isReservationProcessing"
      fluid
      tag="section"
    >
      <v-row justify="center">
        <v-progress-circular
          indeterminate
          color="primary"
          :size="100"
        />
      </v-row>
    </v-container>

    <v-container
      v-if="FailedGetStore"
      fluid
    >
      <material-alert
        color="error"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ $t('entry.errors.store_api') }}
      </material-alert>
    </v-container>

    <v-container
      id="grid"
      :class="{ hide: isStoreProcessing || isCustomerProcessing || isEmployeeProcessing || isReservationProcessing || FailedGetStore }"
      fluid
      tag="section"
    >
      <v-row v-if="!FailedGetStore">
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            v-model="selectedStore"
            :items="storeList"
            item-text="name"
            item-value="_id"
            :label="$t('customer_reservation_browsing.store_selector_label')"
            prepend-icon="mdi-map-marker-radius"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
          <v-menu
            v-model="datePickerMenu"
            offset-y
            :close-on-content-click="false"
            max-width="auto"
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="formattedPickedDate"
                :label="$t('customer_reservation_browsing.date_picker_label')"
                readonly
                prepend-icon="mdi-calendar"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="pickedDate"
              type="month"
              :locale="locale"
              @click="datePickerMenu = false"
            />
          </v-menu>
        </v-col>
      </v-row>

      <material-alert
        v-for="error in errors"
        :key="error"
        color="error"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ error }}
      </material-alert>

      <material-alert
        v-if="isUpdateSuccess"
        color="success"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ $t('customer_reservation_browsing.update_success') }}
      </material-alert>

      <material-alert
        v-if="isDeleteSuccess"
        color="success"
        dark
        dismissible
        icon="mdi-bell"
      >
        {{ $t('customer_reservation_browsing.delete_success') }}
      </material-alert>

      <v-card
        class="calendar"
        :class="{ hide: hideCalendar || FailedGetStore }"
      >
        <full-calendar
          ref="fullCalendar"
          :options="calendarOptions"
        />
      </v-card>
    </v-container>

    <!-- 予約詳細ダイアログ -->
    <v-dialog
      v-model="showContent"
      persistent
      max-width="800px"
    >
      <v-card
        v-if="showContent"
      >
        <v-card-title>
          <span class="text-h5">{{ $t('customer_reservation_browsing.detail.title') }}</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="detailForm"
            v-model="isFormValid"
          >
            <v-container>
              <v-row
                v-if="!isReadOnly && displayDetail.isEditableReservation"
                style="font-size: 12px;"
              >
                {{ $t("customer_reservation_browsing.detail.notation") }}
              </v-row>
              <v-row justify="end">
                <v-btn
                  color="blue-grey lighten-2"
                  small
                  :disabled="isUpdating"
                  @click="closeDialog"
                >
                  {{ $t('customer_reservation_browsing.detail.button.cancel') }}
                </v-btn>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="displayDetail.reservation._id"
                    :label="$t('customer_reservation_browsing.detail.reservation_id')"
                    type="text"
                    disabled
                  />
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col
                  cols="12"
                  sm="2"
                >
                  {{ $t("customer_reservation_browsing.detail.reservation_type.title") }}
                </v-col>
                <v-col
                  cols="12"
                  sm="10"
                >
                  <v-radio-group
                    v-model="displayDetail.reservation.reservation_type"
                    row
                  >
                    <v-radio
                      :label="$t('customer_reservation_browsing.detail.reservation_type.store')"
                      :value="'store'"
                      disabled
                    />
                    <v-radio
                      :label="$t('customer_reservation_browsing.detail.reservation_type.web')"
                      :value="'web'"
                      disabled
                    />
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-menu
                    v-model="dialogDatePickerMenu"
                    offset-y
                    :close-on-content-click="false"
                    max-width="auto"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="displayDetail.formattedPickedDate"
                        :label="$t('customer_reservation_browsing.detail.date_picker_label')"
                        :rules="reservationDateRule"
                        :disabled="isReadOnly || isUpdating || !displayDetail.isEditableReservation"
                        readonly
                        prepend-icon="mdi-calendar"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="dialogPickedDate"
                      :locale="locale"
                      :disabled="isReadOnly || isUpdating || !displayDetail.isEditableReservation"
                      :day-format="date => new Date(date).getDate()"
                      @click="dialogDatePickerMenu = false"
                    />
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-text-field
                    v-model="displayDetail.startTime"
                    :label="$t('customer_reservation_browsing.detail.start_time')"
                    type="time"
                    :rules="startTimeRule"
                    :disabled="isReadOnly || isUpdating || !displayDetail.isEditableReservation"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-text-field
                    v-model="displayDetail.endTime"
                    :label="$t('customer_reservation_browsing.detail.end_time')"
                    type="time"
                    :rules="endTimeRule"
                    :disabled="isReadOnly || isUpdating || !displayDetail.isEditableReservation"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-model="displayDetail.employeeId"
                    :items="displayDetail.employeeList"
                    item-text="fullname"
                    item-value="employee_id"
                    :label="$t('customer_reservation_browsing.detail.employee')"
                    prepend-icon="mdi-map-marker-radius"
                    required
                    :disabled="isReadOnly || isUpdating || !displayDetail.isEditableReservation"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="displayDetail.storeName"
                    :label="$t('customer_reservation_browsing.detail.store')"
                    prepend-icon="mdi-store"
                    disabled
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <a
                    :href="displayDetail.meetingUrl"
                    target="_blank"
                    rel="noopener"
                  >
                    <v-text-field
                      v-if="displayDetail.reservation.reservation_type === 'web'"
                      v-model="displayDetail.meetingUrl"
                      :label="$t('customer_reservation_browsing.detail.meeting_url')"
                      prepend-icon="mdi-video"
                      disabled
                    />
                  </a>
                </v-col>
              </v-row>
              <v-row justify="start">
                <v-col
                  cols="12"
                  sm="2"
                >
                  {{ $t('customer_reservation_browsing.detail.customer.title') }}
                </v-col>
                <!--
                <v-col
                  cols="12"
                  sm="10"
                >
                  <v-btn
                    color="blue-grey lighten-1"
                    x-small
                    :disabled="isUpdating"
                    @click="showCustomerDetail(displayDetail.customer)"
                  >
                    {{ $t('customer_reservation_browsing.detail.customer.button.detail') }}
                  </v-btn>
                  <v-btn
                    color="blue-grey lighten-1"
                    x-small
                    :disabled="isUpdating"
                    @click="closeDialog"
                  >
                    {{ $t('customer_reservation_browsing.detail.customer.button.history') }}
                  </v-btn>
                </v-col>
                -->
              </v-row>
              <div v-if="displayDetail.customer">
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="displayDetail.customer.profile.lastname"
                      type="text"
                      prepend-icon="mdi-account"
                      :label="$t('customer_reservation_browsing.detail.customer.last_name')"
                      disabled
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="displayDetail.customer.profile.firstname"
                      type="text"
                      :label="$t('customer_reservation_browsing.detail.customer.first_name')"
                      disabled
                      required
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="displayDetail.customer.profile.lastname_kana"
                      prepend-icon="mdi-account"
                      type="text"
                      :label="$t('customer_reservation_browsing.detail.customer.last_name_kana')"
                      required
                      disabled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="displayDetail.customer.profile.firstname_kana"
                      type="text"
                      :label="$t('customer_reservation_browsing.detail.customer.first_name_kana')"
                      required
                      disabled
                    />
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    {{ $t("customer_reservation_browsing.detail.customer.gender.title") }}
                  </v-col>
                  <v-col
                    cols="12"
                    sm="10"
                  >
                    <v-radio-group
                      v-model="displayDetail.customer.profile.gender"
                      row
                    >
                      <v-radio
                        :label="$t('customer_reservation_browsing.detail.customer.gender.male')"
                        :value="'男性'"
                        disabled
                      />
                      <v-radio
                        :label="$t('customer_reservation_browsing.detail.customer.gender.female')"
                        :value="'女性'"
                        disabled
                      />
                      <v-radio
                        :label="$t('customer_reservation_browsing.detail.customer.gender.other')"
                        :value="'その他'"
                        disabled
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <v-row>
                  <v-col>
                    {{ $t('customer_reservation_browsing.detail.deleted_customer_message') }}
                  </v-col>
                </v-row>
              </div>
              <v-row>
                <v-col
                  cols="12"
                  sm="2"
                >
                  {{ $t('customer_reservation_browsing.detail.extra_question') }}
                </v-col>
              </v-row>
              <v-row
                v-for="(value, key) in displayDetail.reservation.extra_question"
                :key="key"
                style="padding-left: 43px"
              >
                <v-text-field
                  v-model="displayDetail.reservation.extra_question[key]"
                  type="text"
                  :label="key"
                  disabled
                  required
                />
              </v-row>
            </v-container>
          </v-form>
          <material-alert
            v-for="error in errorsInUpdating"
            :key="error"
            color="error"
            dark
            dismissible
            icon="mdi-bell"
          >
            {{ error }}
          </material-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="isDeletable && displayDetail.isEditableReservation"
            color="red"
            :disabled="isUpdating"
            @click="openDeleteDialog"
          >
            {{ $t('customer_reservation_browsing.detail.button.delete') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="blue-grey lighten-2"
            :disabled="isUpdating"
            @click="closeDialog"
          >
            {{ $t('customer_reservation_browsing.detail.button.cancel') }}
          </v-btn>
          <v-btn
            v-if="!isReadOnly && displayDetail.isEditableReservation"
            color="primary"
            :loading="isUpdating"
            :disabled="isUpdating || !isFormValid"
            @click="updateAndCloseDialog"
          >
            {{ $t('customer_reservation_browsing.detail.button.update') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 削除確認ダイアログ -->
    <v-dialog
      v-model="showDeleteDialog"
      persistent
      max-width="800"
    >
      <v-card v-if="showDeleteDialog">
        <v-card-title>
          <span class="text-h5">{{ $t("customer_reservation_browsing.delete.title") }}</span>
        </v-card-title>

        <v-card-text class="line-break">
          <material-alert
            v-for="error in errorsInDeleting"
            :key="error"
            color="error"
            dark
            dismissible
            icon="mdi-bell"
          >
            {{ error }}
          </material-alert>
          {{ cautionMessage }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey lighten-2"
            :disabled="isDeleting"
            @click="closeDeleteDialog"
          >
            {{ $t('customer_reservation_browsing.delete.button.cancel') }}
          </v-btn>
          <v-btn
            color="red"
            :loading="isDeleting"
            :disabled="isDeleting"
            @click="deleteAndCloseDeleteDialog"
          >
            {{ $t('customer_reservation_browsing.delete.button.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import FullCalendar from '@fullcalendar/vue'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import interactionPlugin from '@fullcalendar/interaction'
  import timeGridPlugin from '@fullcalendar/timegrid'
  import allLocales from '@fullcalendar/core/locales-all'
  import moment from 'moment'
  import * as api from '../api.js'
  import * as holiday from '../holiday.js'
  import MaterialAlert from '../components/atoms/MaterialAlert.vue'

  const holidays = holiday.getHolidays('ja')
  function isHoliday (date) {
    const tmp = moment(date, 'YYYY-MM-DD')
    const variant = tmp.format('YYYY-M-D')
    const day = tmp.get('day')
    if (day === 0 || day === 6) {
      return true
    }
    return (holidays.findIndex(x => x[0] === date || x[0] === variant) >= 0)
  }

  class DisplayDetail {
    constructor () {
      this.id = ''
      this.reservation = null
      this.customer = null
      this.formattedPickedDate = ''
      this.storeName = ''
      this.meetingUrl = ''
      this.startTime = ''
      this.endTime = ''
      this.employeeId = ''
      this.isEditableReservation = false
    }

    startDatetime () {
      return moment(this.formattedPickedDate + ' ' + this.startTime)
    }

    endDatetime () {
      return moment(this.formattedPickedDate + ' ' + this.endTime)
    }

    updateCalendarEvent (calendarEvent) {
      const color = this.employeeList.find(x => x.employee_id === this.reservation.employee_id).color
      calendarEvent.setStart(this.startDatetime().format('YYYY-MM-DD HH:mmZ'))
      calendarEvent.setEnd(this.endDatetime().format('YYYY-MM-DD HH:mmZ'))
      calendarEvent.setProp('color', color)
      calendarEvent.setExtendedProp('reservation', JSON.parse(JSON.stringify(this.reservation)))
    }

    clear () {
      this.id = ''
      this.reservation = null
      this.customer = null
      this.formattedPickedDate = ''
      this.storeName = ''
      this.meetingUrl = ''
    }

    toApi () {
      // 一度JSON文字列化してパースすることで参照渡しを防ぐ
      const params = JSON.parse(JSON.stringify(this.reservation))
      params.reservation_date = moment(this.formattedPickedDate).format('YYYY-MM-DD')
      params.start_time = this.startTime
      params.end_time = this.endTime
      params.employee_id = this.employeeId
      return params
    }

    static fromCalendarEvent (calendarEvent, storeName) {
      const ret = new DisplayDetail()
      ret.id = calendarEvent.id
      ret.customer = calendarEvent.extendedProps.customer
      ret.reservation = calendarEvent.extendedProps.reservation
      ret.startTime = calendarEvent.extendedProps.reservation.start_time
      ret.endTime = calendarEvent.extendedProps.reservation.end_time
      ret.employeeId = calendarEvent.extendedProps.reservation.employee_id
      ret.formattedPickedDate = moment(ret.reservation.reservation_date).format('YYYY/MM/DD')
      ret.storeName = storeName
      if (ret.reservation.reservation_type === 'web') {
        if (ret.reservation.online_support.toolname === 'Google Meet') {
          ret.meetingUrl = 'https://meet.google.com/' + ret.reservation.online_support.token
        } else {
          ret.meetingUrl = ret.reservation.online_support.admin_url
        }
      }
      ret.employeeList = calendarEvent.extendedProps.employeeList.map(item => {
        // 表示用のプロパティ追加
        item.fullname = item.last_name + ' ' + item.first_name
        return item
      })
      ret.isEditableReservation = moment(ret.reservation.reservation_date + ' ' + ret.reservation.start_time).isSameOrAfter(moment())
      if (ret.isEditableReservation) {
        // 予約が変更可能な場合、予約に紐付いた店舗に所属するスタッフ+未指定表示用のスタッフを抽出
        ret.employeeList = ret.employeeList.filter(item => item.store_id === ret.reservation.store_id || item.employee_id === '')
      } else {
        // 予約が変更不可な場合、予約に紐付いたスタッフのみを抽出 (削除/異動されたスタッフはダミーデータがヒットする)
        ret.employeeList = ret.employeeList.filter(item => item.employee_id === ret.reservation.employee_id)
      }
      return ret
    }
  }

  export default {
    name: 'CustomerReservationBrowsing',
    components: {
      MaterialAlert,
      FullCalendar, // make the <FullCalendar> tag available
    },
    data () {
      return {
        calendarApi: null,
        showContent: false,
        showDeleteDialog: false,
        selectedDate: null,
        isFormValid: false,
        displayDetail: new DisplayDetail(),
        selectedEvent: null,
        calendarOptions: {
          plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
          headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          },
          initialView: 'timeGridWeek',
          selectable: false,
          select: this.dateClick,
          eventClick: this.eventClick,
          calendarEvents: this.events,
          editable: false,
          events: [],
          height: '75vh',
          locales: allLocales,
          locale: 'ja',
          allDaySlot: false,
          validRange: {},
          dayHeaderClassNames: function (args) {
            if (isHoliday(args.date)) {
              return ['holiday']
            }
          },
        },
        pickedDate: null,
        dialogPickedDate: null,
        formattedPickedDate: '',
        datePickerMenu: false,
        dialogDatePickerMenu: false,
        cautionMessage: '',
        locale: 'en',
        storeList: [],
        employeeList: [],
        customerList: [],
        selectedStore: null,
        isStoreProcessing: false,
        isCustomerProcessing: false,
        isEmployeeProcessing: false,
        isReservationProcessing: false,
        isUpdating: false,
        isDeleting: false,
        FailedGetStore: false,
        hideCalendar: false,
        errors: [],
        isUpdateSuccess: false,
        isDeleteSuccess: false,
        calendarCurrentDate: null,
        rules: [],
        sending: false,
        errorsInUpdating: [],
        errorsInDeleting: [],
        reservationDateRule: [
          v => !!v || this.$t('customer_reservation_browsing.errors.required'),
          v => this.validateReservationDate(v) || this.$t('customer_reservation_browsing.errors.past_date'),
        ],
        startTimeRule: [
          v => !!v || this.$t('customer_reservation_browsing.errors.required'),
          v => this.validateTime(v) || this.$t('customer_reservation_browsing.errors.past_time'),
          v => this.judgeTimeRel(v, true) || this.$t('customer_reservation_browsing.errors.late_start_time'),
          v => this.judgeTimeDiff(v, true) || this.$t('customer_reservation_browsing.errors.invalid_time_diff'),
          v => v.split(':')[1] === '00' || this.$t('customer_reservation_browsing.errors.minutes_not_allowed'),
        ],
        endTimeRule: [
          v => !!v || this.$t('customer_reservation_browsing.errors.required'),
          v => this.validateTime(v) || this.$t('customer_reservation_browsing.errors.past_time'),
          v => this.judgeTimeRel(v, false) || this.$t('customer_reservation_browsing.errors.early_end_time'),
          v => this.judgeTimeDiff(v, false) || this.$t('customer_reservation_browsing.errors.invalid_time_diff'),
          v => v.split(':')[1] === '00' || this.$t('customer_reservation_browsing.errors.minutes_not_allowed'),
        ],
        isReadOnly: false,
        isDeletable: false,
      }
    },
    computed: {
      year: function () {
        return moment(this.pickedDate).format('YYYY')
      },
      month: function () {
        return moment(this.pickedDate).format('MM')
      },
    },
    watch: {
      selectedStore: function (n, o) {
        // 初期状態ではmountedで更新する
        if (o !== null) {
          this.errors = []

          // 顧客情報の取得 -> 従業員情報の取得 -> 予約情報の取得の流れで処理する
          this.getCustomerData().then(this.getEmployeeData).then(this.getCustomerReservation)
        }
      },
      pickedDate: function (n, o) {
        this.formattedPickedDate = this.pickedDate.replace('-', '/')
        // 初期状態ではmountedで更新する
        if (o !== null) {
          this.errors = []
          this.getCustomerReservation()
        }
        this.calendarOptions.validRange = {}
        this.calendarApi.setOption('validRange', this.generateValidRange(this.pickedDate))
        // 有効範囲の設定が確定して現在日が移動したのが確定したら月初に移動する
        // 変数の変更をフックして月初に移動する
        // 上から順に書き下すと月初にうまく移動できないため
        this.calendarCurrentDate = this.calendarApi.getDate()
      },
      dialogPickedDate: function (n, o) {
        this.displayDetail.formattedPickedDate = moment(this.dialogPickedDate).format('YYYY/MM/DD')
        if (this.$refs.detailForm !== undefined) {
          this.$refs.detailForm.validate()
        }
      },
      calendarCurrentDate: function (value, oldValue) {
        // console.log(value, oldValue)
        // 当月以外は対象月の月初の週を表示する
        if (this.pickedDate !== moment().format('YYYY-MM')) {
          this.calendarApi.gotoDate(this.pickedDate + '-01')
        } else {
          this.calendarApi.gotoDate(moment().format('YYYY-MM-DD'))
        }
      },
      'displayDetail.startTime': function (n, o) {
        if (this.$refs.detailForm !== undefined) {
          this.displayDetail.endTime = moment(n, 'HH:mm').add(1, 'h').format('HH:mm')
          this.$refs.detailForm.validate()
        }
      },
      'displayDetail.endTime': function (n, o) {
        if (this.$refs.detailForm !== undefined) {
          this.displayDetail.startTime = moment(n, 'HH:mm').subtract(1, 'h').format('HH:mm')
          this.$refs.detailForm.validate()
        }
      },
    },
    mounted () {
      this.rules = {
        required: value => value !== '' || this.$t('reservation_capacity_browsing.update.errors.required'),
        natural: value => (Number.isInteger(value) && value >= 0) || this.$t('reservation_capacity_browsing.update.errors.not_natural'),
      }
      this.calendarApi = this.$refs.fullCalendar.getApi()
      this.locale = process.env.VUE_APP_I18N_LOCALE
      moment.locale(this.locale)

      // 権限設定
      this.isReadOnly = !this.$can('component.update_customer_reservation')
      this.isDeletable = this.$can('component.delete_customer_reservation')

      this.getStoreData()
      this.calendarOptions.validRange = this.generateValidRange(this.pickedDate)
    },
    methods: {
      getStoreData () {
        this.isStoreProcessing = true
        api.getStore().then(res => {
          if (res.data.length === 0) {
            this.storeList = []
            this.FailedGetStore = true
          } else {
            this.storeList = res.data
            this.storeList.sort(function (a, b) {
              if (a.name < b.name) {
                return -1
              }
              if (a.name > b.name) {
                return 1
              }
              return 0
            })
            // 登録後遷移の場合、登録対象の店舗・年月を表示する
            if (this.$route.params.store_id && this.$route.params.picked_date) {
              this.selectedStore = this.$route.params.store_id
              this.pickedDate = this.$route.params.picked_date
            } else {
              this.selectedStore = res.data[0]._id // 先頭要素を初期値とする
              const now = moment()
              this.pickedDate = now.format('YYYY-MM')
            }
            this.FailedGetStore = false

            // 顧客情報の取得 -> 従業員情報の取得 -> 予約情報の取得の流れで処理する
            this.getCustomerData().then(this.getEmployeeData).then(this.getCustomerReservation)
          }
        }).catch(e => {
          console.log(e)
          this.FailedGetStore = true
        }).finally(() => {
          this.isStoreProcessing = false
        })
      },
      getCustomerData () {
        this.isCustomerProcessing = true
        const params = {
          first_name: '',
          last_name: '',
          store_id: this.selectedStore,
          phone_number: '',
          mail_address: '',
          start_date: '',
          end_date: '',
        }
        const p = api.searchCustomer(params).then(res => {
          this.customerList = res.data
        }).catch(e => {
          console.log(e)
          if (e.code === 'ECONNABORTED') {
            this.errors.push(this.$t('errors.api_timeout'))
            this.hideCalendar = true
          } else {
            this.errors.push(this.$t('errors.unexpected'))
            this.hideCalendar = true
          }
        }).finally(() => {
          this.isCustomerProcessing = false
        })
        return p
      },
      getEmployeeData () {
        // 対象店舗の従業員情報を取得
        // 対応スタッフが未指定の場合にも対処
        this.isEmployeeProcessing = true
        this.employeeList = [
          {
            employee_id: '',
            last_name: this.$t('customer_reservation_browsing.event.staff_unspecified'),
            first_name: '',
          },
        ]
        const p = api.getEmployeesOfStore(this.selectedStore).then(r => {
          this.employeeList.push(...r.data)

          // カードの色を定義
          const colorList = ['royalblue', 'teal', 'sienna', 'brown', 'mediumvioletred',
                             'darkorchid', 'darkslateblue', 'blueviolet', 'slateblue', 'blue',
                             'green', 'purple', 'indigo', 'black',
                             'midnightblue', 'darkgreen', 'saddlebrown', 'darkolivegreen', 'crimson']
          // 従業員ごとに色を割当
          // 従業員数が色の数を超える(employeeList.lenght >= colorList.length)と色が重複する
          this.employeeList.forEach((v, idx) => {
            v.color = colorList[idx % colorList.length]
          })
        }).catch(e => {
          console.log(e)
          if (e.code === 'ECONNABORTED') {
            this.errors.push(this.$t('errors.api_timeout'))
            this.hideCalendar = true
          } else if (e.response !== undefined && e.response.status === 404) {
            // 対象店舗に所属スタッフが存在しないケースが有り得るため何もしない
            // this.errors.push(this.$t('customer_reservation_browsing.errors.employee_not_found'))
          } else {
            this.errors.push(this.$t('errors.unexpected'))
            this.hideCalendar = true
          }
        }).finally(() => {
          this.isEmployeeProcessing = false
        })
        return p
      },
      dateClick: function (info) {
        this.displayDetail.clear()
        this.selectedEvent = info
        // alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY)
        // alert('Current view: ' + info.view.type)
        this.displayDetail.date = moment(info.start).format('YYYY-MM-DD')
        this.displayDetail.startTime = moment(info.start).format('HH:mm')
        this.displayDetail.endTime = moment(info.end).format('HH:mm')
        this.showContent = true
      },
      eventClick: function (info) {
        // scrollTo(0, 0) // ここはスクロール不要
        this.isUpdateSuccess = false
        this.isDeleteSuccess = false
        this.errorsInUpdating = []
        this.selectedEvent = info.event
        this.dialogPickedDate = moment(info.event.extendedProps.reservation.reservation_date).format('YYYY-MM-DD')
        this.showContent = true
        const storeName = this.storeList.find(item => item._id === info.event.extendedProps.reservation.store_id).name
        this.displayDetail = DisplayDetail.fromCalendarEvent(info.event, storeName)
      },
      closeDialog () {
        this.showContent = false
        this.selectedEvent = null
        this.errorIsnUpdating = []
        this.dialogDatePickerMenu = false
      },
      updateAndCloseDialog () {
        this.isUpdating = true
        this.errorsInUpdating = []
        const params = this.displayDetail.toApi()
        api.updateCustomerReservation(params._id, params).then(res => {
          this.displayDetail.reservation = params
          this.displayDetail.updateCalendarEvent(this.selectedEvent)
          this.isUpdateSuccess = true
          this.closeDialog()
        }).catch(e => {
          this.isUpdateSuccess = false
          if (e.code === 'ECONNABORTED') {
            this.errorsInUpdating.push(this.$t('errors.api_timeout'))
          } else if (e.response !== undefined && e.response.data.error_code === 1000) {
            this.errorsInUpdating.push(this.$t('customer_reservation_browsing.errors.update.capacity_exceeded'))
          } else if (e.response !== undefined && e.response.data.error_code === 1001) {
            this.errorsInUpdating.push(this.$t('customer_reservation_browsing.errors.update.reservation_duplication'))
          } else if (e.response !== undefined && e.response.data.error_code === 1002) {
            this.errorsInUpdating.push(this.$t('customer_reservation_browsing.errors.update.staff_absence'))
          } else {
            this.errorsInUpdating.push(this.$t('errors.unexpected'))
          }
        }).finally(() => {
          this.isUpdating = false
        })
      },
      openDeleteDialog () {
        const customerName = this.displayDetail.customer.profile.lastname + this.displayDetail.customer.profile.firstname + ' ' + this.$t('customer_reservation_browsing.event.honorific_title')
        const date = moment(this.displayDetail.formattedPickedDate).format('LL')
        const time = this.displayDetail.startTime + '~' + this.displayDetail.endTime
        this.cautionMessage = this.$t('customer_reservation_browsing.delete.message').replace('{{customer_name}}', customerName).replace('{{datetime}}', date + time)
        this.errorsInDeleting = []
        this.showDeleteDialog = true
      },
      closeDeleteDialog () {
        this.showDeleteDialog = false
      },
      deleteAndCloseDeleteDialog () {
        this.isDeleting = true
        const _id = this.displayDetail.toApi()._id
        api.deleteCustomerReservation(_id).then(res => {
          this.selectedEvent.remove()
          this.isDeleteSuccess = true
          this.closeDeleteDialog()
          this.closeDialog()
        }).catch(e => {
          if (e.code === 'ECONNABORTED') {
            this.errorsInDeleting.push(this.$t('errors.api_timeout'))
          } else if (e.response && e.response.status === 404) {
            this.errorsInDeleting.push(this.$t('customer_reservation_browsing.errors.delete_not_found'))
          } else {
            this.errorsInDeleting.push(this.$t('errors.unexpected'))
          }
          this.isDeleteSuccess = false
        }).finally(() => {
          this.isDeleting = false
        })
      },
      getCustomerReservation () {
        this.isProcessing = true
        this.hideCalendar = false

        // 前回のカレンダーデータをすべて削除する
        const eventSources = this.calendarApi.getEventSources()
        eventSources.forEach(e => {
          e.remove()
        })

        // 対象店舗/年月の予約情報を取得してカレンダーに登録
        this.isReservationProcessing = true
        api.getCustomerReservationMonthly(this.selectedStore, this.year, this.month).then(res => {
          const currentEvents = []
          if (res.data.length === 0) {
            this.errors.push(this.$t('customer_reservation_browsing.errors.not_found'))
          } else {
            res.data.forEach(e => {
              const start = moment(e.reservation_date + '-' + e.start_time, 'YYYY-MM-DD-HH:mm')
              const end = moment(e.reservation_date + '-' + e.end_time, 'YYYY-MM-DD-HH:mm')

              let employee = this.employeeList.find((v) => v.employee_id === e.employee_id)
              // 削除や異動されたスタッフは見つからないので、ダミーデータを用意する
              if (!employee) {
                employee = {
                  employee_id: e.employee_id,
                  color: 'gray',
                  first_name: this.$t('customer_reservation_browsing.event.staff_undefined'),
                  last_name: e.employee_id,
                }
                this.employeeList.push(employee)
              }
              const customer = this.customerList.find((v) => v.customer_id === e.customer_id)
              let title = ''
              if (!customer) {
                title = this.$t('customer_reservation_browsing.deleted_customer_name')
              } else {
                title = this.$t('customer_reservation_browsing.event.' + e.reservation_type) +
                  ' ' + customer.profile.lastname + this.$t('customer_reservation_browsing.event.honorific_title')
              }
              const calendarEvent = {
                title: title,
                start: start.toDate(),
                end: end.toDate(),
                color: employee.color,
                extendedProps: {
                  reservation: e,
                  customer: customer,
                  employeeList: this.employeeList,
                },
              }
              currentEvents.push(calendarEvent)
            })
            this.calendarApi.addEventSource(currentEvents)
          }
          this.isProcessing = false
        }).catch(e => {
          console.log(e)
          if (e.code === 'ECONNABORTED') {
            this.errors.push(this.$t('errors.api_timeout'))
            this.hideCalendar = true
          } else {
            this.errors.push(this.$t('errors.unexpected'))
            this.hideCalendar = true
          }
        }).finally(() => {
          this.isReservationProcessing = false
        })
      },
      generateValidRange (yyyymm) {
        return {
          start: moment(yyyymm).startOf('month').format('YYYY-MM-DD'),
          end: moment(yyyymm).endOf('month').add(1, 'days').format('YYYY-MM-DD'),
        }
      },
      reformatMMDD (dateStr) {
        return moment(dateStr).format(this.$t('reservation_capacity_browsing.update.title.date_format'))
      },
      showCustomerDetail (customer) {
        // 新しくタブを開く
        const routeData = this.$router.resolve({
          name: 'Customer Detail',
          params: { target: customer },
        })
        window.open(routeData.href, '_blank')
        // その場で表示
        // this.$router.push({
        //   name: 'Customer Detail',
        //   params: { target: customer },
        // })
      },
      validateReservationDate (v) {
        const now = moment()
        return moment(v).isSameOrAfter(now, 'day')
      },
      validateTime (v) {
        const now = moment()
        const reservationDatetime = moment(this.displayDetail.formattedPickedDate + ' ' + v)
        return reservationDatetime.isAfter(now)
      },
      judgeTimeRel (v, isStart) {
        let start = null
        let end = null
        if (isStart) {
          start = moment(this.displayDetail.formattedPickedDate + ' ' + v)
          end = moment(this.displayDetail.formattedPickedDate + ' ' + this.displayDetail.endTime)
        } else {
          start = moment(this.displayDetail.formattedPickedDate + ' ' + this.displayDetail.startTime)
          end = moment(this.displayDetail.formattedPickedDate + ' ' + v)
        }
        return end.isAfter(start)
      },
      judgeTimeDiff (v, isStart) {
        let start = null
        let end = null
        if (isStart) {
          start = moment(this.displayDetail.formattedPickedDate + ' ' + v)
          end = moment(this.displayDetail.formattedPickedDate + ' ' + this.displayDetail.endTime)
        } else {
          start = moment(this.displayDetail.formattedPickedDate + ' ' + this.displayDetail.startTime)
          end = moment(this.displayDetail.formattedPickedDate + ' ' + v)
        }
        return end.diff(start, 'hours') === 1
      },
    },
  }
</script>

<style>
.calendar {
  margin-top: 0px;
  margin-bottom: 0px;
}
.v-progress-circular {
  margin: 1rem;
}
.v-picker.v-card {
  margin: 0px;
}
/* スピナーを回すときのコンテンツ非表示用
 * v-showだとカレンダーの表示が崩れるため
 * v-ifだとdomが生成されず、カレンダーの初期化に失敗するため
 */
.hide {
  visibility:hidden;
}
.fc-col-header-cell.fc-day-sat {
  background-color: #d2d8fc;
}
.fc-col-header-cell.fc-day-sun {
  background-color:#fae1e1;
}
.holiday {
  background-color:#fae1e1;
}
.theme--light .v-input--is-disabled * {
  color: rgba(0,0,0,0.87) !important
}
.theme--light .black-label .v-label {
  color: rgba(0,0,0,0.87) !important
}
div.col {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
</style>
