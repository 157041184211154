<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="font-weight-light"
      v-text="this.titleWithAuthorization($t($route.name))"
    />

    <v-spacer />

    <div class="mx-3" />

    <v-btn
      class="ml-2"
      min-width="0"
      text
      disabled
      style="text-transform: none"
    >
      <v-icon>mdi-office-building</v-icon>
      {{ user.tenantName }}
    </v-btn>

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          style="text-transform: none"
          v-on="on"
        >
          <v-icon>mdi-account</v-icon>
          {{ user.userName }}
        </v-btn>
      </template>

      <v-list
        :tile="false"
        nav
      >
        <div>
          <v-list-item
            to="/component/password-change"
          >
            <v-list-item-title>{{ this.$t('Password Change') }}</v-list-item-title>
          </v-list-item>
          <!-- ダークモード切り替え用
          <v-list-item>
            <v-row
              align="center"
              no-gutters
            >
              <v-col cols="auto">
                Dark Mode
              </v-col>

              <v-spacer />

              <v-col cols="auto">
                <v-switch
                  v-model="$vuetify.theme.dark"
                  class="ma-0 pa-0"
                  color="secondary"
                  hide-details
                />
              </v-col>
            </v-row>
          </v-list-item>
          -->
          <v-list-item
            @click="logout"
          >
            <v-list-item-title>{{ this.$t('Logout') }}</v-list-item-title>
          </v-list-item>
        </div>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
  // Components
  // import { VHover, VListItem } from 'vuetify/lib'

  // Utilities
  import { mapState, mapMutations } from 'vuex'

  import * as Auth from '../../auth.js'

  export default {
    name: 'MainAppBar',

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      user: '',
    }),

    computed: {
      ...mapState(['drawer']),
    },

    mounted () {
      this.user = this.getUser()
    },

    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      logout () {
        Auth.logout().then(res => {
          this.$router.push({
            name: 'Login',
          })
        })
      },
      getUser () {
        return Auth.getCurrentUser()
      },
      // 権限でタイトルが変わる場合の処理をここで行う
      titleWithAuthorization (title) {
        if (title === this.$t('Store Update') && this.$can('store_browsing_self')) {
          return this.$t('store_browsing_self.title')
        } else if (title === this.$t('Employee Update') && this.$can('employee_browsing_self')) {
          return this.$t('employee_browsing_self.title')
        } else {
          return title
        }
      },
    },
  }
</script>

<style scoped>
/* テナント名のアイコンとテキストの色指定 */
.ml-2.v-btn.v-btn--disabled.v-btn--flat.v-btn--text.theme--light{
  color:#000000 !important;
}
.v-icon.notranslate.mdi.mdi-office-building.theme--light{
  color:#000000 !important;
}
.ml-2.v-btn.v-btn--disabled.v-btn--flat.v-btn--text.theme--dark{
  color:#FFFFFF !important;
}
.v-icon.notranslate.mdi.mdi-office-building.theme--dark{
  color:#FFFFFF !important;
}
</style>
